import ReportingQuarter from '../interfaces/models/ReportingQuarter';
import axios from 'axios';
import UserService from './UserService';
import EventSystem from '../EventSystem';

const client = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL + 'reporting',
});

const showErrorMessage = (text: string) => {
    EventSystem.publish(
        'notification',
        text,
        {
            variant: 'error',
        }
    );
}

const showUnauthorizedMessageAndLogoutUser = () => {
    UserService.logout();
    showErrorMessage('Du bist nicht mehr eingeloggt. Bitte speicher deine Daten zwischen und lade die Webseite neu um dich erneut einzuloggen.');
}

// @ts-ignore
client.interceptors.request.use((request) => {
    const accessToken = localStorage.getItem('access-token');
    if (!accessToken) {
        showUnauthorizedMessageAndLogoutUser();
        return;
    }

    request.headers.common.Authorization = `Bearer ${accessToken}`;

    return request;
});

// @ts-ignore
client.interceptors.response.use(function (response) {
    if (response.headers['x-api-access-token']) {
        localStorage.setItem('access-token', response.headers['x-api-access-token']);
    }

    return Promise.resolve(response.data);
}, function (error) {
    if (error && typeof error.response !== 'undefined' && error.response.status === 401) {
        showUnauthorizedMessageAndLogoutUser();
        return Promise.reject(error);
    } else if (error &&
        typeof error.response !== 'undefined' &&
        error.response.status === 422 &&
        typeof error.response.data.errors === 'object'
    ) {
        const errorKeys = Object.keys(error.response.data.errors);
        if (typeof error.response.data.errors[errorKeys[0]][0] === 'string') {
            showErrorMessage('Es gab folgenden Fehler: ' + error.response.data.errors[errorKeys[0]][0]);
        } else {
            showErrorMessage('Die Anfrage an das Backend ist leider ungültig!');
        }

        return Promise.reject(error);
    } else if (error &&
        typeof error.response !== 'undefined' &&
        error.response.status === 403
    ) {
        showErrorMessage('Keine Berechtigung für diesen Bereich.');

        EventSystem.publish('redirect', '/projects');

        return Promise.reject(error);
    } else if (error &&
        typeof error.response !== 'undefined' &&
        error.response.status === 410
    ) {
        showErrorMessage('Die angeforderte Ressource wird nicht länger bereitgestellt und wurde entfernt.');

        window.location.href = '/#/projects';

        return Promise.reject(error);
    }

    showErrorMessage('Es gab einen Fehler beim Kommunizieren mit dem Backend.');

    return Promise.reject(error);
});

export default {
    getAllById: (financialProductId: string): Promise<ReportingQuarter[]> => {
        // @ts-ignore
        return client.get(`/${financialProductId}/all`).then((response: ReportingQuarter[]) => {
            return response;
        }).catch(() => {
            return [];
        });
    },
    publishToContentful: async (data: ReportingQuarter) => {
        return client.post(`/report/publish`, {
            financialProductId: data.financialProductId,
            year: data.year,
            quarter: data.quarter,
            id: data.id,
        }).then(response => {
            return response;
        }).catch(error => {
            throw error;
        });
    },
    create: (data: object): Promise<ReportingQuarter | null> => {
        // @ts-ignore
        return client.post(`/report`, data).then((response: ReportingQuarter) => {
            return response;
        }).catch(() => {
            return null;
        });
    },
    updateReport: async (data: ReportingQuarter): Promise<ReportingQuarter | null> => {
        return client.put(`/report`, data, {
            headers: {
                'Content-Type': 'application/json'
            }
            // @ts-ignore
        }).then((response: ReportingQuarter) => {
            return response;
        }).catch(() => {
            return null;
        });
    },
    approveReport: async (data: ReportingQuarter) => {
        try {
            const payload = {
                financialProductId: data.financialProductId,
                year: data.year,
                quarter: data.quarter,
            };

            await client.post(`/drafts/approve`, {
                ...payload,
                approvedBy: data.approvedBy,
            });

            await client.post(`/report/publish`, {
                ...payload,
                id: data.id,
            });

            return data;
        } catch (error) {
            throw error;
        }
    },
    unapproveReport: async (data: ReportingQuarter) => {
        return client.post(`/published/unapprove`, {
            financialProductId: data.financialProductId,
            year: data.year,
            quarter: data.quarter,
            approvedBy: data.approvedBy,
        }).then(response => {
            return response;
        }).catch(error => {
            throw error;
        });
    },
    getAllAssets: async (): Promise<ReportingQuarter[]> => {
        // @ts-ignore
        return client.get(`/assets`).then((response: ReportingQuarter[]) => {
            return response;
        }).catch(() => {
            return [];
        });
    },
    uploadExcel: async (formData: any, fpId: string) => {
        return client.post(`/report/${fpId}/excel`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        ).then(response => {
            return response;
        }).catch(error => {
            throw error;
        });
    }
}
