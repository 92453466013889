import React, {useEffect, useState} from 'react';
import Formatter from '../../helpers/Formatter';
import BaseTable from '../UI/Tables/BaseTable';
import AccountService from '../../services/AccountService';
import AddAccount from './AddAccount';
import Flag from 'react-world-flags';
import Search from '../../helpers/Search';
import {useAppContext} from '../../libs/contextLib';

export default function AccountsTable(props) {
    const [data, setData] = useState([]);
    const [countries, setCountries] = useState({});
    const {selectOptions} = useAppContext();
    const {setLoader} = props;

    useEffect(() => {
        setLoader(true);

        AccountService.all().then(response => {
            response = response || [];
            setData(response);
            setLoader(false);
        }).catch(() => {
            setLoader(false);
        });
    }, [setLoader]);

    useEffect(() => {
        if (Object.keys(selectOptions).length === 0) {
            return;
        }

        setCountries(selectOptions.realEstateProperty.countries.available);
    }, [selectOptions]);

    const formatCountryColumn = (row, field) => {
        if (!row[field]) {
            return '';
        }

        return row[field].split(',').map(item => {
            if (!item) {
                return '';
            }

            return (
                <div key={item}>
                    <Flag code={item} height='14'/>
                </div>
            );
        });
    };

    const columns = [
        {
            title: 'ID',
            id: 'id',
            editable: 'never',
            render: row => Formatter.formatIdColumn(row, 'account'),
            width: '20px',
        },
        {
            title: 'Name',
            field: 'name',
            render: row => Formatter.formatNameColumn(row, 'account'),
            editable: 'onUpdate',
        },
        {
            title: 'Straße',
            field: 'street',
            editable: 'onUpdate',
        },
        {
            title: 'Hausnummer',
            field: 'streetNo',
            editable: 'onUpdate',
        },
        {
            title: 'Stadt',
            field: 'city',
            editable: 'onUpdate',
        },
        {
            title: 'Land',
            field: 'country',
            editable: 'onUpdate',
            width: '100px',
            render: row => formatCountryColumn(row, 'country'),
            filtering: true,
            lookup: countries,
            customFilterAndSearch: (term, rowData) => Search.searchAccountCountry(term, rowData),
        },
        {
            title: 'Typ',
            field: 'type',
            editable: 'never',
        },
    ];

    const handleDelete = accountToDelete => {
        return AccountService.delete(accountToDelete.id);
    };

    const handleSave = account => {
        AccountService.create(account).then(response => {
            setData([...data, response]);
        }).catch(() => { });
    }

    const handleUpdate = updatedData => {
        return AccountService.updateFromTable(updatedData.id, updatedData);
    };

    return (
        <>
            <AddAccount
                onSave={handleSave}
                countries={countries}
            />
            <BaseTable
                tableName='accountsTable'
                columns={columns}
                data={data}
                onDelete={handleDelete}
                onUpdate={handleUpdate}
                isEditable={rowData => rowData.type === 'company'}
                title='Accounts'
                objects='Accounts'
            />
        </>
    );
}
