import React from 'react';
import Chip from '@material-ui/core/Chip';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    chip: {
        color: 'white',
        margin: theme.spacing(0.25),
    },
}));

export default function CustomChip(props) {
    const classes = useStyles();

    return (
        <Chip
            className={classes.chip}
            size='small'
            color={props.color}
            label={props.value}
        />
    );
}
