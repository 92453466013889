import React, {useMemo, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {makeStyles} from '@material-ui/core/styles';
import FileDropzoneAreaProps from '../../../interfaces/props/FileDropzoneAreaProps';

const useStyles = makeStyles(() => ({
    description : {
        marginLeft: 16,
        marginRight: 16,
    },
    container: {
        background: 'transparent',
        margin: 0,
    },
}));

const baseStyle = {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: 'transparent',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    marginTop: 16,
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

export default function FileDropzoneArea(props: FileDropzoneAreaProps) {
    const classes = useStyles();
    const [files, setFiles] = useState<File[]>([] as File[]);
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        multiple: false,
        maxSize: 25000000,
        onDrop: (acceptedFiles: File[]) => {
            setFiles(acceptedFiles);
            props.onFile(acceptedFiles[0]);
        },
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
    }), [
        isDragActive,
        isDragReject,
        isDragAccept,
    ]);

    const fileNames = files.map(file => (
        <div>{file.name}</div>
    ));

    return (
        <section className={classes.container}>
            <div {...getRootProps({style})}>
                <input {...getInputProps()} />
                <p>
                    Hier eine Datei reinlegen oder per Mausklick eine Datei auswählen.
                    Die maximale Dateigröße liegt bei 25MB.
                </p>
            </div>
            <aside>
                {fileNames}
            </aside>
        </section>
    );
}
