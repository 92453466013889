import React, {useEffect, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import FinancingStructureTable from './FinancingStructureTable';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import SaveIcon from '@material-ui/icons/Save';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import PlannedMeasureService from '../../services/PlannedMeasureService';
import CustomDatePicker from '../UI/Forms/CustomDatePicker';
import {TextField} from '@material-ui/core';
import FinancingEntityService from '../../services/FinancingEntityService';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import ThemeHelper from '../../helpers/ThemeHelper';
import {useAppContext} from '../../libs/contextLib';
import UserHelper from '../../helpers/UserHelper';

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <Typography
            component='div'
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        padding: 0,
    },
    rootDialog: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        padding: 24,
    },
    grow: {
        flexGrow: 1,
    },
    addButton: {
        color: 'white',
    },
    appBar: {
        position: 'relative',
        backgroundColor: ThemeHelper.getColor(theme, theme.palette.cardHeader.backgroundColor),
        color: ThemeHelper.getColor(theme, theme.palette.cardHeader.color),
    },
    appBarTable: {
        backgroundColor: ThemeHelper.getColor(theme, theme.palette.cardHeader.backgroundColor),
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        color: ThemeHelper.getColor(theme, theme.palette.cardHeader.color),
    },
    tab: {
        color: ThemeHelper.getColor(theme, theme.palette.cardHeader.color),
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
        color: ThemeHelper.getColor(theme, theme.palette.cardHeader.color),
    },
    button: {
        margin: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
        paddingRight: 5,
    },
    deleteButton: {
        background: theme.palette.delete,
        color: 'white'
    },
    indicator: {
        backgroundColor: theme.palette.customBackground.green,
    },
    tabPanel: {
        fontSize: theme.typography.fontSize,
    },
    primary: {
        color: ThemeHelper.getColor(theme, theme.palette.primary),
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

export default function PlannedMeasures(props) {
    const {selectOptions} = useAppContext();
    const classes = useStyles();
    const [tab, setTab] = useState(0);
    const [plannedMeasureModalOpen, setPlannedMeasureModalOpen] = useState(false);
    const [plannedMeasureEditModalOpen, setPlannedMeasureEditModalOpen] = useState(false);
    const [selectedPlannedMeasure, setSelectedPlannedMeasure] = useState('');
    const [financingEntityModalOpen, setFinancingEntityModalOpen] = useState(false);
    const [newFinancingEntity, setNewFinancingEntity] = useState({});
    const [types, setTypes] = useState({});
    const [sourceTypes, setSourceTypes] = useState({});
    const [financingEntityTypes, setFinancingEntityTypes] = useState({});
    const [financingEntityLegalSetupTypes, setFinancingEntityLegalSetupTypes] = useState({});
    const match = useRouteMatch();
    const [deletePlannedMeasureOpen, setDeletePlannedMeasureOpen] = React.useState(false);
    const {user} = useAppContext();

    const orderPlannedMeasures = (plannedMeasures) => {
        if (!plannedMeasures) {
            return;
        }

        return plannedMeasures.sort(function (a, b) {
            if (a.type < b.type) return 1;
            if (a.type > b.type) return -1;
            if (a.id < b.id) return -1;
            if (a.id > b.id) return 1;
            return 0;
        });
    };

    const orderedPlannedMeasures = orderPlannedMeasures(props.plannedMeasures);
    const [currentPlannedMeasureId, setCurrentPlannedMeasureId] = useState(
        orderedPlannedMeasures && orderedPlannedMeasures.length > 0 ? orderedPlannedMeasures[0].id : 0
    );
    const [currentPlannedMeasure, setCurrentPlannedMeasure] = useState(
        orderedPlannedMeasures && orderedPlannedMeasures.length > 0 ? orderedPlannedMeasures[0] : 0
    );
    const [data, setData] = useState(orderedPlannedMeasures);

    const setDataPlannedMeasures = data => {
        setData(orderPlannedMeasures(data));
    };

    useEffect(() => {
        if (Object.keys(selectOptions).length === 0) {
            return;
        }

        setSourceTypes(selectOptions.financingEntity.sourceTypes);
        setFinancingEntityTypes(selectOptions.financingEntity.types);
        setTypes(selectOptions.plannedMeasure.types);
        setFinancingEntityLegalSetupTypes(selectOptions.financingEntity.legalSetupTypes);
    }, [selectOptions]);

    const handleTabChange = (event, newValue) => {
        setCurrentPlannedMeasureId(data[newValue].id)
        setCurrentPlannedMeasure(data[newValue])
        setTab(newValue);
    };

    const handleSelectChange = (event) => {
        setSelectedPlannedMeasure(event.target.value);
    };

    const handleSave = () => {
        PlannedMeasureService.create({type: selectedPlannedMeasure, projectId: match.params.id}).then((response) => {
            response.financingEntities = response.financingEntities || [];

            const plannedMeasures = data;
            plannedMeasures.push(response);

            setDataPlannedMeasures(plannedMeasures);
            setPlannedMeasureModalOpen(false);
        }).catch(() => {
        });
    };

    const handleUpdate = () => {
        PlannedMeasureService.update(currentPlannedMeasureId, {type: selectedPlannedMeasure}).then((response) => {
            const plannedMeasures = data;

            plannedMeasures[plannedMeasures.findIndex(plannedMeasure => plannedMeasure.id === response.id)].type = response.type;

            setDataPlannedMeasures(plannedMeasures);

            setTab(data.findIndex(data => data.id === response.id));

            setPlannedMeasureEditModalOpen(false);
        })
    };

    const handleFinancingEntitySave = () => {
        FinancingEntityService.create({
            ...newFinancingEntity,
            plannedMeasureId: currentPlannedMeasureId,
        }).then(response => {
            response.startsAt = response.startsAt || null;
            response.endsAt = response.endsAt || null;
            const plannedMeasureData = data;
            plannedMeasureData.forEach(plannedMeasure => {
                if (plannedMeasure.id === currentPlannedMeasureId) {
                    if (plannedMeasure.financingEntities) {
                        plannedMeasure.financingEntities.push(response);
                    } else {
                        plannedMeasure.financingEntities = [response];
                    }
                }
            });

            setDataPlannedMeasures(plannedMeasureData);
            setNewFinancingEntity({});
            setFinancingEntityModalOpen(false);
        }).catch(() => {
        });
    };

    const handleFinancingEntityChange = (event) => {
        setNewFinancingEntity({
            ...newFinancingEntity,
            [event.target.name]: event.target.value
        });
    };

    const handleClickDeletePlannedMeasureOpen = () => {
        setDeletePlannedMeasureOpen(true);
    };

    const handleDeletePlannedMeasureClose = () => {
        setDeletePlannedMeasureOpen(false);
    };

    const handleDeletePlannedMeasure = () => {
        const currentPlannedMeasure = data[tab];

        PlannedMeasureService.delete(currentPlannedMeasure.id).then(() => {
            setDeletePlannedMeasureOpen(false);

            const plannedMeasures = data;
            const index = plannedMeasures.indexOf(currentPlannedMeasure);
            plannedMeasures.splice(index, 1);

            setData(plannedMeasures);

            handleTabChange(null, 0)
        }).catch(() => {

        });
    }

    return (
        <div className={classes.root}>
            <AppBar className={classes.appBarTable} position='static' elevation={0}>
                <Toolbar>
                    <Tabs value={tab} onChange={handleTabChange} className={classes.tab} classes={{
                        indicator: classes.indicator
                    }}>
                        {data && data.map((plannedMeasure, index) => {
                            return (
                                <Tab
                                    key={plannedMeasure.id}
                                    label={types[plannedMeasure.type]}
                                    {...a11yProps(index)}
                                    className={classes.tab}
                                />
                            );
                        })}
                    </Tabs>
                    <div
                        className={classes.grow}
                    />
                    {
                        UserHelper.hasPermission(user, 'edit')
                            ? (<>
                                    <IconButton
                                        disabled={!currentPlannedMeasureId}
                                        onClick={() => {
                                            setSelectedPlannedMeasure('');
                                            setPlannedMeasureEditModalOpen(true)
                                        }}
                                        className={classes.primary}
                                    >
                                        <EditIcon/>
                                    </IconButton>
                                    <IconButton
                                        onClick={handleClickDeletePlannedMeasureOpen}
                                        className={classes.primary}
                                    >
                                        <DeleteIcon/>
                                    </IconButton>
                                    <IconButton
                                        onClick={() => setPlannedMeasureModalOpen(true)}
                                        className={classes.primary}
                                    >
                                        <AddIcon/>
                                    </IconButton>
                                </>
                            )
                            : null
                    }
                </Toolbar>
            </AppBar>
            <div>
                {data && data.map((plannedMeasure, index) => {
                    return (
                        <TabPanel className={classes.tabPanel} key={plannedMeasure.id} value={tab} index={index}>
                            <FinancingStructureTable financingEntities={plannedMeasure.financingEntities}/>
                        </TabPanel>
                    );
                })}
                {
                    UserHelper.hasPermission(user, 'edit')
                        ? (<IconButton
                            aria-label='edit content'
                            onClick={() => setFinancingEntityModalOpen(true)}
                            className={classes.primary}
                        >
                            <AddIcon/>
                        </IconButton>)
                        : null
                }
            </div>
            <Dialog
                fullScreen
                open={plannedMeasureModalOpen}
                onClose={() => setPlannedMeasureModalOpen(false)}
                TransitionComponent={Transition}
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            edge='start'
                            onClick={() => setPlannedMeasureModalOpen(false)}
                            aria-label='close'
                        >
                            <CloseIcon/>
                        </IconButton>
                        <Typography variant='h6' className={classes.title}>
                            Geplante Maßnahme hinzufügen
                        </Typography>
                        <Button
                            variant='contained'
                            color='secondary'
                            size='small'
                            className={classes.button}
                            startIcon={<SaveIcon/>}
                            onClick={handleSave}
                        >
                            Speichern
                        </Button>
                    </Toolbar>
                </AppBar>
                <div className={classes.rootDialog}>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <h2>Bitte wähle die geplante Maßnahme aus</h2>
                            <FormControl className={classes.formControl}>
                                <InputLabel shrink id='planned-measure-label'>
                                    Geplante Maßnahme
                                </InputLabel>
                                <Select
                                    id='planned-measure-select'
                                    value={selectedPlannedMeasure}
                                    onChange={handleSelectChange}
                                    displayEmpty
                                    className={classes.selectEmpty}
                                    required
                                >
                                    <MenuItem value=''>
                                        <em>Bitte wählen</em>
                                    </MenuItem>
                                    {Object.keys(types).map(type => {
                                        return (
                                            <MenuItem key={type} value={type}>{types[type]}</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
            </Dialog>
            <Dialog
                fullScreen
                open={plannedMeasureEditModalOpen}
                onClose={() => setPlannedMeasureEditModalOpen(false)}
                TransitionComponent={Transition}
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            edge='start'
                            onClick={() => setPlannedMeasureEditModalOpen(false)}
                            aria-label='close'
                        >
                            <CloseIcon/>
                        </IconButton>
                        <Typography variant='h6' className={classes.title}>
                            Geplante Maßnahme ändern
                        </Typography>
                        <Button
                            variant='contained'
                            color='secondary'
                            size='small'
                            className={classes.button}
                            startIcon={<SaveIcon/>}
                            onClick={handleUpdate}
                        >
                            Speichern
                        </Button>
                    </Toolbar>
                </AppBar>
                <div className={classes.rootDialog}>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <FormControl className={classes.formControl}>
                                <InputLabel shrink id='planned-measure-label'>
                                    Geplante Maßnahme
                                </InputLabel>
                                <Select
                                    id='planned-measure-select'
                                    value={selectedPlannedMeasure || currentPlannedMeasure.type}
                                    onChange={handleSelectChange}
                                    displayEmpty
                                    className={classes.selectEmpty}
                                    required
                                >
                                    {Object.keys(types).map(type => {
                                        return (
                                            <MenuItem key={type} value={type}>{types[type]}</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
            </Dialog>
            <Dialog
                fullScreen
                open={financingEntityModalOpen}
                onClose={() => setFinancingEntityModalOpen(false)}
                TransitionComponent={Transition}
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            edge='start'
                            onClick={() => setFinancingEntityModalOpen(false)}
                            aria-label='close'
                        >
                            <CloseIcon/>
                        </IconButton>
                        <Typography variant='h6' className={classes.title}>
                            Financing Entity hinzufügen
                        </Typography>
                        <Button
                            variant='contained'
                            color='secondary'
                            size='small'
                            className={classes.button}
                            startIcon={<SaveIcon/>}
                            onClick={handleFinancingEntitySave}
                        >
                            Speichern
                        </Button>
                    </Toolbar>
                </AppBar>
                <div className={classes.rootDialog}>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <FormControl className={classes.formControl}>
                                <InputLabel shrink id='financing-entity-label'>
                                    Art der Financing Entity
                                </InputLabel>
                                <Select
                                    id='financing-entity-select'
                                    value={newFinancingEntity.type ? newFinancingEntity.type : ''}
                                    onChange={handleFinancingEntityChange}
                                    displayEmpty
                                    className={classes.selectEmpty}
                                    required
                                    name={'type'}
                                >
                                    <MenuItem value=''>
                                        <em>Bitte wählen</em>
                                    </MenuItem>
                                    {Object.keys(financingEntityTypes).map(type => {
                                        return (
                                            <MenuItem key={type} value={type}>{financingEntityTypes[type]}</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <InputLabel shrink id='source-label'>
                                    Quelle
                                </InputLabel>
                                <Select
                                    id='source-select'
                                    value={newFinancingEntity.source ? newFinancingEntity.source : ''}
                                    onChange={handleFinancingEntityChange}
                                    displayEmpty
                                    className={classes.selectEmpty}
                                    required
                                    name={'source'}
                                >
                                    <MenuItem value=''>
                                        <em>Bitte wählen</em>
                                    </MenuItem>
                                    {Object.keys(sourceTypes).map(type => {
                                        return (
                                            <MenuItem key={type} value={type}>{sourceTypes[type]}</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <InputLabel shrink id='legal-setup-label'>
                                    Legal Setup
                                </InputLabel>
                                <Select
                                    id='legal-setup-select'
                                    value={newFinancingEntity.legalSetup ? newFinancingEntity.legalSetup : ''}
                                    onChange={handleFinancingEntityChange}
                                    displayEmpty
                                    className={classes.selectEmpty}
                                    required
                                    name={'legalSetup'}
                                >
                                    <MenuItem value=''>
                                        <em>Bitte wählen</em>
                                    </MenuItem>
                                    {Object.keys(financingEntityLegalSetupTypes).map(type => {
                                        return (
                                            <MenuItem key={type}
                                                      value={type}>{financingEntityLegalSetupTypes[type]}</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <TextField
                                    id={'fundingTargetMin'}
                                    type={'number'}
                                    onBlur={handleFinancingEntityChange}
                                    defaultValue={0}
                                    name={'fundingTargetMin'}
                                    label={'Betrag'}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item sm={3}>
                            <FormControl className={classes.formControl}>
                                <InputLabel shrink id='starts-at-label'>
                                    Start
                                </InputLabel>
                                <CustomDatePicker
                                    id={'financing-starts-at'}
                                    value={newFinancingEntity.startsAt ? newFinancingEntity.startsAt : ''}
                                    onChange={handleFinancingEntityChange}
                                    name={'startsAt'}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item sm={3}>
                            <FormControl className={classes.formControl}>
                                <InputLabel shrink id='ends-at-label'>
                                    Ende
                                </InputLabel>
                                <CustomDatePicker
                                    id={'financing-ends-at'}
                                    value={newFinancingEntity.endsAt ? newFinancingEntity.endsAt : ''}
                                    onChange={handleFinancingEntityChange}
                                    name={'endsAt'}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
            </Dialog>
            <Dialog
                open={deletePlannedMeasureOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleDeletePlannedMeasureClose}
            >
                <DialogTitle>{'Geplante Maßnahme löschen'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Bist du dir sicher, dass du diese Geplante Maßnahme und alle dazugehörigen Daten löschen
                        möchtest?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeletePlannedMeasureClose} color='primary' autoFocus>
                        Abbrechen
                    </Button>
                    <Button
                        variant='contained'
                        onClick={handleDeletePlannedMeasure}
                        className={classes.deleteButton}
                        startIcon={<DeleteIcon/>}
                    >
                        Löschen
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
