import React, {ReactElement, useEffect, useState} from 'react';
import EditableCard from '../../UI/Cards/EditableCard';
import Project from '../../../interfaces/models/Project';
import CardData from '../../../interfaces/CardData';
import LandRegisterCardProps from '../../../interfaces/props/LandRegisterCardProps';
import LandRegister from '../../../interfaces/models/LandRegister';

export default function LandRegisterInventoryCard(props: LandRegisterCardProps): ReactElement {
    const {landRegister} = props;
    const [data, setData] = useState<LandRegister>({} as LandRegister);

    useEffect(() => {
        setData(landRegister);
    }, [landRegister]);

    const tableData: CardData[] = [
        {
            label: 'Flurstück',
            value: data.parcel ? data.parcel : null,
            name: 'parcel',
            editable: true,
        },
        {
            label: 'Flur',
            value: data.cadastralDistrict ? data.cadastralDistrict : null,
            name: 'cadastralDistrict',
            editable: true,
        },
        {
            label: 'Wirtschaftsart',
            value: data.typeEconomy ? data.typeEconomy : null,
            name: 'typeEconomy',
            editable: true,
        },
        {
            label: 'Verbundene Rechte',
            value: data.attachingRights ? data.attachingRights : null,
            name: 'attachingRights',
            editable: true,
        },
    ];

    const handleSave = (updatedData: Project): void => {
        setData({
            ...data,
            ...updatedData
        });

        props.onSave(updatedData);
    };

    return (
        <EditableCard
            tableData={tableData}
            onSave={handleSave}
            title={'Bestandsverzeichnis'}
        />
    );
}
