import React from 'react';
import Link from '@material-ui/core/Link';
import {Link as RouterLink} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import BreadcrumbItem from '../../../interfaces/BreadcrumbItem';
import BreadcrumbProps from '../../../interfaces/props/BreadcrumbProps';

export default function Breadcrumb(props: BreadcrumbProps) {
    const items = props.items;

    if (items.length === 0) {
        return null;
    }

    return (
        <Breadcrumbs aria-label="breadcrumb">
            {items.map((item: BreadcrumbItem) => {
                switch(item.type) {
                    case 'link':
                        return (
                            <Link
                                color="inherit"
                                component={RouterLink}
                                to={item.link || ''}
                                key={item.name}
                            >
                                {item.name}
                            </Link>
                        )
                    case 'typography':
                        return (
                            <Typography
                                color="textPrimary"
                                key={item.name}
                            >
                                {item.name}
                            </Typography>
                        )
                    default:
                        return null;
                }
            })}
        </Breadcrumbs>
    );
}
