import React, {useEffect, useState} from 'react';
import RealEstatePropertyDetailPageProps from '../../../interfaces/props/RealEstatePropertyDetailPageProps';
import EditableCard from '../../UI/Cards/EditableCard';
import {Grid} from '@material-ui/core';
import RealEstateProperty from '../../../interfaces/models/RealEstateProperty';
import CardData from "../../../interfaces/CardData";
import {RenderTypes} from "../../../interfaces/enums/RenderTypes";
import Formatter from "../../../helpers/Formatter";
import {useAppContext} from "../../../libs/contextLib";
import RealEstatePropertyService from "../../../services/RealEstatePropertyService";

export default function RealEstatePropertyOriginationData(props: RealEstatePropertyDetailPageProps) {
    const {selectOptions} = useAppContext();

    const [data, setData] = useState<RealEstateProperty[]>([]);
    const [generalCardData, setGeneralcardData] = useState<CardData[]>([]);
    const [objectCardData, setObjectcardData] = useState<CardData[]>([]);
    const [assetClasses, setAssetClasses] = useState<{[key: string]: string}>({});

    useEffect(() => {
        if (!selectOptions) {
            return;
        }

        if (Object.keys(selectOptions).length === 0) {
            return;
        }

        setAssetClasses(selectOptions.realEstateProperty.assetClasses);
    }, [selectOptions]);

    useEffect(() => {
        if (props.realEstateProperties) {
            setData(props.realEstateProperties);
        }
    }, [props.realEstateProperties]);

    useEffect(() => {
        const generalColumns = [] as CardData[];
        const objectColumns = [] as CardData[];
        data.forEach(item => {
            generalColumns.push(
                {
                    label: 'Verkehrswertgutachten',
                    value: item.marketValueAppraisal,
                    name: 'marketValueAppraisal',
                    editable: true,
                    type: RenderTypes.floatCurrency,
                    formatter: Formatter.formatCurrency,
                },
                {
                    label: 'Kaufpreis',
                    value: item.purchasePrice,
                    name: 'purchasePrice',
                    editable: true,
                    type: RenderTypes.floatCurrency,
                    formatter: Formatter.formatCurrency,
                },
                {
                    label: 'Jahresnettomieteinnahmen 1. Jahr',
                    value: item.basicRentFirstYear,
                    name: 'basicRentFirstYear',
                    editable: true,
                    type: RenderTypes.floatCurrency,
                    formatter: Formatter.formatCurrency,
                },
                {
                    label: 'Nettokaufpreisfaktor',
                    value: item.purchaseFactor,
                    name: 'purchaseFactor',
                    editable: true,
                    formatter: Formatter.formatFactor,
                },
                {
                    label: 'Baujahr',
                    value: item.constructionYear,
                    name: 'constructionYear',
                    editable: true,
                },
                {
                    label: 'Jahr der letzten Modernisierung',
                    value: item.lastModernizationYear,
                    name: 'lastModernizationYear',
                    editable: true,
                },
                {
                    label: 'Asset-Klasse',
                    value: item.assetClass,
                    name: 'assetClass',
                    id: item.assetClass,
                    type: RenderTypes.select,
                    selectOptions: Formatter.formatSelectOptionsFromObject(assetClasses),
                    // @ts-ignore
                    formatter: () => assetClasses[item.assetClass],
                    editable: true,
                },
            );
            objectColumns.push(
                {
                    label: 'Vermietungsstand',
                    value: item.occupancyRate,
                    name: 'occupancyRate',
                    editable: true,
                    type: RenderTypes.floatPercent,
                    formatter: Formatter.formatPercentage,
                },
                {
                    label: 'Wohnfläche (in m²)',
                    value: item.livingArea,
                    name: 'livingArea',
                    editable: true,
                    type: RenderTypes.floatPercent,
                    formatter: Formatter.formatSquareMeters,
                },
                {
                    label: 'Gewerbefläche Gastronomie (in m²)',
                    value: item.gastronomyArea,
                    name: 'gastronomyArea',
                    editable: true,
                    type: RenderTypes.floatPercent,
                    formatter: Formatter.formatSquareMeters,
                },
                {
                    label: 'Gewerbefläche Einzelhandel (in m²)',
                    value: item.retailArea,
                    name: 'retailArea',
                    editable: true,
                    type: RenderTypes.floatPercent,
                    formatter: Formatter.formatSquareMeters,
                },
                {
                    label: 'Bürofläche (in m²)',
                    value: item.officeArea,
                    name: 'officeArea',
                    editable: true,
                    type: RenderTypes.floatPercent,
                    formatter: Formatter.formatSquareMeters,
                },
                {
                    label: 'Lager, Archiv, Stellplätze (in m²)',
                    value: item.stockArea,
                    name: 'stockArea',
                    editable: true,
                    type: RenderTypes.floatPercent,
                    formatter: Formatter.formatSquareMeters,
                },
                {
                    label: 'Fläche Sonstiges (in m²)',
                    value: item.otherArea,
                    name: 'otherArea',
                    editable: true,
                    type: RenderTypes.floatPercent,
                    formatter: Formatter.formatSquareMeters,
                },
                {
                    label: 'Leerstand (in m²)',
                    value: item.emptyArea,
                    name: 'emptyArea',
                    editable: true,
                    type: RenderTypes.floatPercent,
                    formatter: Formatter.formatSquareMeters,
                },
                {
                    label: 'Wohnmiete pro m²',
                    value: item.residentialRentSquareMeter,
                    name: 'residentialRentSquareMeter',
                    editable: true,
                    type: RenderTypes.floatPercent,
                    formatter: Formatter.formatCurrency,
                },
                {
                    label: 'Gewerbemiete pro m²',
                    value: item.commercialRentSquareMeter,
                    name: 'commercialRentSquareMeter',
                    editable: true,
                    type: RenderTypes.floatPercent,
                    formatter: Formatter.formatCurrency,
                },
                {
                    label: 'Büromiete pro m²',
                    value: item.officeRentSquareMeter,
                    name: 'officeRentSquareMeter',
                    editable: true,
                    type: RenderTypes.floatPercent,
                    formatter: Formatter.formatCurrency,
                },
                {
                    label: 'Miete Sonstiges pro m²',
                    value: item.otherRentSquareMeter,
                    name: 'otherRentSquareMeter',
                    editable: true,
                    type: RenderTypes.floatPercent,
                    formatter: Formatter.formatCurrency,
                },
                {
                    label: 'Anzahl Wohnungen',
                    value: item.livingUnits,
                    name: 'livingUnits',
                    editable: true,
                },
                {
                    label: 'Gewerbeeinheiten',
                    value: item.commercialUnits,
                    name: 'commercialUnits',
                    editable: true,
                },
                {
                    label: 'Büroeinheiten',
                    value: item.officeUnits,
                    name: 'officeUnits',
                    editable: true,
                },
                {
                    label: 'Stellplätze drinnen',
                    value: item.parkingLotsInside,
                    name: 'parkingLotsInside',
                    editable: true,
                },
                {
                    label: 'Stellplätze draussen',
                    value: item.parkingLotsOutside,
                    name: 'parkingLotsOutside',
                    editable: true,
                },
            )
        })

        setGeneralcardData(generalColumns);
        setObjectcardData(objectColumns);
    }, [data, assetClasses]);

    const handleSave = (updatedData: RealEstateProperty) => {
        RealEstatePropertyService.update(updatedData.id,{
            ...updatedData
        }).then((result: RealEstateProperty) => {
            const stateData = [...data];

            const updatedProperty = data.filter(item => {
                return item.id === updatedData.id;
            })[0];

            const index = stateData.indexOf(updatedProperty);

            Object.assign(stateData[index], result);
            setData([...stateData]);
        }).catch(() => { });
    };

    return (
        <>
            {props.realEstateProperties && props.realEstateProperties.map(property => {
                return (
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <EditableCard
                                title={`${property.name} - Allgemeine Informationen`}
                                tableData={generalCardData}
                                onSave={handleSave}
                                id={property.id}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <EditableCard
                                title={`${property.name} - Objekt- und Mietdaten`}
                                tableData={objectCardData}
                                onSave={handleSave}
                                id={property.id}
                            />
                        </Grid>
                    </Grid>
                )
            })}
        </>
    );
}
