import React, {forwardRef, useCallback, useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import {
    Add,
    ArrowDownward,
    Check,
    ChevronLeft,
    ChevronRight,
    Clear,
    DeleteOutline,
    Edit,
    FilterList,
    FirstPage,
    LastPage,
    Remove,
    SaveAlt,
    Search,
    SettingsBackupRestore,
    ViewColumn,
} from '@material-ui/icons';
import SingleValueStorageHelper from '../../../helpers/SingleValueStorageHelper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import {useTheme} from '@material-ui/core/styles';
import ThemeHelper from '../../../helpers/ThemeHelper';
import {diff} from 'deep-object-diff';
import CustomDatePicker from '../Forms/CustomDatePicker';
import {useAppContext} from '../../../libs/contextLib';
import UserHelper from '../../../helpers/UserHelper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import AccountSelect from '../Forms/AccountSelect';
import Formatter from '../../../helpers/Formatter';
import Render from '../../../helpers/Render';
import DebugHint from '../Partials/DebugHint';

const useStyles = makeStyles((theme) => ({
    button: {
        marginBottom: theme.spacing(1),
        backgroundColor: theme.palette.customBackground.lightGrey,
    },
}));

const uncheckedIcon = <CheckBoxOutlineBlankIcon fontSize={'small'}/>;
const checkedIcon = <CheckBoxIcon fontSize={'small'}/>;

export default function BaseTable(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [reload, setReload] = useState(0);
    const [debugInfo, setDebugInfo] = useState('');
    const {user} = useAppContext();

    const forceUpdate = () => {
        setReload(reload + 1);
    }

    const numberFormatIntegerCurrency = props => {
        return Render.numberFormatInteger(props, '€ ');
    }

    const numberFormatFloatSquareMeters = (props) => {
        return Render.numberFormatFloat(props, ' ㎡', true);
    }

    const handleAutocompleteChange = (props, event, item) => {
        if (!item) {
            return;
        }

        props.onChange(item);
    };

    const handleAccountAutocompleteChange = (props, event) => {
        if (!event) {
            return;
        }

        props.onChange(event.target.value);
    };

    const resolveCustomTypes = useCallback((columns) => {
        columns.forEach(column => {
            switch (column.type) {
                case 'date':
                    column.editComponent = props => (
                        <CustomDatePicker
                            value={props.value}
                            onChange={e => props.onChange(e.target.value)}
                            name={props.field}
                            size={'small'}
                        />);
                    break;
                case 'currency':
                    column.type = 'string';
                    column.editComponent = props => (
                        <TextField
                            id={props.field}
                            onChange={e => props.onChange(e.target.value)}
                            defaultValue={props.value ? props.value : ''}
                            name={props.field}
                            size={'small'}
                            style={{width: '100%'}}
                            InputProps={{
                                inputComponent: numberFormatIntegerCurrency,
                            }}
                        />);
                    break;
                case 'squareMeter':
                    column.type = 'string';
                    column.editComponent = props => (
                        <TextField
                            id={props.field}
                            onChange={e => props.onChange(e.target.value)}
                            defaultValue={props.value ? props.value : ''}
                            name={props.field}
                            size={'small'}
                            style={{width: '100%'}}
                            InputProps={{
                                inputComponent: numberFormatFloatSquareMeters,
                            }}
                        />);
                    break;
                case 'accountSelect':
                    column.type = 'string';
                    column.editComponent = props => (
                        <AccountSelect
                            name={'name'}
                            defaultValue={props.value}
                            onChange={(...data) => handleAccountAutocompleteChange(props, ...data)}
                            onKeyDown={event => props.onKeyDown(event)}
                            accountType={column.accountType}
                            formatter={Formatter.formatAccountSelectOption}
                        />);
                    break;
                case 'multiSelect':
                    column.type = 'string';
                    column.editComponent = props => (
                        <Autocomplete
                            multiple
                            id={`{checkboxes-tags-${column.field}}`}
                            size='small'
                            options={column.options}
                            value={props.value}
                            onChange={(...data) => handleAutocompleteChange(props, ...data)}
                            disableCloseOnSelect
                            getOptionSelected={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => option.name}
                            renderOption={(option, {selected}) => (
                                <React.Fragment>
                                    <Checkbox
                                        icon={uncheckedIcon}
                                        checkedIcon={checkedIcon}
                                        style={{marginRight: 8}}
                                        checked={selected}
                                    />
                                    {option.name}
                                </React.Fragment>
                            )}
                            style={{width: 200}}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant='outlined'
                                />
                            )}
                        />);
                    break;
                default:
                    // Do nothing...
                    break;
            }
        });

        return columns;
    }, []);

    useEffect(() => {
        const columns = resolveCustomTypes(props.columns);

        const savedOrderBy = SingleValueStorageHelper.get(`materialtable-orderBy-${props.tableName}`);
        const savedOrderDirection = SingleValueStorageHelper.get(`materialtable-direction-${props.tableName}`);

        if (savedOrderBy && savedOrderDirection) {
            columns[savedOrderBy] = {...columns[savedOrderBy], defaultSort: savedOrderDirection};
        }

        const savedFilter = SingleValueStorageHelper.get(`materialtable-filters-${props.tableName}`, null);
        if (savedFilter) {
            const filter = JSON.parse(savedFilter);
            filter.forEach(element => {
                columns[element.column.tableData.columnOrder] = {
                    ...columns[element.column.tableData.columnOrder],
                    defaultFilter: element.value,
                };
            });
        }

        const debugInfo = columns.map(column => {
            if (!('field' in column)) {
                return '';
            }

            return column['field'] === Formatter.camelToSnake(column['field'])
                ? `<b>${column['title']}:</b> ${column['field']}`
                : `<b>${column['title']}:</b> ${column['field']} | ${Formatter.camelToSnake(column['field'])}`;
        });
        setDebugInfo(debugInfo.join('<br>'));

        setColumns(columns);

        const tableData = props.data;
        if (tableData) {
            setData(tableData);
        }
    }, [props, reload, resolveCustomTypes]);

    const handleOrderChange = (orderBy, orderDirection) => {
        if (
            Number.isInteger(orderBy) &&
            orderBy >= 0 &&
            (orderDirection === 'asc' || orderDirection === 'desc')
        ) {
            SingleValueStorageHelper.set(`materialtable-orderBy-${props.tableName}`, orderBy);
            SingleValueStorageHelper.set(`materialtable-direction-${props.tableName}`, orderDirection);
        }
    };

    const handleSearchChange = searchtext => {
        SingleValueStorageHelper.set(`materialtable-search-${props.tableName}`, searchtext);
    }

    const handleFilterChange = filter => {
        SingleValueStorageHelper.set(`materialtable-filters-${props.tableName}`, JSON.stringify(filter));
    }

    const handleResetUserSettings = () => {
        const savedOrderBy = SingleValueStorageHelper.get(`materialtable-orderBy-${props.tableName}`);
        if (savedOrderBy) {
            delete columns[savedOrderBy].defaultSort;
            setColumns(columns);
            SingleValueStorageHelper.remove(`materialtable-orderBy-${props.tableName}`);
            SingleValueStorageHelper.remove(`materialtable-direction-${props.tableName}`);
        }

        const savedFilter = SingleValueStorageHelper.get(`materialtable-filters-${props.tableName}`, null);
        if (savedFilter) {
            const filter = JSON.parse(savedFilter);
            filter.forEach(element => {
                delete columns[element.column.tableData.columnOrder].defaultFilter;
            });
            setColumns(columns);
            SingleValueStorageHelper.remove(`materialtable-filters-${props.tableName}`);
        }

        //TODO: Material-Table Bug - SearchText & Filters are not updated by rerender - only on page reload :(
        SingleValueStorageHelper.remove(`materialtable-search-${props.tableName}`);
        //TODO: workaround as long material-table not support reset search fields
        const clearSearchElement = document.querySelector('[aria-label="Clear Search"]');
        if (clearSearchElement) {
            clearSearchElement.click();
        }

        forceUpdate();
    }

    const tableIcons = {
        Add: forwardRef((props, ref) => <Add {...props} ref={ref}/>),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref}/>),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>)
    };

    return (
        <>
            <Box display='flex' justifyContent='flex-end'>
                <DebugHint id={debugInfo}/>
                <Button
                    variant='contained'
                    size='small'
                    className={classes.button}
                    startIcon={<SettingsBackupRestore/>}
                    onClick={handleResetUserSettings}
                >
                    Filter zurücksetzen
                </Button>
            </Box>
            <MaterialTable
                key={props.tableName}
                title={props.title || ''}
                columns={columns}
                data={[...data]}
                options={{
                    actionsColumnIndex: -1,
                    pageSize: 20,
                    columnsButton: props.columnsButton === undefined ? true : props.columnsButton,
                    toolbar: props.toolbar === undefined ? true : props.toolbar,
                    pageSizeOptions: [10, 20, 50],
                    stickyHeader: true,
                    filtering: props.filtering === undefined ? true : props.filtering,
                    search: props.search === undefined ? true : props.search,
                    paging: props.paging === undefined ? true : props.paging,
                    emptyRowsWhenPaging: false,
                    searchText: SingleValueStorageHelper.get(`materialtable-search-${props.tableName}`),
                    rowStyle: (rowData, index) => {
                        if (index % 2) {
                            return {
                                backgroundColor: ThemeHelper.getColor(theme, theme.palette.tableOdd),
                            }
                        }
                    },
                    actionsCellStyle: {
                        color: ThemeHelper.getColor(theme, theme.palette.primary),
                    },
                }}
                icons={tableIcons}
                localization={{
                    body: {
                        addTooltip: 'Hinzufügen',
                        deleteTooltip: 'Löschen',
                        editRow: {
                            cancelTooltip: 'Abbrechen',
                            deleteText: 'Bist du dir sicher, dass du diese Zeile löschen möchtest?',
                            saveTooltip: 'Speichern',
                        },
                        editTooltip: 'Bearbeiten',
                        emptyDataSourceMessage: 'Keine Daten gefunden',
                        filterRow: {
                            filterPlaceHolder: '',
                            filterTooltip: 'Filter',
                        },
                    },
                    grouping: {
                        placeholder: 'Spalten ziehen ...',
                        groupedBy: 'Gruppiert nach:'
                    },
                    header: {
                        actions: 'Aktionen',
                    },
                    pagination: {
                        firstAriaLabel: 'Erste Seite',
                        firstTooltip: 'Erste Seite',
                        labelDisplayedRows: '{from}-{to} von {count}',
                        labelRowsPerPage: 'Zeilen pro Seite:',
                        labelRowsSelect: props.objects || 'Objekte',
                        lastAriaLabel: 'Letzte Seite',
                        lastTooltip: 'Letzte Seite',
                        nextAriaLabel: 'Nächste Seite',
                        nextTooltip: 'Nächste Seite',
                        previousAriaLabel: 'Vorherige Seite',
                        previousTooltip: 'Vorherige Seite',
                    },
                    toolbar: {
                        addRemoveColumns: 'Spalten hinzufügen oder löschen',
                        exportAriaLabel: 'Exportieren',
                        exportName: 'Als CSV exportieren',
                        exportTitle: 'Exportieren',
                        nRowsSelected: '{0} Spalte(n) ausgewählt',
                        searchPlaceholder: 'Suche',
                        searchTooltip: 'suchen',
                        showColumnsTitle: 'Spalten anzeigen',
                        showColumnsAriaLabel: 'Spalten anzeigen',
                    },
                }}
                editable={{
                    isEditable: props.isEditable ? props.isEditable : undefined,
                    onRowAdd: !UserHelper.hasPermission(user, 'edit') || !props.enableRowAdd
                        ? null
                        : newData =>
                            props.onAdd(newData).then(newData => {
                                const stateData = data;
                                stateData.push(newData);
                                setData([...stateData]);
                            }),
                    onRowUpdate: !UserHelper.hasPermission(user, 'edit') || props.disableUpdateAction
                        ? null
                        : (newData, oldData) => {
                            const updatedData = props.onUpdateDisableDiff
                                ? newData
                                : diff(oldData, newData);

                            // Check if a column was actually updated.
                            if (Object.keys(updatedData).length === 0) {
                                return Promise.resolve();
                            }

                            // We need to manually assign the ID because it will never be changed on update.
                            updatedData.id = newData.id;

                            return props.onUpdate(updatedData, newData).then(updatedProject => {
                                const stateData = data;
                                const index = stateData.indexOf(oldData);
                                stateData[index] = updatedProject;

                                setData([...stateData]);
                            });
                        },
                    onRowDelete: !UserHelper.hasPermission(user, 'edit') || props.disableDeleteAction
                        ? null
                        : (oldData =>
                            props.onDelete(oldData).then(() => {
                                const stateData = data;
                                const index = stateData.indexOf(oldData);
                                stateData.splice(index, 1);

                                setData([...stateData]);
                            }))
                }}
                onOrderChange={handleOrderChange}
                onSearchChange={handleSearchChange}
                onFilterChange={handleFilterChange}
            />
        </>
    );
}
