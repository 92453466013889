export enum RenderTypes {
    accountSelect = 'accountSelect',
    currency = 'currency',
    date = 'date',
    floatCurrency = 'floatCurrency',
    negativeFloatCurrency = 'negativeFloatCurrency',
    floatPercent = 'floatPercent',
    integerCurrency = 'integerCurrency',
    multiSelect = 'multiSelect',
    select = 'select',
    squareMeter = 'squareMeter',
    squareMeters = 'squareMeters',
    divider = 'divider',
}
