import React, {useEffect, useState} from 'react';
import AccountsTable from './AccountsTable';
import Loader from '../UI/Loader';
import Helper from '../../helpers/Helper';

export default function Accounts(props) {
    const {setMenuItems, setBreadcrumbItems} = props;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setMenuItems([]);
        setBreadcrumbItems([]);
        Helper.setPageTitle('Accounts');
    }, [setMenuItems, setBreadcrumbItems]);

    return (
        <>
            <AccountsTable setLoader={setLoading}/>
            <Loader open={loading}/>
        </>
    );
}
