const itemName: string = 'tenants-import-data';

export default {
    getTenantsImportData: (): any => {
        const rawData = localStorage.getItem(itemName);
        if (!rawData) {
            return null;
        }

        try {
            const data = JSON.parse(rawData);
            localStorage.removeItem(itemName);
            return data;
        } catch (exception) {
            return null;
        }
    },
    setTenantsImportData: (value: any): void => {
        if (!value) {
            return;
        }

        localStorage.setItem(itemName, JSON.stringify(value));
    },
}
