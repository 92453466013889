import React, {useEffect, useState} from 'react';
import FutureDetails from "./FutureDetails";
import Project from "../../../interfaces/models/Project";
import ProjectService from "../../../services/ProjectService";
import ProjectsProps from "../../../interfaces/props/ProjectsProps";

export default function FutureOriginationData(props: ProjectsProps) {
    const [project, setProject] = useState<Project>({} as Project);

    useEffect(() => {
        if (props.project) {
            setProject(props.project);
        }
    }, [props.project]);

    const handleSave = (updatedData: Project) => {
        setProject({
            ...project,
            ...updatedData,
        });

        ProjectService.updateFromTable(project.id, {
            ...updatedData
        }).then(() => {

        }).catch(() => { });
    };

    return (
        <>
            <FutureDetails project={project} onSave={handleSave}/>
        </>
    );
}
