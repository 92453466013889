import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import {useAppContext} from '../../libs/contextLib';
import {useHistory} from 'react-router-dom';
import UserService from '../../services/UserService';
import config from '../../config';
import EventSystem from '../../EventSystem';
import Helper from '../../helpers/Helper';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: 'url(/images/mood/mood' + (Math.floor(Math.random() * 3) + 1) + '.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    exporoLogo: {
        maxWidth: '150px',
        display: 'block',
        margin: '15px auto',
    },
    error: {
        color: 'red',
    },
}));

export default function SignInSide() {
    const [errorText, setErrorText] = useState('');
    const classes = useStyles();

    const {setIsAuthenticated} = useAppContext();
    const history = useHistory();

    const redirect = (redirect) => {
        history.push(redirect);
    }

    EventSystem.subscribe('redirect', redirect);

    async function handleSubmit(token) {
        await UserService.auth(token);
        setIsAuthenticated(true);

        EventSystem.publish('loggedIn');

        // remove get parameters from url
        window.history.replaceState(null, null, window.location.pathname);
        history.push('/projects');
    }

    const search = window.location.search;
    const params = new URLSearchParams(search);

    useEffect(() => {
        const error = params.get('error');
        if (error) {
            if (error === 'access_denied') {
                setErrorText('Keine Berechtigung. Sollte dies ein Fehler sein, wende dich bitte an den Helpdesk.');
            } else {
                setErrorText('Fehler aufgetreten. Bitte versuche es später erneut.');
            }
        }
    }, [params])

    const token = params.get('token');
    if (token) {
        handleSubmit(token);
    }

    Helper.setPageTitle('Login');

    const envConfig = config[process.env.REACT_APP_ENV];

    return (
        <Grid container component='main' className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={classes.image} />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <img src={`${process.env.PUBLIC_URL}/images/Exporo-Logo.png`} alt={'Exporo Logo'} className={classes.exporoLogo}/>
                    <Grid container>
                        <Grid item xs>
                        </Grid>
                        <Grid item className={classes.error}>
                            {errorText}
                        </Grid>
                    </Grid>
                    <Button
                        type='submit'
                        fullWidth
                        variant='contained'
                        color='primary'
                        className={classes.submit}
                        href={envConfig.API_LOGIN_URL}
                    >
                        Login via OneLogin
                    </Button>
                    <Grid container>
                        <Grid item xs>
                        </Grid>
                        <Grid item>
                            Noch keinen Zugang? Dann wende Dich bitte an den Helpdesk.
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    );
}
