import React, {useEffect, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import RealEstateProperty from '../../../../../interfaces/models/RealEstateProperty';
import Helper from '../../../../../helpers/Helper';
import RealEstatePropertyImportPreviewPageProps from '../../../../../interfaces/props/RealEstatePropertyImportPreviewPageProps';
import ImportTenantsTable from './ImportTenantsTable';
import Loader from '../../../../UI/Loader';
import RealEstatePropertyService from '../../../../../services/RealEstatePropertyService';
import {Typography} from '@material-ui/core';
import TenantsImportHelper from '../../../../../helpers/TenantsImportHelper';
import Tenant from '../../../../../interfaces/models/Tenant';
import {makeStyles} from '@material-ui/core/styles';
import {IdMatchParam} from '../../../../../interfaces/matchs/IdMatchParam';

const useStyles = makeStyles({
    infoText: {
        marginTop: 26,
    },
});

export default function RealEstatePropertyImportPreviewPage(props: RealEstatePropertyImportPreviewPageProps) {
    const classes = useStyles();
    const {setMenuItems, setBreadcrumbItems} = props;
    const [data, setData] = useState<RealEstateProperty>({} as RealEstateProperty);
    const [tenants, setTenants] = useState<Tenant[]>([] as Tenant[]);
    const [isLoading, setIsLoading] = useState(false);
    const match = useRouteMatch<IdMatchParam>();

    useEffect(() => {
        setMenuItems!([]);
    }, [setMenuItems]);

    useEffect(() => {
        setIsLoading(true);

        const tenantsData = TenantsImportHelper.getTenantsImportData();
        if (tenantsData) {
            setTenants(tenantsData);
        }

        RealEstatePropertyService.get(parseInt(match.params.id, 10)).then((property: RealEstateProperty) => {
            setData(property);
            setIsLoading(false);

            setBreadcrumbItems!([
                {
                    type: 'link',
                    link: '/projects/',
                    name: 'Projekte',
                },
                {
                    type: 'link',
                    link: '/project/' + property.projectId + '/',
                    name: 'Projekt #' + property.projectId,
                },
                {
                    type: 'link',
                    link: '/real-estate-property/' + property.id + '/',
                    name: property.fullAddress ?? '',
                },
                {
                    type: 'typography',
                    name: 'Mieter importieren',
                },
            ]);
        }).catch(() => {
            setIsLoading(false);
        });

        data.name
            ? Helper.setPageTitle('Objekt - ' + data.name)
            // @ts-ignore
            : Helper.setPageTitle('Objekt #' + match.params.id);

        // @ts-ignore
    }, [match.params.id, setBreadcrumbItems, data.name]);

    if (isLoading) {
        // @ts-ignore
        return <Loader open={isLoading}/>
    }

    return (
        <>
            <Typography
                className={classes.infoText}
            >
                Hier können nochmals alle zu importierenden Mieter überprüft und gegebenenfalls bearbeitet werden.<br/>
                Hinweis: Die Mieter werden erst importiert wenn du unten auf Importieren klickst.
            </Typography>
            <ImportTenantsTable realEstatePropertyId={data.id} tenants={tenants}/>
        </>
    );
}
