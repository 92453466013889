import React, {ReactElement, useEffect, useState} from 'react';
import {Check, Clear} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import ThemeHelper from '../../../helpers/ThemeHelper';
import ButtonEditOkCancelProps from '../../../interfaces/props/ButtonEditOkCancelProps';
import {useAppContext} from '../../../libs/contextLib';
import UserHelper from '../../../helpers/UserHelper';

const useStyles = makeStyles( theme => ({
    primary: {
        color: ThemeHelper.getColor(theme, theme.palette.primary),
    },
    cancel: {
        color: ThemeHelper.getColor(theme, theme.palette.error),
    },
    secondary: {
        color: theme.palette.common.white,
    }
}));

export default function ButtonEditOkCancel(props: ButtonEditOkCancelProps): ReactElement | null {
    const [editMode, setEditMode] = useState(props.editMode);
    const {user} = useAppContext();
    const classes = useStyles();

    useEffect(() => {
        setEditMode(props.editMode);
    }, [props.editMode]);

    const handleEdit = (): void => {
        setEditMode(true);
        props.onEdit();
    }

    const handleCancel = (): void => {
        setEditMode(false);
        props.onCancel();
    }

    const handleSave = (): void => {
        setEditMode(false);
        props.onSave();
    }

    return UserHelper.hasPermission(user, 'edit')
        ? (
        <>
            {editMode ? (
                <>
                    <Button
                        onClick={handleCancel}
                        size='small'
                        className={classes.cancel}
                        startIcon={<Clear/>}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        onClick={handleSave}
                        size='small'
                        color={props.headerStyle === 'secondary' ? 'secondary' : 'primary'}
                        startIcon={<Check/>}
                    >
                        Speichern
                    </Button>
                </>
            ) : (
                <Button
                    onClick={handleEdit}
                    size='small'
                    className={props.headerStyle === 'secondary' ? classes.secondary : classes.primary}
                >
                    <EditIcon/>
                </Button>
            )}
        </>
    ) : null;
}
