import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function CustomSelect(props) {
    const classes = useStyles();
    const [value, setValue] = useState(props.defaultValue);

    const handleChange = (event) => {
        setValue(event.target.value);
        props.onChange(event);
    };

    return (
        <div>
            <FormControl className={classes.formControl}>
                <Select
                    labelId={props.labelId}
                    id={props.id}
                    value={value}
                    onChange={handleChange}
                    name={props.name}
                    size={props.size}
                    displayEmpty
                >
                    {props.noneValue
                        ? <MenuItem key='' value=''>{props.noneValue}</MenuItem>
                        : null
                    }
                    {props.options && props.options.map(option => {
                        return (
                            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        </div>
    );
}
