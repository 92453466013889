import React, {useEffect, useState} from 'react';
import Formatter from '../../helpers/Formatter';
import FinancingEntityService from '../../services/FinancingEntityService';
import EditableCard from '../UI/Cards/EditableCard';
import SendButton from "../UI/Partials/SendButton";
import EventSystem from "../../EventSystem";
import {useRouteMatch} from "react-router-dom";
import ProjectService from "../../services/ProjectService";
import {RenderTypes} from "../../interfaces/enums/RenderTypes";
import {useAppContext} from "../../libs/contextLib";

export default function FinancialProductCard(props) {
    const [financingEntityData, setFinancingEntityData] = useState({});
    const [projectData, setProjectData] = useState({});
    const [createContractSendMode, setCreateContractSendMode] = useState(false);
    const [contractButtonText, setContractButtonText] = useState('Financial Product erstellen');
    const [legalSetupTypes, setLegalSetupTypes] = useState({});

    const {financingEntity} = props;

    const match = useRouteMatch();
    const {selectOptions} = useAppContext();

    useEffect(() => {
        setFinancingEntityData(financingEntity);
        if (selectOptions.financingEntity && selectOptions.financingEntity.legalSetupTypes) {
            setLegalSetupTypes(selectOptions.financingEntity.legalSetupTypes);
        }
    }, [financingEntity, selectOptions]);

    useEffect(() => {
        ProjectService.get(match.params.id).then(response => {
            setProjectData(response);
        });
    }, [match.params]);

    useEffect(() => {
        setCreateContractSendMode(
            !financingEntityData.financialProductId
            && projectData.secondStepCompleted
            && projectData.thirdStepCompleted
            && projectData.fourthStepCompleted
            && projectData.fifthStepCompleted
        );

        if (financingEntityData.financialProductId) {
            setContractButtonText(`FinancialProduct #${financingEntityData.financialProductId}`);
        }
    }, [financingEntityData.financialProductId, projectData, financingEntityData]);

    const legalSetupOptions = () => {
        return Formatter.formatSelectOptionsFromObject(legalSetupTypes);
    };

    const tableData = [
        {
            label: 'Projektname',
            value: projectData.name,
            name: 'project.name',
            editable: true,
        },
        {
            label: 'Legal Setup',
            value: financingEntityData.legalSetup,
            id: financingEntityData.legalSetup,
            name: 'financingEntity.legalSetup',
            type: 'select',
            editable: true,
            selectOptions: legalSetupTypes ? legalSetupOptions() : null,
            formatter: legalSetup => Formatter.formatLegalSetup(legalSetup, legalSetupTypes),
        },
        {
            label: 'Token Nummer',
            value: financingEntityData.tokenNumber,
            name: 'financingEntity.tokenNumber',
            editable: true,
        },
        {
            label: 'Vermittler Besitzgesellschaft',
            value: projectData.financialServicesInstitute ? projectData.financialServicesInstitute.companyName : null,
            id: projectData.financialServicesInstitute ? projectData.financialServicesInstitute : null,
            name: 'project.financialServicesInstituteId',
            editable: true,
            type: RenderTypes.accountSelect,
            noneValue: '-- keine Angabe --',
            formatter: () => Formatter.formatAccountLink(projectData.financialServicesInstitute),
            editFormatter: Formatter.formatAccountSelectOption,
        },
        {
            label: 'Asset Management',
            value: projectData.assetManagementCompany ? projectData.assetManagementCompany.companyName : null,
            id: projectData.assetManagementCompany ? projectData.assetManagementCompany : null,
            name: 'project.assetManagementCompanyId',
            type: RenderTypes.accountSelect,
            noneValue: '-- keine Angabe --',
            editable: true,
            formatter: () => Formatter.formatAccountLink(projectData.assetManagementCompany),
            editFormatter: Formatter.formatAccountSelectOption,
        },
        {
            label: 'Mindestinvestmentsumme',
            value: financingEntityData.investingAmountMin,
            name: 'financingEntity.investingAmountMin',
            formatter: Formatter.formatCurrency,
            type: 'number',
            editable: true,
        },
        {
            label: 'Funding Target',
            value: financingEntityData.fundingTargetMin,
            name: 'financingEntity.fundingTargetMin',
            formatter: Formatter.formatCurrency,
            type: 'integerCurrency',
            editable: true,
        },
        {
            label: 'Rückzahlungsintervall in Monaten',
            value: 3,
            name: 'paybackIntervalInMonths',
            editable: false,
        },
        {
            label: 'Zinssatz',
            value: projectData.expectedDividendTenYearAverage,
            name: 'project.expectedDividendTenYearAverage',
            editable: true,
            formatter: Formatter.formatPercentage,
            type: 'floatPercent',
        },
        {
            label: 'Zinssatz am Ende der Laufzeit',
            value: projectData.expectedTotalDividendPerYear,
            name: 'project.expectedTotalDividendPerYear',
            editable: true,
            formatter: Formatter.formatPercentage,
            type: 'floatPercent',
        },
        {
            label: 'Erstes Rückzahlungsdatum',
            value: financingEntityData.firstInterestDistribution,
            name: 'financingEntity.firstInterestDistribution',
            formatter: Formatter.formatDate,
            type: 'date',
            editable: true,
        },
        {
            label: 'Vermittlungskosten',
            value: financingEntityData.completionCostsPercentage,
            name: 'financingEntity.completionCostsPercentage',
            formatter: Formatter.formatPercentage,
            editable: true,
            type: 'floatPercent',
        },
        {
            label: 'Startdatum',
            value: financingEntityData.startsAt,
            name: 'financingEntity.startsAt',
            formatter: Formatter.formatDate,
            type: 'date',
            editable: true,
        },
        {
            label: 'Enddatum',
            value: financingEntityData.endsAt,
            name: 'financingEntity.endsAt',
            formatter: Formatter.formatDate,
            type: 'date',
            editable: true,
        },
    ];

    const parsePayload = (payload) => {
        let parsedPayload = {
            financingEntity: [],
            project: [],
        };
        Object.entries(payload).forEach(([key, value]) => {
            if (key.substring(0, key.indexOf('.')) === 'financingEntity') {
                parsedPayload.financingEntity = {
                    ...parsedPayload.financingEntity,
                    [key.substring(key.indexOf('.') + 1, key.length)]: value,
                }
            }
            if (key.substring(0, key.indexOf('.')) === 'project') {
                parsedPayload.project = {
                    ...parsedPayload.project,
                    [key.substring(key.indexOf('.') + 1, key.length)]: value,
                }
            }
        })

        return parsedPayload;
    }

    const handleSave = (updatedData) => {
        const payload = parsePayload(updatedData);
        // check if fundingTarget % minInvestmentSum === 0

        setFinancingEntityData({
            ...financingEntityData,
            ...payload.financingEntity,
        });

        setProjectData({
            ...projectData,
            ...payload.project,
        })

        FinancingEntityService.update(financingEntityData.id, payload.financingEntity);
        ProjectService.updateFromTable(match.params.id, payload.project);
    };

    const handleCreateFinancialProduct = () => {
        setCreateContractSendMode(false);
        FinancingEntityService.createFinancialProduct(financingEntityData.id).then(response => {
            setFinancingEntityData(response);
            EventSystem.publish(
                'notification',
                'Das Financial Product wurde erfolgreich erstellt!',
                {
                    variant: 'success',
                }
            );
        }).catch((error) => {
            console.log(error);
            setCreateContractSendMode(true);
        });
    }

    return (
        <EditableCard
            tableData={tableData}
            onSave={handleSave}
            title={'Financial Product'}
            cardActions={
                <SendButton
                    buttonText={contractButtonText}
                    title={"Financial Product erstellen"}
                    text={"Das Financial Product wird hiermit erstellt. Möchtest Du jetzt das Financial Product erstellen?"}
                    onSend={handleCreateFinancialProduct}
                    sendMode={createContractSendMode}
                />
            }
        />
    );
}
