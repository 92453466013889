import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import EditableTextCard from '../../UI/Cards/EditableTextCard';
import DescriptionDetailsProps from '../../../interfaces/props/DescriptionDetailsProps';
import RealEstateProperty from '../../../interfaces/models/RealEstateProperty';

export default function DescriptionDetails(props: DescriptionDetailsProps) {
    const [data, setData] = useState<RealEstateProperty>({} as RealEstateProperty);
    const [updatedData, setUpdatedData] = useState<RealEstateProperty>({} as RealEstateProperty);
    const {realEstateProperty} = props;

    useEffect(() => {
        setData(realEstateProperty ?? {} as RealEstateProperty);
    }, [realEstateProperty]);

    const handleChange = (event: any) => {
        const currentData = data;
        // @ts-ignore
        currentData[event.target.name] = event.target.value;
        setData(currentData);

        const currentUpdatedData = updatedData;
        // @ts-ignore
        currentUpdatedData[event.target.name] = event.target.value;
        setUpdatedData(currentUpdatedData);
    };

    return (
        <>
            <Grid container spacing={3} alignItems='stretch'>
                <Grid item sm={12}>
                    <Typography variant={'h5'} component={'h2'}>
                        Beschreibung / Lage
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={3} alignItems='stretch'>
                <Grid item sm={12}>
                    <EditableTextCard
                        data={data}
                        onChange={handleChange}
                        onSave={() => props.onSave!(updatedData)}
                        title={'Objektbeschreibung'}
                        name={'description'}
                    />
                </Grid>
                <Grid item sm={6}>
                    <EditableTextCard
                        data={data}
                        onChange={handleChange}
                        onSave={() => props.onSave!(updatedData)}
                        title={'Macro-Location'}
                        name={'macroLocation'}
                    />
                </Grid>
                <Grid item sm={6}>
                    <EditableTextCard
                        data={data}
                        onChange={handleChange}
                        onSave={() => props.onSave!(updatedData)}
                        title={'Micro-Location'}
                        name={'microLocation'}
                    />
                </Grid>
            </Grid>
        </>
    );
}
