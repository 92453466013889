import React, {useEffect, useState} from 'react';
import Formatter from '../../helpers/Formatter';
import ProjectService from '../../services/ProjectService';
import UserService from '../../services/UserService';
import BaseTable from '../UI/Tables/BaseTable';
import Flag from 'react-world-flags';
import Search from '../../helpers/Search';
import {useAppContext} from '../../libs/contextLib';

export default function ProjectsTable(props) {
    const {selectOptions} = useAppContext();
    const [data, setData] = useState([]);
    const [transactionUsers, setTransactionUsers] = useState({});
    const [assetUsers, setAssetUsers] = useState({});
    const [stageTypes, setStageTypes] = useState({});
    const [countries, setCountries] = useState({});
    const [countriesSearch, setCountriesSearch] = useState({});
    const {setLoader} = props;

    useEffect(() => {
        setLoader(true);

        ProjectService.all().then(projects => {
            setData(projects);
            setLoader(false);
        }).catch(() => {
            setLoader(false);
        });
    }, [setLoader]);

    useEffect(() => {
        if (Object.keys(selectOptions).length === 0) {
            return;
        }

        setStageTypes(selectOptions.project.stageTypes);

        const countriesAvailable = selectOptions.realEstateProperty.countries.available;

        setCountries(Object.keys(countriesAvailable).reduce(function(obj, key) {
            obj[countriesAvailable[key]] = key;
            return obj;
        }, {}));

        setCountriesSearch(Object.keys(countriesAvailable).reduce(function(obj, key) {
            obj[countriesAvailable[key]] = countriesAvailable[key];
            return obj;
        }, {}));
    }, [selectOptions]);

    useEffect(() => {
        formatOwnerUsers();
    }, []);

    const handleUpdate = updatedData => {
        return ProjectService.updateFromTable(updatedData.id, updatedData);
    };

    const handleAdd = newData => {
        return ProjectService.create(newData);
    }

    const formatOwnerUsers = () => {
        let transactionManagers = {};
        let assetManagers = {};

        // TODO: deprecated and will be removed
        UserService.allShort().then(users => {
            users.forEach(user => {
                if (user.roles.includes('transactionManager')) {
                    transactionManagers[user.id] = user.fullName;
                }

                if (user.roles.includes('assetManager')) {
                    assetManagers[user.id] = user.fullName;
                }
            });

            transactionManagers[''] = '-- keine Angabe --';
            assetManagers[''] = '-- keine Angabe --';
        }).catch(() => { });

        setTransactionUsers(transactionManagers);
        setAssetUsers(assetManagers);
    };

    const formatCountryColumn = (row, field, countries) => {
        if (!row[field][0]) {
            return '';
        }

        return row[field].split(',').map(item => {
            if (!item || !countries[item]) {
                return '';
            }

            return (
                <div key={countries[item]}>
                    <Flag code={countries[item]} fallback={item} height='14'/>
                </div>
            );
        });
    };

    const columns = [
        {
            title: 'ID',
            field: 'id',
            render: row => Formatter.formatIdColumn(row, 'project'),
            filtering: false,
            width: '65px',
            editable: 'never',
        },
        {
            title: 'Projektname',
            field: 'name',
            render: row => Formatter.formatNameColumn(row, 'project'),
        },
        {
            title: 'Transaction Manager',
            id: 'transactionManagerId',
            render: row => Formatter.formatOwnerName(row, 'transactionManager'),
            field: 'transactionManagerId',
            lookup: transactionUsers,
        },
        {
            title: 'Asset Manager',
            id: 'assetManagerId',
            render: row => Formatter.formatOwnerName(row, 'assetManager'),
            field: 'assetManagerId',
            lookup: assetUsers,
        },
        {
            title: 'Stage',
            field: 'stage',
            lookup: stageTypes,
        },
        {
            title: 'Kaufpreis',
            id: 'purchasePrice',
            field: 'purchasePrice',
            render: row => Formatter.formatCurrency(row.propertyPurchasePrice ? row.propertyPurchasePrice : row.purchasePrice),
            editable: 'never',
            filtering: false,
        },
        {
            title: 'Buchungskreis',
            field: 'bkr',
            width: '130px',
        },
        {
            title: 'Funding-Volumen',
            id: 'computedFundingVolume',
            field: 'computedFundingVolume',
            render: row => Formatter.formatCurrency(row.fundingVolume),
            editable: 'never',
            filtering: false,
        },
        {
            title: 'Land',
            id: 'countries',
            editable: 'never',
            width: '100px',
            render: row => formatCountryColumn(row, 'countries', countries),
            field: 'countries',
            filtering: true,
            lookup: countriesSearch,
            customFilterAndSearch: (term, rowData) => Search.searchProjectCountry(term, rowData),
        },
    ];

    return (
        <BaseTable
            tableName='projectsTable'
            columns={columns}
            data={data}
            onAdd={handleAdd}
            onUpdate={handleUpdate}
            title='Projektübersicht'
            objects='Projekte'
            disableDeleteAction={true}
            enableRowAdd={true}
        />
    );
}
