import React, {useEffect, useState} from 'react';
import BaseTable from '../../../../UI/Tables/BaseTable';
import Formatter from '../../../../../helpers/Formatter';
import BooleanYesNoTypes from '../../../../../enums/BooleanYesNoTypes';
import ImportTenantsTableProps from '../../../../../interfaces/props/ImportTenantsTableProps';
import Tenant from '../../../../../interfaces/models/Tenant';
import TableData from '../../../../../interfaces/TableData';
import TenantService from '../../../../../services/TenantService';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import EventSystem from '../../../../../EventSystem';
import {Redirect} from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import {TableDataTypes} from "../../../../../interfaces/enums/TableDataTypes";
import {TableDataAccountTypes} from "../../../../../interfaces/enums/TableDataAccountTypes";

const useStyles = makeStyles(theme => ({
    button: {
        background: theme.palette.success.main,
        color: 'white',
        marginTop: 26,
    },
}));

export default function ImportTenantsTable(props: ImportTenantsTableProps) {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const [tableData, setTableData] = useState<Tenant[]>([] as Tenant[]);
    const [redirectToRealEstatePropertyPage, setRedirectToRealEstatePropertyPage] = useState(false);
    const {realEstatePropertyId} = props;

    useEffect(() => {
        setTableData(props.tenants);
    }, [props.tenants]);

    const formatAccountName = (row: any): string => {
        return row.account ? row.account.name : '';
    };

    const columns: TableData[] = [
        {
            title: 'Account',
            id: 'accountId',
            field: 'account',
            render: (row: any) => formatAccountName(row),
            type: TableDataTypes.accountSelect,
            accountType: TableDataAccountTypes.all,
        },
        {
            title: 'Rank',
            field: 'rank',
        },
        {
            title: 'Mietfläche',
            field: 'rentalSpace',
            type: TableDataTypes.squareMeter,
            render: (row: any) => Formatter.formatSquareMeters(row ? row.rentalSpace : null),
        },
        {
            title: 'Mietende',
            field: 'rentalEndDate',
            render: (row: any) => Formatter.formatDate(row.rentalEndDate),
            type: TableDataTypes.date,
        },
        {
            title: 'Mieteinnahmen/Jahr',
            field: 'rentalIncome',
            type: TableDataTypes.currency,
            render: (row: any) => Formatter.formatCurrency(row ? row.rentalIncome : null),
        },
        {
            title: 'automatische Verlängerung',
            field: 'extensionOption',
            render: (row: any) => Formatter.formatBooleanYesNo(row ? row.extensionOption : 0),
            lookup: BooleanYesNoTypes.getTranslations(),
        },
        {
            title: 'Option',
            field: 'option',
        },
    ];

    const handleUpdate = (changedData: Tenant, newData: Tenant) => {
        return Promise.resolve(newData);
    };

    const handleDelete = (data: Tenant) => {
        return Promise.resolve(data);
    };

    const handleImport = () => {
        setLoading(true);

        const data = {
            tenants: tableData,
        };

        TenantService.createMultiple(data).then(() => {
            setLoading(false);

            setRedirectToRealEstatePropertyPage(true);

            EventSystem.publish(
                'notification',
                'Die Mieter wurden erfolgreich gespeichert!',
                {
                    variant: 'success',
                }
            );
        }).catch(() => {
            setLoading(false);

            EventSystem.publish(
                'notification',
                'Es gab einen Fehler beim Speichern der Mieter.',
                {
                    variant: 'error',
                }
            );
        });
    };

    if (redirectToRealEstatePropertyPage) {
        return <Redirect to={`/real-estate-property/${realEstatePropertyId}/`} push={true} />
    }

    return (
        <>
            <BaseTable
                tableName='tenantsTable'
                columns={columns}
                data={tableData}
                onUpdate={handleUpdate}
                onDelete={handleDelete}
                title='Mieter Vorschau'
                objects='Mieter Vorschau'
                filtering={false}
                search={false}
            />
            {loading ? <LinearProgress/> : null}
            <Grid
                container
                direction='row'
                justify='flex-end'
                alignItems='flex-start'
            >
                <Button
                    variant='contained'
                    className={classes.button}
                    startIcon={<CloudUploadIcon/>}
                    onClick={handleImport}
                    disabled={loading}
                >
                    {'Mieter importieren'}
                </Button>
            </Grid>
        </>
    );
}
