import React, {useState} from 'react';
import {CardHeader} from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import {makeStyles} from '@material-ui/core/styles';
import ThemeHelper from '../../helpers/ThemeHelper';
import {useAppContext} from '../../libs/contextLib';
import Switch from '@material-ui/core/Switch';
import DarkModeHelper from '../../helpers/DarkModeHelper';
import SingleValueStorageHelper from '../../helpers/SingleValueStorageHelper';
import UserHelper from '../../helpers/UserHelper';

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: '100%',
        flexGrow: 1,
    },
    card: {
        maxWidth: '100%',
        flexGrow: 1,
        marginTop: 16,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    cardHeader: {
        backgroundColor: ThemeHelper.getColor(theme, theme.palette.cardHeader.backgroundColor),
        color: ThemeHelper.getColor(theme, theme.palette.cardHeader.color),
    },
}));

export default function SettingsCard() {
    const classes = useStyles();
    const [debugMode, setDebugMode] = useState(SingleValueStorageHelper.get('debugMode') === 'true');
    const {handleThemeChange, user} = useAppContext();

    const handleDebugModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDebugMode(event.target.checked);
        SingleValueStorageHelper.set('debugMode', event.target.checked ? 'true' : 'false');
        window.location.reload();
    };

    return (
        <Card className={classes.card}>
            <CardHeader
                title="Einstellungen"
                className={classes.cardHeader}
            >
            </CardHeader>
            <CardContent className={classes.root}>
                <TableContainer component={Paper}>
                    <Table size='small'>
                        <TableBody key={'settings'}>
                            <TableRow key={'dark-mode'}>
                                <TableCell>Dark Modus</TableCell>
                                <TableCell align='right'>
                                    <Switch checked={DarkModeHelper.getDarkModeStatus()} onChange={handleThemeChange}/>
                                </TableCell>
                            </TableRow>
                            {
                                UserHelper.hasPermission(user, 'debug')
                                    ? (<TableRow key={'debug-mode'}>
                                        <TableCell>Debug Modus</TableCell>
                                        <TableCell align='right'>
                                            <Switch checked={debugMode} onChange={handleDebugModeChange}/>
                                        </TableCell>
                                    </TableRow>)
                                    : null
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    );
}
