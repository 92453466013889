import React, {useEffect, useState} from 'react';
import ProjectsProps from "../../../interfaces/props/ProjectsProps";
import {useAppContext} from "../../../libs/contextLib";
import CardData from "../../../interfaces/CardData";
import Project from "../../../interfaces/models/Project";
import {RenderTypes} from "../../../interfaces/enums/RenderTypes";
import Formatter from "../../../helpers/Formatter";
import {Grid} from "@material-ui/core";
import EditableCard from "../../UI/Cards/EditableCard";
import Person from "../../../interfaces/models/Person";
import ProjectService from "../../../services/ProjectService";

export default function ProjectOriginationData(props: ProjectsProps) {
    const {selectOptions} = useAppContext();

    const [data, setData] = useState<Project>({} as Project);
    const [generalCardData, setGeneralcardData] = useState<CardData[]>([]);
    const [costsData, setCostsData] = useState<CardData[]>([]);
    const [riskClasses, setRiskClasses] = useState<{[key: string]: string}>({});

    const getManagingDirectorsNames = (managingDirectors: Person[] | undefined) => {
        let names = [] as string[];
        managingDirectors && managingDirectors.forEach(item => {
            if (item.name) {
                names.push(item.name);
            }
        })

        return names.join(', ');
    };

    useEffect(() => {
        if (!selectOptions) {
            return;
        }

        if (Object.keys(selectOptions).length === 0) {
            return;
        }

        setRiskClasses(selectOptions.project.riskClasses);
    }, [selectOptions]);

    useEffect(() => {
        if (props.project) {
            setData(props.project);
        }
    }, [props.project]);

    useEffect(() => {
        const generalColumns = [] as CardData[];
        const costsColumns = [] as CardData[];
        generalColumns.push(
            {
                label: 'Projektname',
                value: data.name,
                name: 'name',
                editable: true,
            },
            {
                label: 'Investment Fokus',
                value: data.investmentFocus,
                name: 'investmentFocus',
                editable: false,
                hint: 'Dieses Feld wird von den TAMs in Salesforce bearbeitet.',
            },
            {
                label: 'Risikoklasse',
                value: data.riskClass,
                name: 'riskClass',
                formatter: Formatter.formatRiskClass,
                type: RenderTypes.select,
                selectOptions: Formatter.formatSelectOptionsFromObject(riskClasses),
                id: data.riskClass,
                editable: true,
            },
            {
                label: 'Emittentin',
                value: data.emittent ? data.emittent.name : null,
                id: data.emittent ? data.emittent : null,
                name: 'emittentId',
                type: RenderTypes.accountSelect,
                noneValue: '-- keine Angabe --',
                formatter: () => Formatter.formatAccountLink(data.emittent),
                editFormatter: Formatter.formatAccountSelectOption,
                editable: true,
            },
            {
                label: 'Geschäftsführer der Emittentin',
                value: data.emittent ? getManagingDirectorsNames(data.emittent.managingDirectors) : null,
                name: 'emittentManagingDirectors',
                editable: false,
                hint: 'Die Geschäftsführer kannst auf der Account-Detailseite bearbeiten. Dafür auf den Namen des Unternehmens klicken.',
            },
            {
                label: 'Link zur Emittentin',
                value: data.emittent ? data.emittent.url : null,
                name: 'url',
                editable: false,
                hint: 'Der Link kann auf der Detailseite er Emittentin bearbeitet werden.'
            },
            {
                label: 'Vermittler Besitzgesellschaft',
                value: data.financialServicesInstitute ? data.financialServicesInstitute.name : null,
                id: data.financialServicesInstitute ? data.financialServicesInstitute : null,
                name: 'financialServicesInstituteId',
                type: RenderTypes.accountSelect,
                noneValue: '-- keine Angabe --',
                formatter: () => Formatter.formatAccountLink(data.financialServicesInstitute),
                editFormatter: Formatter.formatAccountSelectOption,
                editable: true,
            },
            {
                label: 'Geschäftsführer Vermittler Besitzgesellschaft',
                value: data.financialServicesInstitute ? getManagingDirectorsNames(data.financialServicesInstitute.managingDirectors) : null,
                name: 'financialServicesInstituteManagingDirectors',
                editable: false,
                hint: 'Die Geschäftsführer kannst auf der Account-Detailseite bearbeiten. Dafür auf den Namen des Unternehmens klicken.',
            },
        );
        costsColumns.push(
            {
                label: 'Notar',
                value: data.notaryCosts,
                name: 'notaryCosts',
                editable: true,
                type: RenderTypes.integerCurrency,
                formatter: Formatter.formatCurrency,
            },
            {
                label: 'Makler',
                value: data.brokerageFee || '',
                name: 'brokerageFee',
                editable: true,
                type: RenderTypes.integerCurrency,
                formatter: Formatter.formatCurrency,
            },
            {
                label: 'Liquiditätsrücklage',
                value: data.cashReserve,
                name: 'cashReserve',
                editable: true,
                formatter: Formatter.formatCurrency,
                type: RenderTypes.integerCurrency,
            },
        )
        setGeneralcardData(generalColumns);
        setCostsData(costsColumns);
    }, [data, riskClasses]);

    const handleSave = (updatedData: Project) => {
        setData({
            ...data,
            ...updatedData,
        });

        ProjectService.updateFromTable(data.id, {
            ...updatedData
        }).then(() => {

        }).catch(() => { });
    };

    return (
        <>
            {generalCardData && (
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <EditableCard
                            title={`Allgemeine Informationen`}
                            tableData={generalCardData}
                            onSave={handleSave}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <EditableCard
                            title={`Kosten`}
                            tableData={costsData}
                            onSave={handleSave}
                        />
                    </Grid>
                </Grid>
            )}
        </>
    );
}
