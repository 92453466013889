import API from '../api/API';
import Tenant from '../interfaces/models/Tenant';

export default {
    all: (): Promise<Tenant[]> => {
        return API.tenant.get();
    },
    create: (data: Tenant): Promise<Tenant> => {
        return API.tenant.post({
            data: data,
        });
    },
    createMultiple: (data: any): Promise<string> => {
        return API.tenant.post({
            url:  `/multiple/`,
            data: data,
        });
    },
    get: (id: number): Promise<Tenant> => {
        return API.tenant.get(id);
    },
    delete: (id: number): Promise<string> => {
        return API.tenant.delete(id);
    },
    import: (projectId: number, data: object): Promise<any> => {
        return API.tenant.post({
            url:  `/import/${projectId}/`,
            data: data,
            fileUpload: true,
        });
    },
    update: (id: number, data: Tenant): Promise<Tenant> => {
        return API.tenant.patch({
            url: id,
            data: data,
        });
    },
}
