import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Formatter from '../../../helpers/Formatter';
import EditableSmallTable from '../../UI/Tables/EditableSmallTable';
import EditableSmallTextCard from '../../UI/Cards/EditableSmallTextCard';
import SingleUpload from '../../UI/Upload/SingleUpload';
import RealEstatePropertyService from '../../../services/RealEstatePropertyService';
import ButtonEditOkCancel from '../../UI/Partials/ButtonEditOkCancel';
import RentalSituationDetailsProps from '../../../interfaces/props/RentalSituationDetailsProps';
import RealEstateProperty from '../../../interfaces/models/RealEstateProperty';

export default function RentalSituationDetails(props: RentalSituationDetailsProps) {
    const [data, setData] = useState<RealEstateProperty>({} as RealEstateProperty);
    const [updatedData, setUpdatedData] = useState<RealEstateProperty>({} as RealEstateProperty);
    const [editMode, setEditMode] = useState(false);
    const {realEstateProperty} = props;

    useEffect(() => {
        setData(realEstateProperty ?? {} as RealEstateProperty);
    }, [realEstateProperty]);

    const handleChange = (event: any) => {
        const currentData = data;
        // @ts-ignore
        currentData[event.target.name] = event.target.value;
        setData(currentData);

        const currentUpdatedData = updatedData;
        // @ts-ignore
        currentUpdatedData[event.target.name] = event.target.value;
        setUpdatedData(currentUpdatedData);
    };

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            handleSave();
        }

        if (event.key === 'Escape') {
            setEditMode(false);
        }
    }

    const handleSave = () => {
        setEditMode(false);
        props.onSave!(updatedData);
    }

    const propertyAreaRows = [
        {
            label: 'Vermietungsstand (%)',
            value: data.occupancyRate || '',
            name: 'occupancyRate',
            editable: true,
            type: 'number',
            formatter: Formatter.formatPercentage,
        },
        {
            label: 'Wohnmiete pro m²',
            value: data.residentialRentSquareMeter || '',
            name: 'residentialRentSquareMeter',
            editable: true,
            formatter: Formatter.formatCurrency,
        },
        {
            label: 'Gewerbemiete pro m²',
            value: data.commercialRentSquareMeter || '',
            name: 'commercialRentSquareMeter',
            editable: true,
            formatter: Formatter.formatCurrency,
        },
        {
            label: 'Büromiete pro m²',
            value: data.officeRentSquareMeter || '',
            name: 'officeRentSquareMeter',
            editable: true,
            formatter: Formatter.formatCurrency,
        },
        {
            label: 'Miete Sonstiges pro m²',
            value: data.otherRentSquareMeter || '',
            name: 'otherRentSquareMeter',
            editable: true,
            formatter: Formatter.formatCurrency,
        },
        {
            label: 'Leerstand-Sollmiete pro m²',
            value: data.emptyUnitRentSquareMeter || '',
            name: 'emptyUnitRentSquareMeter',
            editable: true,
            formatter: Formatter.formatCurrency,
        },
        {
            label: 'Jahresnettomieteinnahmen 1. Jahr',
            value: data.basicRentFirstYear || '',
            name: 'basicRentFirstYear',
            editable: true,
            type: 'integerCurrency',
            formatter: Formatter.formatCurrency,
        },
        {
            label: 'Jahresnettomieteinnahmen 10-Jahres-Schnitt',
            value: data.basicRentTenYearsAverage || '',
            name: 'basicRentTenYearsAverage',
            editable: true,
            formatter: Formatter.formatCurrency,
        },
        {
            label: 'Hauptmieter',
            value: data.mainTenant ? data.mainTenant.name : null,
            id: data.mainTenant ? data.mainTenant : null,
            name: 'mainTenantId',
            type: 'accountSelect',
            noneValue: '-- keine Angabe --',
            editable: true,
            editFormatter: Formatter.formatAccountSelectOption,
        },
        {
            label: 'Vermietete Tage der Immobilie',
            value: data.rentedDaysProperty || '',
            name: 'rentedDaysProperty',
            editable: true,
            type: 'number',
        },
        {
            label: 'Durchschnittspreis/Nacht',
            value: data.averagePricePerNight || '',
            name: 'averagePricePerNight',
            editable: true,
            formatter: Formatter.formatCurrency,
        },
        {
            label: 'Umsatz aus Vermietungen/Nacht',
            value: data.turnoverFromRentals || '',
            name: 'turnoverFromRentals',
            editable: true,
            formatter: Formatter.formatCurrency,
        },
    ];

    return (
        <>
            <Grid
                justify={'flex-start'}
                container
                spacing={2}
                style={{margin: '15px 0 20px 0'}}
            >
                <Grid item>
                    <Typography variant={'h4'} component={'h2'}>
                        Mietsituation
                    </Typography>
                </Grid>
                <Grid item style={{display: 'flex'}}>
                    <div style={{display: 'flex'}}>
                        <ButtonEditOkCancel
                            onEdit={() => setEditMode(true)}
                            onCancel={() => setEditMode(false)}
                            onSave={handleSave}
                            editMode={editMode}>
                        </ButtonEditOkCancel>
                    </div>
                </Grid>
            </Grid>
            <Grid container spacing={3} alignItems='stretch'>
                <Grid item sm={4}>
                    <EditableSmallTable
                        rows={propertyAreaRows}
                        editMode={editMode}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                    />
                    <br/>
                    <EditableSmallTextCard
                        data={data}
                        onChange={handleChange}
                        onSave={() => props.onSave!(updatedData)}
                        title={'Die Mietsituation'}
                        name={'textRentalSituation'}
                    />
                </Grid>
                <Grid item sm={4}>
                    <EditableSmallTextCard
                        data={data}
                        onChange={handleChange}
                        onSave={() => props.onSave!(updatedData)}
                        title={'Mietverteilung'}
                        name={'textRentDistribution'}
                    />
                    <SingleUpload
                        title='Grafik Mieterverteilung'
                        data={realEstateProperty}
                        column='graphicRentDistributionUrl'
                        urlSuffix='uploadGraphicRentDistributionUrl'
                        service={RealEstatePropertyService}
                        onSave={(updatedData: RealEstateProperty) => props.onSave!(updatedData)}
                    />
                    <EditableSmallTextCard
                        data={data}
                        onChange={handleChange}
                        onSave={() => props.onSave!(updatedData)}
                        title={'Erklärung Grafik Mieterverteilung'}
                        name={'graphicRentDistributionExplanation'}
                    />
                </Grid>
                <Grid item sm={4}>
                    <EditableSmallTextCard
                        data={data}
                        onChange={handleChange}
                        onSave={() => props.onSave!(updatedData)}
                        title={'Markt'}
                        name={'textMarket'}
                    />
                    <SingleUpload
                        title='Grafik Markt'
                        data={realEstateProperty}
                        column='graphicMarketUrl'
                        urlSuffix='uploadGraphicMarketUrl'
                        service={RealEstatePropertyService}
                        onSave={(updatedData: RealEstateProperty) => props.onSave!(updatedData)}
                    />
                    <EditableSmallTextCard
                        data={data}
                        onChange={handleChange}
                        onSave={() => props.onSave!(updatedData)}
                        title={'Erklärung Grafik Markt'}
                        name={'graphicMarketExplanation'}
                    />
                </Grid>
            </Grid>
        </>
    );
}
