const itemName: string = 'sidebar-openStatus';

export default {
    openStatus: (): boolean => {
        return localStorage.hasOwnProperty(itemName) ? (localStorage.getItem(itemName) === 'true') : true;
    },
    setOpenStatus: (value: string): void => {
        localStorage.setItem(itemName, value);
    },
}
