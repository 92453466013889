import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import HintProps from '../../../interfaces/props/HintProps';

const useStyles = makeStyles(theme => ({
    tooltip: {
        fontSize: 18,
    },
    button: {
        padding: 0,
        height: 13,
    },
    icon: {
        height: 15,
        color: theme.palette.primary.main,
    }
}));

export default function Hint(props: HintProps) {
    const classes = useStyles();

    const tooltipTitle = (props: HintProps): JSX.Element => {
        return (<div dangerouslySetInnerHTML={{__html: props.message}} />);
    };

    return (props.message
        ?
            <>
                <Tooltip title={tooltipTitle(props)} className={classes.tooltip}>
                    <IconButton className={classes.button}>
                        <InfoOutlinedIcon className={classes.icon}/>
                    </IconButton>
                </Tooltip>
            </>
        : null
    );
}
