import React, {useEffect} from 'react';
import {useRouteMatch} from 'react-router-dom';

export default function Documents(props) {
    const match = useRouteMatch();
    const {setMenuItems} = props;

    useEffect(() => {
        setMenuItems([
            {
                title: 'Zurück zum Projekt',
                to: `/project/${match.params.id}`,
            },
            /*
            {
                title: 'Dokumente',
                to: `/project/${match.params.id}/documents`,
            },
            {
                title: 'Prüfung',
                to: `/project/${match.params.id}/assessment`,
            },
            */
        ]);
    }, [match.params.id, setMenuItems]);

    return (
        <>
            <h1>Dokumente für Projekt {match.params.id}</h1>
            <p>Hier könnte eine Auflistung aller Dokumente sein. Mit Upload-Funktion usw.</p>
        </>
    );
}
