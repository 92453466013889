import API from '../api/API';
import Permission from '../interfaces/models/Permission';

export default {
    all: (): Promise<Permission[]> => {
        return API.permission.get();
    },
    create: (data: object): Promise<Permission> => {
        return API.permission.post({
            data: data,
        });
    },
    get: (id: number): Promise<Permission> => {
        return API.permission.get(id);
    },
    delete: (id: number): Promise<string> => {
        return API.permission.delete(id);
    },
    updateFromTable: async (id: number, data: Permission): Promise<Permission> => {
        return API.permission.patch({
            url: id,
            data: data,
        });
    },
}
