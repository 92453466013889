import API from '../api/API';
import LandRegister from '../interfaces/models/LandRegister';

export default {
    get: (id: number): Promise<LandRegister> => {
        return API.landRegister.get(id);
    },
    create: (data: object): Promise<LandRegister> => {
        return API.landRegister.post({
            data: data,
        });
    },
    delete: (id: number): Promise<string> => {
        return API.landRegister.delete(id);
    },
    updateFromTable: async (id: number, data: LandRegister): Promise<LandRegister> => {
        return API.landRegister.patch({
            url: id,
            data: data,
        });
    },
}
