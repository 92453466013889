import React, {useState} from 'react';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import ImportTenantsButtonProps from '../../../interfaces/props/ImportTenantsButtonProps';
import FileDropzoneArea from '../Upload/FileDropzoneArea';
import {Typography} from '@material-ui/core';
import EventSystem from '../../../EventSystem';
import TenantService from '../../../services/TenantService';
import {Redirect} from 'react-router-dom';
import TenantsImportHelper from '../../../helpers/TenantsImportHelper';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles(theme => ({
    button: {
        background: theme.palette.success.main,
        color: 'white'
    },
    importButton: {
        background: theme.palette.success.main,
        color: 'white',
        marginRight: 16,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    // @ts-ignore
    return <Slide direction='up' ref={ref} {...props} />;
});

export default function ImportTenantsButton(props: ImportTenantsButtonProps) {
    const classes = useStyles();
    const [modalOpen, setModalOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [file, setFile] = React.useState<File | null>(null);
    const [realEstatePropertyId, setRealEstatePropertyId] = useState(0);
    const [redirectToImportPage, setRedirectToImportPage] = useState(false);
    const {project} = props;

    const handleImport = (): void  => {
        if (!file) {
            EventSystem.publish(
                'notification',
                'Bitte wähle eine Datei aus!',
                {
                    variant: 'error',
                }
            );
            return;
        }

        setLoading(true);

        const formData = new FormData();
        formData.append('file', file, file.name);

        TenantService.import(project.id, formData).then(result => {
            setLoading(false);

            if (!result.success) {
                EventSystem.publish(
                    'notification',
                    result.text,
                    {
                        variant: 'error',
                    }
                );
                return;
            }

            TenantsImportHelper.setTenantsImportData(result.tenants);
            setRealEstatePropertyId(result.realEstatePropertyId);
            setRedirectToImportPage(true);

            EventSystem.publish(
                'notification',
                'Die Excel wurde erfolgreich importiert!',
                {
                    variant: 'success',
                }
            );
        }).catch(() => {
            setLoading(false);

            EventSystem.publish(
                'notification',
                'Es gab einen Fehler beim Importieren der Excel.',
                {
                    variant: 'error',
                }
            );
        });
    }

    const handleFile = (file: File): void => {
        setFile(file);
    };

    const handleClickModalOpen = (): void => {
        setModalOpen(true);
    };

    const handleModalClose = (): void  => {
        setModalOpen(false);
    };

    if (redirectToImportPage) {
        return <Redirect to={`/real-estate-property/${realEstatePropertyId}/import/preview`} push={true} />
    }

    return (
        <>
            <Button
                variant='contained'
                className={classes.importButton}
                startIcon={<CloudUploadIcon/>}
                onClick={handleClickModalOpen}
            >
                Mieter importieren
            </Button>
            <Dialog
                open={modalOpen}
                // @ts-ignore
                TransitionComponent={Transition}
                keepMounted
                onClose={handleModalClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>{'Mieter importieren'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        <Typography>
                            Bitte stelle sicher, dass die Excel eine WE-Nummer aufweist.<br/>
                            Bitte wähle für den Upload eine Excel Datei aus.
                        </Typography>
                        <FileDropzoneArea
                            onFile={handleFile}
                            />
                    </DialogContentText>
                    {loading ? <LinearProgress/> : null}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleModalClose}
                        color='primary'
                        disabled={loading}
                        autoFocus>
                        Abbrechen
                    </Button>
                    <Button
                        variant='contained'
                        onClick={handleImport}
                        className={classes.button}
                        startIcon={<CloudUploadIcon/>}
                        disabled={loading}
                    >
                        Importieren
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
