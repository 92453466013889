import React, {useEffect, useState} from 'react';
import {Redirect, useRouteMatch} from 'react-router-dom';
import Loader from '../../UI/Loader';
import RealEstatePropertyService from '../../../services/RealEstatePropertyService';
import AddressDataCard from './AddressDataCard';
import Grid from '@material-ui/core/Grid';
import PropertyDetails from './PropertyDetails';
import MetaDataCard from './MetaDataCard';
import DescriptionDetails from './DescriptionDetails';
import RentalSituationDetails from './RentalSituationDetails';
import Divider from '@material-ui/core/Divider';
import {makeStyles} from '@material-ui/core/styles';
import DeleteEntry from '../../UI/Partials/DeleteEntry';
import Helper from '../../../helpers/Helper';
import LandRegisterTable from './LandRegisterTable';
import TenantsTable from './TenantsTable';
import RealEstatePropertyDetailPageProps from '../../../interfaces/props/RealEstatePropertyDetailPageProps';
import RealEstateProperty from '../../../interfaces/models/RealEstateProperty';
import {IdMatchParam} from '../../../interfaces/matchs/IdMatchParam';

const useStyles = makeStyles({
    divider: {
        marginTop: 16,
        marginBottom: 16,
    },
});

export default function RealEstatePropertyDetailPage(props: RealEstatePropertyDetailPageProps) {
    const classes = useStyles();
    const {setMenuItems, setBreadcrumbItems} = props;
    const [data, setData] = useState<RealEstateProperty>({} as RealEstateProperty);
    const [isLoading, setIsLoading] = useState(false);
    const [navigateToProject, setNavigateToProject] = useState(false);
    const match = useRouteMatch<IdMatchParam>();

    useEffect(() => {
        setMenuItems!([]);
    }, [setMenuItems]);

    useEffect(() => {
        setIsLoading(true);

        RealEstatePropertyService.get(parseInt(match.params.id)).then((property: RealEstateProperty) => {
            setData(property);
            setIsLoading(false);

            setBreadcrumbItems!([
                {
                    type: 'link',
                    link: '/projects/',
                    name: 'Projekte',
                },
                {
                    type: 'link',
                    link: '/project/' + property.projectId + '/',
                    name: 'Projekt #' + property.projectId,
                },
                {
                    type: 'typography',
                    name: property.fullAddress ?? '',
                },
            ]);
        }).catch(() => {
            setIsLoading(false);
        });

        data.name
            ? Helper.setPageTitle('Objekt - ' + data.name)
            // @ts-ignore
            : Helper.setPageTitle('Objekt #' + match.params.id);

        // @ts-ignore
    }, [match.params.id, setBreadcrumbItems, data.name]);

    const handleSave = (updatedData: RealEstateProperty) => {
        setData({
            ...data,
            ...updatedData,
        });

        RealEstatePropertyService.update(data.id,{
            ...updatedData
        }).then((result: RealEstateProperty) => {
            setData(result);
        }).catch(() => { });
    };

    const handleDelete = () => {
        // @ts-ignore
        RealEstatePropertyService.delete(match.params.id).then(() => {
            setNavigateToProject(true);
        });
    }

    if (isLoading) {
        return <Loader open={isLoading}/>
    }

    if (navigateToProject) {
        return <Redirect to={`/project/${data.projectId}`} push={true} />
    }

    return (
        <>
            <DeleteEntry
                onDelete={handleDelete}
                title={'Objekt löschen?'}
                text={'Bist du dir sicher, dass du dieses Objekt und alle dazugehörigen Daten löschen möchtest?'}
            />
            <Divider className={classes.divider}/>
            <Grid container spacing={3} alignItems='stretch'>
                <Grid item xs={6}>
                    <AddressDataCard
                        realEstateProperty={data}
                        onSave={(updatedData: RealEstateProperty) => handleSave(updatedData)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <MetaDataCard
                        realEstateProperty={data}
                        onSave={(updatedData: RealEstateProperty) => handleSave(updatedData)}
                    />
                </Grid>
            </Grid>
            <Divider className={classes.divider}/>
            <RentalSituationDetails realEstateProperty={data} onSave={(updatedData: RealEstateProperty) => handleSave(updatedData)}/>
            <Divider className={classes.divider}/>
            <PropertyDetails realEstateProperty={data} onSave={(updatedData: RealEstateProperty) => handleSave(updatedData)}/>
            <Divider className={classes.divider}/>
            <DescriptionDetails realEstateProperty={data} onSave={(updatedData: RealEstateProperty) => handleSave(updatedData)}/>
            <Divider className={classes.divider}/>
            <LandRegisterTable landRegisters={data.landRegisters}/>
            <Divider className={classes.divider}/>
            <TenantsTable projectId={data.projectId} tenants={data.tenants}/>
        </>
    );
}
