import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {useRouteMatch} from 'react-router-dom';
import ProjectService from '../../../services/ProjectService';
import Grid from '@material-ui/core/Grid';
import PlannedMeasures from '../PlannedMeasures';
import Loader from '../../UI/Loader';
import UserService from '../../../services/UserService';
import ProjectCard from './ProjectCard';
import RealEstatePropertiesTable from './RealEstatePropertiesTable';
import CustomSelect from '../../UI/Forms/CustomSelect';
import ProjectYieldCard from './ProjectYieldCard';
import ProjectTextarea from './ProjectTextarea';
import ProjectFinancialKeyFacts from './ProjectFinancialKeyFacts';
import FutureDetails from './FutureDetails';
import ValueIncreaseDetails from './ValueIncreaseDetails';
import SendButton from '../../UI/Partials/SendButton';
import EventSystem from '../../../EventSystem';
import ProjectCosts from './ProjectCosts';
import TopTenantsTable from './TopTenantsTable';
import ProjectCompanyStructureCard from './ProjectCompanyStructureCard';
import Hint from '../../UI/Partials/Hint';
import Button from '@material-ui/core/Button';
import SyncIcon from '@material-ui/icons/Sync';
import SingleValueStorageHelper from '../../../helpers/SingleValueStorageHelper';
import Helper from '../../../helpers/Helper';
import LatestEvents from './LatestEvents';
import ImportTenantsButton from '../../UI/Partials/ImportTenantsButton';
import Paper from '@material-ui/core/Paper';
import LaunchIcon from '@material-ui/icons/Launch';

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: '100%',
        flexGrow: 1,
        paddingLeft: 10,
        paddingRight: 10,
    },
    financingStructure: {
        marginTop: 16,
    },
    title: {
        color: theme.palette.grey.A100,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        marginTop: 15,
        fontWeight: 'bold',
    },
    importTenantsButton: {
        marginRight: 16,
    },
    button: {
        background: theme.palette.success.main,
        color: 'white'
    },
    paper: {
        padding: '10px',
    },
}));

const typesForFinancingStructure = [
    'bondCalculation',
    'financingStructure',
];

export default function ProjectDetailPage(props) {
    const classes = useStyles();
    const match = useRouteMatch();

    const [project, setProject] = useState({});
    const [users, setUsers] = useState([]);
    const [transactionUsers, setTransactionUsers] = useState([]);
    const [assetUsers, setAssetUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [marketingSendMode, setMarketingSendMode] = useState(true);
    const [editingType, setEditingType] = useState(SingleValueStorageHelper.get('project-editingType'));
    const {setMenuItems, setBreadcrumbItems} = props;

    const handleSave = (updatedData) => {
        setProject({
            ...project,
            ...updatedData,
        });

        ProjectService.updateFromTable(project.id, {
            ...updatedData
        }).then(result => {
            console.log('RESULT', result);
            setProject(result);
        }).catch(() => { });
    };

    const handleMarketingSend = () => {
        setMarketingSendMode(false);
        ProjectService.exportMarketing(project.id).then(()=>{
            setMarketingSendMode(true);
            EventSystem.publish(
                'notification',
                'Daten wurden erfolgreich zum Marketing versendet!',
                {
                    variant: 'success',
                }
            );
        }).catch(() => {
            setMarketingSendMode(true);
        });
    };

    useEffect(() => {
        setIsLoading(true);

        ProjectService.getForDetailPage(match.params.id).then(project => {
            setProject(project);
            setIsLoading(false);

            if (project.exporoCapital.financialProductId) {
                setMenuItems([
                    {
                        title: 'Reportings',
                        to: `/reporting/${project.exporoCapital.financialProductId}`,
                    },
                ]);
            }

            setBreadcrumbItems([
                {
                    type: 'link',
                    link: '/projects/',
                    name: 'Projekte',
                },
                {
                    type: 'typography',
                    name: 'Projekt #' + project.id,
                },
            ]);
        }).catch(() => {
            setIsLoading(false);
        });

        UserService.allShort().then(users => {
            setUsers(users);

            let transactionManagers = [];
            let assetManagers = [];

            users.forEach(user => {
                if (user.roles.includes('transactionManager')) {
                    transactionManagers.push(user);
                }

                if (user.roles.includes('assetManager')) {
                    assetManagers.push(user);
                }
            });

            setTransactionUsers(transactionManagers);
            setAssetUsers(assetManagers);
        }).catch(() => { });

        project.name
            ? Helper.setPageTitle('Projekt - ' + project.name)
            : Helper.setPageTitle('Projekt #' + match.params.id);

    }, [match.params.id, setMenuItems, setBreadcrumbItems, project.name]);

    const handleEditingTypeChange = (event) => {
        setEditingType(event.target.value);
        SingleValueStorageHelper.set('project-editingType', event.target.value);
    };

    if (isLoading) {
        return <Loader open={isLoading}/>
    }

    return (
        <Grid className={classes.root} container spacing={0} alignItems='stretch'>
            <Grid item sm={9}>
                <Typography variant='h4' className={classes.title}>{project.name}</Typography>
            </Grid>
            <Grid item sm={3}>
                <label htmlFor='select-editing-type'><strong>Was möchtest du machen?</strong></label>
                <CustomSelect
                    labelId={'select-editing-type'}
                    defaultValue={editingType}
                    onChange={handleEditingTypeChange}
                    size={'small'}
                    name={'editingType'}
                    options={[
                        { value: '', label: 'Alles'},
                        { value: 'financingStructure', label: 'Finanzierungsstruktur bearbeiten' },
                        { value: 'costs', label: 'Kosten' },
                        { value: 'objects', label: 'Objekte bearbeiten' },
                        { value: 'tenants', label: 'Top Mieter bearbeiten' },
                        { value: 'description', label: 'Projektbeschreibung bearbeiten' },
                        { value: 'financialKeyFacts', label: 'Finanzkennzahlen bearbeiten' },
                        { value: 'futureFields', label: 'Prognostizierte Jahresrechnung bearbeiten' },
                        { value: 'valueIncreaseFields', label: 'Wertentwicklung der Immobilie bearbeiten' },
                    ]}
                />
            </Grid>

            <Grid
                container
                direction='row'
                justify='flex-end'
                alignItems='flex-start'
            >
                <ImportTenantsButton
                    project={project}
                />
                {(project.sendMarketingAllowed)
                    ?
                        <SendButton
                            buttonText={"Marketing Export"}
                            title={"Daten zum Marketing exportieren"}
                            text={"Die Daten dieses Projektes werden umgehend zum Marketing exportiert. Möchtest Du die Daten jetzt exportieren?"}
                            onSend={handleMarketingSend}
                            sendMode={marketingSendMode}
                        />
                    :
                        <>
                            <Button variant='contained' startIcon={<SyncIcon/>} disabled={true}>
                                Daten zum Marketing exportieren
                            </Button>
                            <Hint
                                message="Ein Financial Product muss bereits erstellt worden sein und jedes Objekt muss über eine gültige Adresse (Stadt, PLZ, Straße) verfügen."
                            />
                        </>
                }

            </Grid>

            {project.stage === 'purchased' && (
                <Grid item xs={12} className={classes.financingStructure}>
                    <Paper elevation={3} className={classes.paper}>
                        <Typography>
                            <h3>Herzlichen Glückwunsch zum Kauf!</h3>
                        </Typography>
                        {!project.isFinalized && (
                            <>
                                <p>Es fehlen noch ein paar Daten, um das Projekt auf die Plattform zu bringen.</p>
                                <p>
                                    <Button variant='contained' startIcon={<LaunchIcon/>} className={classes.button} href={`#/project/${match.params.id}/finalize`}>
                                    Finalize Origination now
                                    </Button>
                                </p>
                            </>
                        )}
                        {project.isFinalized && (
                            <>
                                <p>Alle Daten sind komplett und das Projekt ist bereit für das Funding.</p>
                            </>
                        )}
                    </Paper>
                </Grid>
            )}

            {(!editingType || editingType === 'objects') && (
                <Grid item xs={12} className={classes.financingStructure}>
                    <RealEstatePropertiesTable realEstateProperties={project.realEstateProperties}/>
                </Grid>
            )}

            {(!editingType || editingType === 'tenants') && (
                <Grid item xs={12} className={classes.financingStructure}>
                    <TopTenantsTable tenants={project.topTenants}/>
                </Grid>
            )}

            {Object.keys(project).length > 0 && (
                <>
                    {!editingType && (
                        <Grid container spacing={3} alignItems='stretch'>
                            <Grid item sm={6} xs={12}>
                                <ProjectCard project={project} assetUsers={assetUsers} transactionUsers={transactionUsers} onSave={handleSave}/>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <ProjectCompanyStructureCard project={project} users={users} onSave={handleSave}/>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <ProjectYieldCard project={project} onSave={handleSave}/>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <LatestEvents projectId={project.id} events={project.events}/>
                            </Grid>
                        </Grid>
                    )}
                </>
            )}
            {(!editingType || editingType === 'costs') && (
                <ProjectCosts project={project} onSave={handleSave}/>
            )}
            {(!editingType || typesForFinancingStructure.includes(editingType)) && (
                <Grid item xs={12} className={classes.financingStructure} margin={3}>
                    <Typography gutterBottom variant='h5' component='h2'>
                        Finanzierungsstruktur
                    </Typography>
                    <PlannedMeasures plannedMeasures={project.plannedMeasures}/>
                </Grid>
            )}
            {(!editingType || editingType === 'description') && (
                <Grid container spacing={3} alignItems='stretch'>
                    <Grid item sm={12}>
                        <ProjectTextarea
                            project={project}
                            title={'Projektbeschreibung'}
                            name={'description'}
                            onSave={handleSave}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <ProjectTextarea
                            project={project}
                            title={'Highlights'}
                            name={'highlights'}
                            onSave={handleSave}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <ProjectTextarea
                            project={project}
                            title={'Szenariorechner'}
                            name={'textScenarioCalculator'}
                            onSave={handleSave}
                        />
                    </Grid>
                </Grid>
            )}

            {(!editingType || editingType === 'financialKeyFacts') && (
                <Grid container spacing={3} alignItems='stretch'>
                    <Grid item sm={12} className={classes.financingStructure}>
                        <Typography variant='h5' component='h2' style={{marginBottom: '-30px'}}>
                            Finanzkennzahlen
                        </Typography>
                    </Grid>
                    <Grid item sm={6}>
                        <ProjectFinancialKeyFacts project={project} onSave={handleSave}/>
                    </Grid>
                    <Grid item sm={6}>
                        <ProjectTextarea
                            project={project}
                            title={'Beschreibung Finanzkennzahlen'}
                            name={'financialKeyFacts'}
                            onSave={handleSave}
                        />
                    </Grid>
                </Grid>
            )}

            {(!editingType || editingType === 'futureFields') && (
                <Grid item sm={12}>
                    <FutureDetails project={project} onSave={handleSave}/>
                </Grid>
            )}

            {(!editingType || editingType === 'valueIncreaseFields') && (
                <Grid item sm={12}>
                    <ValueIncreaseDetails project={project} onSave={handleSave}/>
                </Grid>
            )}

        </Grid>
    );
}
