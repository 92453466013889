export default {
    local: {
        API_BASE_URL: 'https://immo.localhost/api',
        API_LOGIN_URL: 'https://immo.localhost/login',
        ONE_LOGIN_REDIRECT_URI: 'https://immo.localhost/auth',
        ONE_LOGIN_CLIENT_ID: '3dd889f0-4066-0138-b8e0-02aab85ebc2237836',
        REPORTING_BASE_URI: 'https://api.stage-reports.exporo.com',
    },
    stage: {
        API_BASE_URL: 'https://stage.api.yield-re.exporo.de/api',
        API_LOGIN_URL: 'https://stage.api.yield-re.exporo.de/login',
        ONE_LOGIN_REDIRECT_URI: 'https://stage.api.yield-re.exporo.de/auth',
        ONE_LOGIN_CLIENT_ID: 'b1b09040-87d6-0138-79f0-0231ba1a28ba37836',
        REPORTING_BASE_URI: 'https://api.stage-reports.exporo.com',
    },
    production: {
        API_BASE_URL: 'https://api.yield-re.exporo.de/api',
        API_LOGIN_URL: 'https://api.yield-re.exporo.de/login',
        ONE_LOGIN_REDIRECT_URI: 'https://api.yield-re.exporo.de/auth',
        ONE_LOGIN_CLIENT_ID: '8108ab00-88b7-0138-5207-068de21dbd2237836',
        REPORTING_BASE_URI: 'https://chbaxd3cp0.execute-api.eu-central-1.amazonaws.com/prod/',
    },
    newProduction: {
        API_BASE_URL: 'https://api.yield-re.exporo.de/api',
        API_LOGIN_URL: 'https://api.yield-re.exporo.de/login',
        ONE_LOGIN_REDIRECT_URI: 'https://api.yield-re.exporo.de/auth',
        ONE_LOGIN_CLIENT_ID: '8108ab00-88b7-0138-5207-068de21dbd2237836',
        REPORTING_BASE_URI: 'https://chbaxd3cp0.execute-api.eu-central-1.amazonaws.com/prod/',
    }
}
