import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import AddModal from '../UI/Dialogs/Account/AddModal';
import {useAppContext} from '../../libs/contextLib';
import UserHelper from '../../helpers/UserHelper';

const useStyles = makeStyles(theme => ({
    addButton: {
        background: theme.palette.primary['500'],
        color: 'white',
        marginBottom: 20,
    },
}));

export default function AddAccount(props) {
    const [open, setOpen] = React.useState(false);
    const [countries, setCountries] = useState({});
    const classes = useStyles();
    const {user} = useAppContext();

    useEffect(() => {
        setCountries(props.countries);
    }, [props.countries]);

    const handleSave = (account) => {
        setOpen(false);
        props.onSave(account);
    }

    const handleClose = () => {
        setOpen(false);
    }

    return UserHelper.hasPermission(user, 'edit') ? (
        <>
            <Grid
                container
                direction='row'
                justify='flex-end'
                alignItems='flex-start'
            >
                <Button
                    variant='contained'
                    className={classes.addButton}
                    startIcon={<AddIcon/>}
                    onClick={() => setOpen(true)}
                >
                    Account hinzufügen
                </Button>
            </Grid>
            <AddModal
                open={open}
                onClose={handleClose}
                onSave={handleSave}
                countries={countries}
            />
        </>
    ) : null;
}
