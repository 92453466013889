import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import SaveIcon from '@material-ui/icons/Save';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {TextField} from '@material-ui/core';
import AccountTypes from '../../../../enums/AccountTypes';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        padding: 0,
    },
    rootDialog : {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        padding: 24,
    },
    grow: {
        flexGrow: 1,
    },
    appBar: {
        position: 'relative',
        color: 'white',
        backgroundColor: theme.palette.customBackground.lightGrey,
    },
    appBarTable: {
        color: theme.palette.grey.A100,
        backgroundColor: theme.palette.customBackground.lightGrey,
    },
    tab: {
        color: theme.palette.grey.A100,
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
        color: theme.palette.grey.A100,
    },
    button: {
        margin: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
        paddingRight: 5,
    },
    deleteButton: {
        background: theme.palette.delete,
        color: 'white'
    },
    indicator: {
        backgroundColor: theme.palette.background.green,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

export default function AddModal(props) {
    const [account, setAccount] = useState(props.account || {
        'type': '',
        'companyName': '',
        'firstName': '',
    });
    const [open, setOpen] = React.useState(false);
    const [countries, setCountries] = useState({});
    const classes = useStyles();

    useEffect(() => {
        setCountries(props.countries);
    }, [props.countries]);

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    useEffect(() => {
        setAccount(props.account || {
            'type': '',
            'companyName': '',
            'firstName': '',
        });
    }, [props.account]);

    const handleSave = () => {
        props.onSave(account);
    }

    const handleAccountChange = (event) => {
        setAccount({
            ...account,
            [event.target.name]: event.target.value
        });
    };

    const handleClose = () => {
        setAccount({
            'type': '',
            'companyName': '',
            'firstName': '',
        });
        props.onClose();
    }

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        edge='start'
                        onClick={handleClose}
                        aria-label='close'
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant='h6' className={classes.title}>
                        Account hinzufügen
                    </Typography>
                    <Button
                        variant='contained'
                        color='secondary'
                        size='small'
                        className={classes.button}
                        startIcon={<SaveIcon />}
                        onClick={handleSave}
                    >
                        Speichern
                    </Button>
                </Toolbar>
            </AppBar>
            <div className={classes.rootDialog}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <FormControl className={classes.formControl}>
                            <InputLabel shrink>
                                Art des Accounts
                            </InputLabel>
                            <Select
                                defaultValue={account.type ? account.type : ''}
                                onChange={handleAccountChange}
                                displayEmpty
                                className={classes.selectEmpty}
                                required
                                name={'type'}
                            >
                                <MenuItem value=''>
                                    <em>Bitte wählen</em>
                                </MenuItem>
                                {Object.keys(AccountTypes.getTranslations()).map(type => {
                                    return (
                                        <MenuItem key={type} value={type}>{AccountTypes.getTranslations()[type]}</MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        {(() => {
                            if (account.type === 'company') {
                                return (
                                    <FormControl className={classes.formControl}>
                                        <TextField
                                            defaultValue={account.companyName ? account.companyName : ''}
                                            type={'text'}
                                            onBlur={handleAccountChange}
                                            name={'companyName'}
                                            label={'Name'}
                                        />
                                    </FormControl>
                                )
                            } else if (account.type === 'person') {
                                return (
                                    <Grid container spacing={3}>
                                        <Grid item sm={6}>
                                            <FormControl className={classes.formControl}>
                                                <TextField
                                                    defaultValue={account.firstName ? account.firstName : ''}
                                                    type={'text'}
                                                    onBlur={handleAccountChange}
                                                    name={'firstName'}
                                                    label={'Vorname'}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm={6}>
                                            <FormControl className={classes.formControl}>
                                                <TextField
                                                    type={'text'}
                                                    onBlur={handleAccountChange}
                                                    name={'lastName'}
                                                    label={'Nachname'}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                )
                            }
                        })()}
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item sm={3}>
                        <FormControl className={classes.formControl}>
                            <TextField
                                type={'text'}
                                onBlur={handleAccountChange}
                                name={'street'}
                                label={'Straße'}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sm={3}>
                        <FormControl className={classes.formControl}>
                            <TextField
                                type={'number'}
                                onBlur={handleAccountChange}
                                name={'streetNo'}
                                label={'Hausnummer'}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <FormControl className={classes.formControl}>
                            <TextField
                                type={'text'}
                                onBlur={handleAccountChange}
                                name={'city'}
                                label={'Stadt'}
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <InputLabel shrink>
                                Land
                            </InputLabel>
                            <Select
                                value={account.country ? account.country : ''}
                                onChange={handleAccountChange}
                                displayEmpty
                                className={classes.selectEmpty}
                                required
                                name={'country'}
                            >
                                <MenuItem value=''>
                                    <em>Bitte wählen</em>
                                </MenuItem>
                                {Object.keys(countries).map(type => {
                                    return (
                                        <MenuItem key={type} value={type}>{countries[type]}</MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </div>
        </Dialog>
    );
}
