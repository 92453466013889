import * as React from 'react';
import AccountTree from '@material-ui/icons/AccountTree';
import ThumbDownAlt from '@material-ui/icons/ThumbDownAlt';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import PeopleIcon from '@material-ui/icons/People';
import LockIcon from '@material-ui/icons/Lock';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import SettingsPowerIcon from '@material-ui/icons/SettingsPower';
import PersonIcon from '@material-ui/icons/Person';
import NavigationItem from '../interfaces/NavigationItem';

const navigation: Array<NavigationItem> = [
    {
        type: 'link',
        icon: <AccountTree/>,
        name: 'Projekte',
        link: '/projects',
    },
    {
        type: 'link',
        icon: <AccountTree/>,
        name: 'Reporting',
        link: '/reporting',
    },
    {
        type: 'link',
        icon: <ThumbDownAlt/>,
        name: 'Abgelehnte Projekte',
        link: '/projects/archived',
    },
    {
        type: 'link',
        icon: <RecentActorsIcon/>,
        name: 'Accounts',
        link: '/accounts',
    },
    {
        type: 'divider',
    },
    {
        type: 'link',
        icon: <PeopleIcon/>,
        name: 'Benutzerverwaltung',
        link: '/users',
        permission: 'admin',
    },
    {
        type: 'link',
        icon: <LockIcon/>,
        name: 'Rollen',
        link: '/roles',
        permission: 'admin',
    },
    {
        type: 'link',
        icon: <VpnKeyIcon/>,
        name: 'Berechtigungen',
        link: '/permissions',
        permission: 'admin',
    },
    {
        type: 'divider',
    },
    {
        type: 'link',
        icon: <PersonIcon/>,
        name: 'Profil',
        link: '/profile',
    },
    {
        type: 'divider',
    },
    {
        type: 'click',
        icon: <SettingsPowerIcon/>,
        name: 'Logout',
    },
];

export default navigation;
