import API from '../api/API';
import Project from '../interfaces/models/Project';

export default {
    all: (): Promise<Project[]> => {
        return API.project.get();
    },
    getByStage: (stage: string): Promise<string> => {
        return API.project.get(`/stage/${stage}`);
    },
    create: (data: object): Promise<Project> => {
        return API.project.post({
            data: data,
        });
    },
    get: async (id: number): Promise<Project> => {
        return API.project.get(id);
    },
    getForDetailPage: async (id: number): Promise<Project> => {
        return API.project.get(id);
    },
    getForFinancialProduct: async (id: number): Promise<Project> => {
        return API.project.get(`/origination-finalized/${id}`);
    },
    updateFromTable: async (id: number, data: Project): Promise<Project> => {
        console.log('PROJECT SERVICE: ', id, data);
        return API.project.patch({
            url: id,
            data: data,
        });
    },
    exportMarketing: (id: number): Promise<string> => {
        return API.project.get(`/marketing/send/${id}`);
    },
}
