import React, {ReactElement, useEffect, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import Helper from '../../helpers/Helper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Card from '@material-ui/core/Card';
import {CardHeader} from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Loader from '../UI/Loader';
import EventTimeline from './EventTimeline';
import ThemeHelper from '../../helpers/ThemeHelper';
import Button from '@material-ui/core/Button';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EventNew from './EventNew';
import EventService from '../../services/EventService';
import EventsProps from '../../interfaces/props/EventsProps';
import Event from '../../interfaces/models/Event';
import EventsParams from '../../interfaces/params/EventsParams';
import {useAppContext} from '../../libs/contextLib';
import UserHelper from '../../helpers/UserHelper';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '100%',
        flexGrow: 1,
        paddingLeft: 10,
        paddingRight: 10,
    },
    card: {
        maxWidth: '100%',
        height: '100%',
        flexGrow: 1,
        marginTop: 16,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    cardHeader: {
        backgroundColor: ThemeHelper.getColor(theme, theme.palette.cardHeader.backgroundColor),
        color: ThemeHelper.getColor(theme, theme.palette.cardHeader.color),
    },
    button: {
        color: ThemeHelper.getColor(theme, theme.palette.primary),
    },
}));

export default function Events(props: EventsProps): ReactElement {
    const classes = useStyles();
    const match = useRouteMatch<EventsParams>();
    const [events, setEvents] = useState<Event[]>([] as Event[]);
    const [isLoading, setIsLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const {setMenuItems, setBreadcrumbItems} = props;
    const {user} = useAppContext();

    useEffect(() => {
        setIsLoading(true);
        setMenuItems!([]);

        if (match.params.id && match.params.id !== '') {
            EventService.allProjectEvents(parseInt(match.params.id, 10)).then(events => {
                setEvents(events);
                setIsLoading(false);

                setBreadcrumbItems!([
                    {
                        type: 'link',
                        link: '/projects/',
                        name: 'Projekte',
                    },
                    {
                        type: 'link',
                        link: `/project/${match.params.id}/`,
                        name: 'Projekt #' + match.params.id,
                    },
                    {
                        type: 'typography',
                        name: `Ereignisse`,
                    },
                ]);

                Helper.setPageTitle('Ereignisse - Projekt #' + match.params.id);
            })
        }
    }, [match.params.id, setMenuItems, setBreadcrumbItems]);

    const handleSave = (data: Event): void => {
        setEditMode(false);

        if (Object.keys(data).length === 0) {
            return;
        }

        if (match.params.id) {
            data['projectId'] = parseInt(match.params.id, 10);
        }

        EventService.create(data).then(response => {
            setEvents([...events, response]);
        }).catch(() => { });
    }

    if (isLoading) {
        return <Loader open={isLoading}/>
    }

    return (
        <Card className={classes.card}>
            <CardHeader
                title='Ereignisse'
                className={classes.cardHeader}
                action={
                    UserHelper.hasPermission(user, 'edit') && !editMode
                        ? <Button
                            onClick={() => setEditMode(true)}
                            size='small'
                            className={classes.button}
                        >
                            <AddCircleOutlineIcon/>
                        </Button>
                        : null
                }
            >
            </CardHeader>
            <CardContent className={classes.root}>
                {
                    editMode
                        ? (<EventNew
                            onCancel={() => setEditMode(false)}
                            onSave={handleSave}
                        />)
                        : null
                }
                {
                    events
                        ? (<EventTimeline events={events}/>)
                        : null
                }
            </CardContent>
        </Card>
    );
}
