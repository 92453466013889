import React, {useEffect, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import BaseTable from '../../UI/Tables/BaseTable';
import PersonService from '../../../services/PersonService';
import CompanyService from '../../../services/CompanyService';

export default function ManagingDirectorTable(props) {
    const [tableData, setTableData] = useState([]);
    const [accounts, setAccounts] = useState({});
    const match = useRouteMatch();

    useEffect(() => {
        setTableData(props.managingDirectors);
        formatAccountData(tableData);
    }, [props.managingDirectors, tableData]);

    const formatAccountData = tableData => {
        let accountData = {};
        PersonService.all().then(accounts => {
            accounts.forEach(account => {
                 const managingDirectorIds = tableData.map(account => {
                     return account.id;
                 })

                 if (!managingDirectorIds.includes(account.id)) {
                     accountData[account.id] = account.name;
                 }
            });
        }).catch(() => { });

        setAccounts(accountData);
    };

    const formatAccountName = row => {
        return row ? row.name : '';
    };

    const columns = [
        {
            title: 'ID',
            field: 'id',
            editable: 'never',
            filtering: false,
            width: '65px',
        },
        {
            title: 'Name',
            id: 'name',
            field: 'name',
            lookup: accounts,
            render: row => formatAccountName(row),
            editable: 'onAdd',
            type: 'accountSelect',
            accountType: 'person',
        },
    ];

    const handleAdd = newData => {
        const accountId = match.params.id;
        return CompanyService.attachManagingDirector(accountId, newData.name);
    }

    const handleDelete = data => {
        const accountId = match.params.id;
        return CompanyService.detachManagingDirector(accountId, data.id);
    };

    return (
        <BaseTable
            tableName={'managingDirectorTable'}
            columns={columns}
            data={tableData}
            enableRowAdd={true}
            disableUpdateAction={true}
            onAdd={handleAdd}
            onDelete={handleDelete}
            title={'Geschäftsführer'}
            objects={'Geschäftsführer'}
            filtering={false}
            search={false}
        />
    );
}
