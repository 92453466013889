import React, {useEffect, useState} from 'react';
import Formatter from '../../helpers/Formatter';
import BaseTable from '../UI/Tables/BaseTable';
import RoleService from '../../services/RoleService';
import PermissionService from '../../services/PermissionService';
import Search from '../../helpers/Search';
import RolesTableProps from '../../interfaces/props/RolesTableProps';
import Permission from '../../interfaces/models/Permission';
import Role from '../../interfaces/models/Role';

export default function RolesTable(props: RolesTableProps) {
    const [data, setData] = useState<Role[]>([] as Role[]);
    const [permissions, setPermissions] = useState<Permission[]>([] as Permission[]);
    const {setLoader} = props;

    useEffect(() => {
        setLoader(true);

        RoleService.all().then(response => {
            response = response || [];
            setData(response);
            setLoader(false);
        }).catch(() => {
            setLoader(false);
        });

        PermissionService.all().then(response => {
            response = response || [];
            setPermissions(response);
        }).catch(() => { });
    }, [setLoader]);

    const columns = [
        {
            title: 'ID',
            id: 'id',
            editable: 'never',
            render: (row: any) => Formatter.formatId(row),
            width: '20px',
        },
        {
            title: 'Name',
            field: 'name',
            render: (row: any) => Formatter.formatRoleColumn(row.name, 'roles'),
        },
        {
            title: 'Berechtigungen',
            field: 'permissions',
            render: (row: any) => Formatter.formatRolesColumn(row, 'permissions'),
            customFilterAndSearch: (term: string, rowData: any) => Search.searchRolesColumn(term, rowData['permissions']),
            type: 'multiSelect',
            options: permissions,
        },
    ];

    const handleDelete = (model: Role) => {
        return RoleService.delete(model.id);
    };

    const handleSave = (model: Role) => {
        return RoleService.create(model);
    }

    const handleUpdate = (model: Role) => {
        return RoleService.updateFromTable(model.id, model);
    };

    return (
        <>
            <BaseTable
                tableName='rolesTable'
                columns={columns}
                data={data}
                onAdd={handleSave}
                onDelete={handleDelete}
                onUpdate={handleUpdate}
                onUpdateDisableDiff={true}
                enableRowAdd={true}
                title='Rollen'
                objects='Rollen'
            />
        </>
    );
}
