import User from '../interfaces/models/User';

const UserHelper = {
    hasPermission: (user: User, permission: string): boolean => {
        return user
            && user.permissions
            && user.permissions.indexOf(permission) !== -1;
    },
}

export default UserHelper;
