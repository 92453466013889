import Projects from '../components/Projects';
import Accounts from '../components/Accounts';
import NotFound from '../components/Errors/PageNotFound';
import Documents from '../components/Documents';
import RiskAssessment from '../components/RiskAssessment';
import Archive from '../components/Projects/Archive';
import ProjectDetailPage from '../components/Projects/DetailPage';
import FinancingEntityDetailPage from '../components/FinancingEntities/FinancingEntityDetailPage';
import AccountDetailPage from '../components/Accounts/DetailPage';
import RealEstatePropertyDetailPage from '../components/RealEstateProperties/DetailPage';
import Users from '../components/Users';
import Roles from '../components/Roles';
import Permissions from '../components/Permissions';
import Profile from '../components/Profile';
import Route from '../interfaces/Route';
import Events from '../components/Events';
import LandRegisterDetailPage from '../components/LandRegisters/DetailPage';
import RealEstatePropertyImportPreviewPage from '../components/RealEstateProperties/DetailPage/Import/Preview';
import Reporting from '../components/Reportings';
import AssetInventoryTable from "../components/Reportings/AssetInventoryTable";
import FinalizeProject from "../components/Projects/DetailPage/FinalizeProject";

const routes: Array<Route> = [
    {
        path: '/projects',
        exact: true,
        auth: true,
        component: Projects,
    },
    {
        path: '/projects/archived',
        exact: true,
        auth: true,
        component: Archive,
    },
    {
        path: '/project/:id',
        exact: true,
        auth: true,
        component: ProjectDetailPage,
    },
    {
        path: '/project/:id/finalize',
        exact: true,
        auth: true,
        component: FinalizeProject,
    },
    {
        path: '/project/:id/fe/:financingEntityId',
        exact: true,
        auth: true,
        component: FinancingEntityDetailPage,
    },
    {
        path: '/project/:id/documents',
        exact: true,
        auth: true,
        component: Documents,

    },
    {
        path: '/project/:id/assessment',
        exact: true,
        auth: true,
        component: RiskAssessment,
    },
    {
        path: '/project/:id/events',
        exact: true,
        auth: true,
        component: Events,
    },
    {
        path: '/project/:id/reportings',
        exact: true,
        auth: true,
        component: Reporting,
    },
    {
        path: '/reporting',
        exact: true,
        auth: true,
        component: AssetInventoryTable,
    },
    {
        path: '/reporting/:financialProductId',
        exact: true,
        auth: true,
        component: Reporting,
    },
    {
        path: '/accounts',
        exact: true,
        auth: true,
        component: Accounts,
    },
    {
        path: '/account/:id',
        exact: true,
        auth: true,
        component: AccountDetailPage,
    },
    {
        path: '/real-estate-property/:id/import/preview',
        exact: true,
        auth: true,
        component: RealEstatePropertyImportPreviewPage,
    },
    {
        path: '/real-estate-property/:id',
        exact: true,
        auth: true,
        component: RealEstatePropertyDetailPage,
    },
    {
        path: '/users',
        exact: true,
        auth: true,
        component: Users,
    },
    {
        path: '/roles',
        exact: true,
        auth: true,
        component: Roles,
    },
    {
        path: '/permissions',
        exact: true,
        auth: true,
        component: Permissions,
    },
    {
        path: '/profile',
        exact: true,
        auth: true,
        component: Profile,
    },
    {
        path: '/land-register/:id',
        exact: true,
        auth: true,
        component: LandRegisterDetailPage,
    },
    {
        path: '/:anything',
        exact: false,
        auth: true,
        component: NotFound,
    },
];

export default routes;
