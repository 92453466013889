import React, {useEffect, useState} from 'react';
import Formatter from '../../helpers/Formatter';
import BaseTable from '../UI/Tables/BaseTable';
import PermissionService from '../../services/PermissionService';
import RoleService from '../../services/RoleService';
import Search from '../../helpers/Search';
import PermissionsTableProps from '../../interfaces/props/PermissionsTableProps';
import Permission from '../../interfaces/models/Permission';
import Role from '../../interfaces/models/Role';

export default function PermissionsTable(props: PermissionsTableProps) {
    const [data, setData] = useState<Permission[]>([] as Permission[]);
    const [roles, setRoles] = useState<Role[]>([] as Role[]);
    const {setLoader} = props;

    useEffect(() => {
        setLoader(true);

        PermissionService.all().then(response => {
            response = response || [];
            setData(response);
            setLoader(false);
        }).catch(() => {
            setLoader(false);
        });

        RoleService.all().then(response => {
            response = response || [];
            setRoles(response);
        }).catch(() => { });
    }, [setLoader]);

    const columns = [
        {
            title: 'ID',
            id: 'id',
            editable: 'never',
            render: (row: any) => Formatter.formatId(row),
            width: '20px',
        },
        {
            title: 'Name',
            field: 'name',
            render: (row: any) => Formatter.formatRoleColumn(row.name, 'permissions'),
        },
        {
            title: 'Rollen',
            field: 'roles',
            render: (row: any) => Formatter.formatRolesColumn(row, 'roles'),
            customFilterAndSearch: (term: string, rowData: any) => Search.searchRolesColumn(term, rowData.roles),
            type: 'multiSelect',
            options: roles,
        },
    ];

    const handleDelete = (model: Permission) => {
        return PermissionService.delete(model.id);
    };

    const handleSave = (model: Permission) => {
        return PermissionService.create(model);
    }

    const handleUpdate = (model: Permission) => {
        return PermissionService.updateFromTable(model.id, model);
    };

    return (
        <>
            <BaseTable
                tableName='permissionsTable'
                columns={columns}
                data={data}
                onAdd={handleSave}
                onDelete={handleDelete}
                onUpdate={handleUpdate}
                onUpdateDisableDiff={true}
                enableRowAdd={true}
                title='Berechtigungen'
                objects='Berechtigungen'
            />
        </>
    );
}
