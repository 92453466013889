import React, {ReactElement, useEffect, useState} from 'react';
import Card from '@material-ui/core/Card';
import {CardHeader} from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import EventTimeline from '../../Events/EventTimeline';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ThemeHelper from '../../../helpers/ThemeHelper';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import Event from '../../../interfaces/models/Event';
import EventService from '../../../services/EventService';
import LatestEventsProps from '../../../interfaces/props/LatestEventsProps';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EventNew from '../../Events/EventNew';
import CardActions from '@material-ui/core/CardActions';
import UserHelper from '../../../helpers/UserHelper';
import {useAppContext} from '../../../libs/contextLib';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '100%',
        flexGrow: 1,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 0,
        marginBottom: 0,
    },
    card: {
        maxWidth: '100%',
        flexGrow: 1,
        marginTop: 16,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingBottom: 0,
        marginBottom: 0,
    },
    cardHeader: {
        backgroundColor: ThemeHelper.getColor(theme, theme.palette.cardHeader.backgroundColor),
        color: ThemeHelper.getColor(theme, theme.palette.cardHeader.color),
    },
    button: {
        color: ThemeHelper.getColor(theme, theme.palette.primary),
    },
}));

export default function LatestEvents (props: LatestEventsProps): ReactElement {
    const classes = useStyles();
    const [events, setEvents] = useState<Event[]>([] as Event[]);
    const [projectId, setProjectId] = useState<number>(0);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [cnt, setCnt] = useState(0);
    const {user} = useAppContext();

    useEffect(() => {
        if (props.events) {
            setEvents(props.events.slice(0, 3));
            setProjectId(props.projectId);
            setCnt(props.events.length);
        }
    }, [props.events, props.projectId]);

    const handleShowAllEvents = (): void => {
        window.location.href = `/#/project/${projectId}/events`;
    };

    const handleSave = (data: Event): void => {
        setEditMode(false);

        if (Object.keys(data).length === 0) {
            return;
        }

        data['projectId'] = projectId;

        setEditMode(false);
        EventService.create(data).then(response => {
            setEvents([...events, response]);
        }).catch(() => { });
    }

    const title = (): string => {
        return (events && events.length === cnt)
            ? 'Alle Ereignisse'
            : `Ereignisse ${events?.length} von ${cnt}`;
    };

    return (
        <Card className={classes.card}>
            <CardHeader
                title={title()}
                className={classes.cardHeader}
                action={
                    UserHelper.hasPermission(user, 'edit') && !editMode
                        ? <Button
                            onClick={() => setEditMode(true)}
                            size='small'
                            className={classes.button}
                            startIcon={<AddCircleOutlineIcon/>}
                          >
                            Neues Ereignis
                          </Button>
                        : null
                }
            >
            </CardHeader>
            <CardContent className={classes.root}>
                {
                    editMode
                        ? (<EventNew
                            onCancel={() => setEditMode(false)}
                            onSave={handleSave}
                        />)
                        : null
                }
                {
                    events && events.length > 0
                        ? (<EventTimeline events={events} showInfinity={cnt > events.length} shortText={true}/>)
                        : 'Noch keine Ereignisse eingetragen'
                }
            </CardContent>
            {
                events && events.length > 0
                    ? (<CardActions>
                        <Button
                            onClick={handleShowAllEvents}
                            size='small'
                            className={classes.button}
                            startIcon={<SpeakerNotesIcon/>}
                        >
                            Ereignisse komplett anzeigen
                        </Button>
                    </CardActions>)
                    : null
            }
        </Card>
    );
}
