import React, {ReactElement, useEffect, useState} from 'react';
import EditableCard from '../../UI/Cards/EditableCard';
import Project from '../../../interfaces/models/Project';
import CardData from '../../../interfaces/CardData';
import LandRegisterCardProps from '../../../interfaces/props/LandRegisterCardProps';
import LandRegister from '../../../interfaces/models/LandRegister';
import Formatter from '../../../helpers/Formatter';
import {RenderTypes} from '../../../interfaces/enums/RenderTypes';

export default function LandRegisterInscriptionCard(props: LandRegisterCardProps): ReactElement {
    const {landRegister} = props;
    const [data, setData] = useState<LandRegister>({} as LandRegister);

    useEffect(() => {
        setData(landRegister);
    }, [landRegister]);

    const tableData: CardData[] = [
        {
            label: 'Grundbuch',
            value: data.title ? data.title : null,
            name: 'title',
            editable: true,
        },
        {
            label: 'Grundbuch vom',
            value: data.landRegisterDate ? data.landRegisterDate : null,
            name: 'landRegisterDate',
            editable: true,
            formatter: Formatter.formatDate,
            type: RenderTypes.date,
        },
        {
            label: 'Amt / Amtsgericht',
            value: data.districtCourt ? data.districtCourt : null,
            name: 'districtCourt',
            editable: true,
        },
        {
            label: 'Bandnummer',
            value: data.tapeNumber ? data.tapeNumber : null,
            name: 'tapeNumber',
            editable: true,
        },
        {
            label: 'Blatt',
            value: data.page ? data.page : null,
            name: 'page',
            editable: true,
        },
    ];

    const handleSave = (updatedData: Project): void => {
        setData({
            ...data,
            ...updatedData
        });

        props.onSave(updatedData);
    };

    return (
        <EditableCard
            tableData={tableData}
            onSave={handleSave}
            title={'Aufschrift'}
        />
    );
}
