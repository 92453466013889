const Helper = {
    fallback: (obj: any, fallbackValue: any = null): any => {
        return typeof obj !== 'undefined' ? obj : fallbackValue;
    },
    validLink: (link: string): boolean => {
        const pattern = new RegExp('^(https?:\\/\\/)'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return pattern.test(link);
    },
    setPageTitle: (pageTitle: string): void => {
        document.title = pageTitle + ' - MagicTool';
    },
    areWeTestingWithJest: (): boolean => {
        return process.env.JEST_WORKER_ID !== undefined;
    },
    getLocale: (): string => {
        return Helper.areWeTestingWithJest() ? 'en-US' : 'de-DE';
    },
    generateYearsBack: (count: number): any => {
        count++; // Current year shouldn't count towards counter.

        let years = [];

        const currentYear = new Date().getFullYear();

        for (let i = 0; i < count; i++) {
            const calculatedYear = currentYear - i;

            years.push({
                value: calculatedYear,
                label: calculatedYear,
            });
        }

        return years;
    },
}

export default Helper;
