import React, {useEffect, useMemo, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PictureDropzoneAreaProps from '../../../interfaces/props/PictureDropzoneAreaProps';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    },
    buttonCancel: {
        margin: theme.spacing(1),
        color: theme.palette.error.main,
    },
    description : {
        marginLeft: 16,
        marginRight: 16,
    },
    container: {
        background: 'transparent',
        margin: 0,
    },
    thumbsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        margin: 16,
    },
    thumb: {
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginBottom: 8,
        marginRight: 8,
        width: 100,
        height: 100,
        padding: 4,
        boxSizing: 'border-box'
    },
    thumbInner: {
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden'
    },
    img: {
        display: 'block',
        width: 'auto',
        height: '100%'
    },
}));

const baseStyle = {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: 'transparent',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    marginTop: 16,
    marginLeft: 16,
    marginRight: 16,
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

export default function PictureDropzoneArea(props: PictureDropzoneAreaProps) {
    const classes = useStyles();
    const [files, setFiles] = useState<File[]>([] as File[]);
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        accept: 'image/jpeg, image/png',
        multiple: false,
        maxSize: 25000000,
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file),
            })));
        },
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
    }), [
        isDragActive,
        isDragReject,
        isDragAccept,
    ]);

    const thumbs = files.map(file => (
        <div className={classes.thumb} key={file.name}>
            <div className={classes.thumbInner}>
                <img
                    // @ts-ignore
                    src={file.preview}
                    className={classes.img}
                    alt='preview'
                />
            </div>
        </div>
    ));

    useEffect(() => () => {
        // @ts-ignore
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    const startUpload = () => {
        props.onSave(files);
        setFiles([]);
    };

    const closeModal = () => {
        setFiles([]);
        props.onClose();
    };

    return (
        <section className={classes.container}>
            <Typography variant='body2' color='textSecondary' component='p' className={classes.description}>
                Bitte wählen Sie für den Upload ein JPG oder PNG Bild aus.
                <br/>
                Die maximale Bildgröße liegt bei 25MB.
            </Typography>
            <div {...getRootProps({style})}>
                <input {...getInputProps()} />
                <p>Hier können Sie ein Bild platzieren oder per Mausklick ein Bild auswählen.</p>
            </div>
            <aside className={classes.thumbsContainer}>
                {thumbs}
            </aside>
            {props.cancelButton || files.length
                ?
                    <Button onClick={closeModal} size='small' color='primary' startIcon={<CloseIcon />} className={classes.buttonCancel}>
                        Abbrechen
                    </Button>
                : null
            }
            {files.length
                ?
                    <Button onClick={startUpload} size='small' color='primary' startIcon={<CheckIcon />} className={classes.button}>
                        Hochladen
                    </Button>
                : null
            }
        </section>
    );
}
