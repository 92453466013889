import React, {useState} from 'react';
import TextField from '@material-ui/core/TextField';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Card from '@material-ui/core/Card';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ButtonEditOkCancel from '../Partials/ButtonEditOkCancel';
import Render from '../../../helpers/Render';
import DebugHint from '../Partials/DebugHint';

const useStyles = makeStyles( {
    root: {
        maxWidth: '100%',
        flexGrow: 1,
    },
    card: {
        maxWidth: '100%',
        flexGrow: 1,
        margin: 0,
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    title: {
        margin: 15,
        marginBottom: 0
    },
});

export default function EditableSmallTextCard(props) {
    const [editMode, setEditMode] = useState(false);
    const classes = useStyles();
    const initialValue = props.data[props.name];

    const handleSave = async () => {
        await props.onSave();
        setEditMode(false);
    }

    const handleCancel = async () => {
        props.data[props.name] = initialValue;
        setEditMode(false);
    }

    return (
        <>
            <Card className={classes.card} height={'100%'}>
                <Typography className={classes.title} color='textSecondary' gutterBottom>
                    {props.title}
                </Typography>
                <CardContent className={classes.root}>
                    {editMode
                        ? (
                            <TextField
                                id={props.name}
                                multiline
                                rows={10}
                                defaultValue={props.data ? props.data[props.name] : ''}
                                variant='outlined'
                                name={props.name}
                                onChange={props.onChange}
                                style={{width: '100%'}}
                            />
                        )
                        : (
                            <div style={{whiteSpace: 'pre-line'}}>{props.data && props.data[props.name] ? props.data[props.name] : Render.defaultValue()}</div>
                        )
                    }
                </CardContent>
                <CardActions>
                    <ButtonEditOkCancel
                        onEdit={() => setEditMode(true)}
                        onCancel={handleCancel}
                        onSave={handleSave}
                        editMode={editMode}>
                    </ButtonEditOkCancel>
                    <DebugHint id={props.name}/>
                </CardActions>
            </Card>
        </>
    );
}
