import Formatter from '../helpers/Formatter';
import axios from 'axios';
import EventSystem from '../EventSystem';
import UserService from '../services/UserService';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

const showErrorMessage = text => {
    EventSystem.publish(
        'notification',
        text,
        {
            variant: 'error',
        }
    );
}

const showUnauthorizedMessageAndLogoutUser = () => {
    UserService.logout();
    showErrorMessage('Du bist nicht mehr eingeloggt. Bitte speicher deine Daten zwischen und lade die Webseite neu um dich erneut einzuloggen.');
}

instance.interceptors.request.use((request) => {
    const accessToken = localStorage.getItem('access-token');
    if (!accessToken) {
        showUnauthorizedMessageAndLogoutUser();
        return;
    }

    request.headers.common.Authorization = `Bearer ${accessToken}`;

    return request;
});

instance.interceptors.response.use(function (response) {
    if (response.headers['x-api-access-token']) {
        localStorage.setItem('access-token', response.headers['x-api-access-token']);
    }

    return Promise.resolve(response.data);
}, function (error) {
    if (error && typeof error.response !== 'undefined' && error.response.status === 401) {
        showUnauthorizedMessageAndLogoutUser();
        return Promise.reject(error);
    } else if (error &&
        typeof error.response !== 'undefined' &&
        error.response.status === 422 &&
        typeof error.response.data.errors === 'object'
        ) {
            const errorKeys = Object.keys(error.response.data.errors);
            if (typeof error.response.data.errors[errorKeys[0]][0] === 'string') {
                showErrorMessage('Es gab folgenden Fehler: ' + error.response.data.errors[errorKeys[0]][0]);
            } else {
                showErrorMessage('Die Anfrage an das Backend ist leider ungültig!');
            }

            return Promise.reject(error);
    } else if (error &&
        typeof error.response !== 'undefined' &&
        error.response.status === 403
    ) {
        showErrorMessage('Keine Berechtigung für diesen Bereich.');

        EventSystem.publish('redirect', '/projects');

        return Promise.reject(error);
    } else if (error &&
        typeof error.response !== 'undefined' &&
        error.response.status === 410
    ) {
        showErrorMessage('Die angeforderte Ressource wird nicht länger bereitgestellt und wurde entfernt.');

        window.location.href = '/#/projects';

        return Promise.reject(error);
    }

    showErrorMessage('Es gab einen Fehler beim Kommunizieren mit dem Backend.');

    return Promise.reject(error);
});

const target = {};
const handler = {
    get(target, name) {
        return Object.assign(
            {},
            [
                'get',
                'delete',
                'head'
            ].reduce(
                (o, method) => Object.assign({}, o, {
                    [method](url = '', params = {}) {
                        if (typeof url === 'object') {
                            params = url;
                            url = '';
                        } else if (typeof url === 'number' || url.match(/^\d+$/)) {
                            url = '/' + url;
                        }

                        name = Formatter.formatCamelCaseToRoute(name);

                        return instance[method](name + url, { params });
                    }
                }), {}),
            [
                'post',
                'put',
                'patch'
            ].reduce(
                (o, method) => Object.assign({}, o, {
                    [method](params = {}) {
                        let url = params.url ?? '';
                        let data = params.data ?? {};
                        let fileUpload = params.fileUpload ?? false;

                        if (url.toString().match(/^\d+$/)) {
                            url = '/' + url.toString();
                        }

                        name = Formatter.formatCamelCaseToRoute(name);

                        if (fileUpload) {
                            return instance[method](name + url, data, {}, {
                                headers: { 'Content-Type': 'multipart/form-data' },
                            });
                        }

                        console.log('API', data);

                        return instance[method](name + url, {}, { data });
                    }
                }), {})
        );
    }
};

export default new Proxy(target, handler);
