import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Hint from '../Partials/Hint';
import Render from '../../../helpers/Render';
import DebugHint from '../Partials/DebugHint';
import {RenderTypes} from "../../../interfaces/enums/RenderTypes";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
    table: {
        minWidth: '100%',
    },
});

export default function DetailsTable(props) {
    const classes = useStyles();
    const [tableData, setTableData] = useState([]);

    const {data} = props;
    useEffect(() => setTableData(data), [data]);

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} size='small'>
                <TableBody key={data}>
                    {tableData.map(row => (
                        <>
                            {row.type === RenderTypes.divider
                                ? <>
                                    <TableRow key={row.label}>
                                        <TableCell>
                                            <Typography variant="h6">
                                                {row.label}
                                            </Typography>
                                        </TableCell>
                                      </TableRow>
                                      <Divider variant="middle"/>
                                  </>
                                : <TableRow key={row.label}>
                                    <TableCell>
                                        {row.label}
                                        <Hint message={row.hint}/>
                                        <DebugHint id={row.name}/>
                                    </TableCell>
                                    <TableCell align='right'>
                                        {props.editMode && row.editable
                                            ? Render.renderEditComponentByType(props, row)
                                            : row.formatter ? row.formatter(row.value) || Render.defaultValue() : row.value !== null ? row.value : Render.defaultValue()}
                                    </TableCell>
                                </TableRow>
                            }
                        </>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
