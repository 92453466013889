import React, {useEffect} from 'react';
import {useRouteMatch} from 'react-router-dom';
import RiskAssessmentProps from '../../interfaces/props/RiskAssessmentProps';
import RiskAssessmentParams from '../../interfaces/params/RiskAssessmentParams';

export default function RiskAssessment(props: RiskAssessmentProps) {
    const match = useRouteMatch<RiskAssessmentParams>();
    const {setMenuItems} = props;

    useEffect(() => {
        setMenuItems!([
            {
                title: 'Zurück zum Projekt',
                to: `/project/${match.params.id}`,
            },
            /*
            {
                title: 'Dokumente',
                to: `/project/${match.params.id}/documents`,
            },
            {
                title: 'Prüfung',
                to: `/project/${match.params.id}/assessment`,
            },
            */
        ]);
    }, [match.params.id, setMenuItems]);

    return (
        <>
            <h1>Projektprüfung</h1>
            <p>Hier könnte dann die Prüfung stattfinden. Crefo, Location Analytics, usw.</p>
        </>
    );
}