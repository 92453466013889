import React, {useEffect, useState} from 'react';
import {Link as RouterLink, useRouteMatch} from 'react-router-dom';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Formatter from '../../helpers/Formatter';
import Link from '@material-ui/core/Link';
import FinancingEntityService from '../../services/FinancingEntityService';
import BaseTable from '../UI/Tables/BaseTable';
import {useAppContext} from '../../libs/contextLib';

export default function FinancingStructureTable(props) {
    const {selectOptions} = useAppContext();
    const [stageTypes, setStageTypes] = useState({});
    const [sourceTypes, setSourceTypes] = useState({});
    const [types, setTypes] = useState({});
    const [legalSetupTypes, setLegalSetupTypes] = useState({});
    const [bondTypes, setBondTypes] = useState({});
    const match = useRouteMatch();
    const {financingEntities} = props;

    useEffect(() => {
        if (Object.keys(selectOptions).length === 0) {
            return;
        }

        setTypes(selectOptions.financingEntity.types);
        setStageTypes(selectOptions.financingEntity.stageTypes);
        setSourceTypes(selectOptions.financingEntity.sourceTypes);
        setLegalSetupTypes(selectOptions.financingEntity.legalSetupTypes);
        setBondTypes(selectOptions.financingEntity.bondTypes);
    }, [selectOptions]);

    const formatIdColumn = row => {
        if (!row) {
            return;
        }

        return (
            <Link component={RouterLink} to={`/project/${match.params.id}/fe/${row.id}`}>{Formatter.formatId(row)}</Link>
        );
    };

    const columns = [
        {
            title: 'ID',
            field: 'id',
            render: row => formatIdColumn(row),
            filtering: false,
            width: '65px',
            editable: 'never',
        },
        {
            title: 'Art',
            field: 'type',
            editable: 'onUpdate',
            lookup: types,
        },
        {
            title: 'Quelle',
            editable: 'onUpdate',
            field: 'source',
            lookup: sourceTypes,
        },
        {
            title: 'Legal Setup',
            editable: 'onUpdate',
            field: 'legalSetup',
            lookup: legalSetupTypes,
        },
        {
            title: 'Start',
            editable: 'onUpdate',
            field: 'startsAt',
            render: row => Formatter.formatDate(row.startsAt),
            type: 'date',
        },
        {
            title: 'Ende',
            field: 'endsAt',
            editable: 'onUpdate',
            render: row => Formatter.formatDate(row.endsAt),
            type: 'date',
        },
        {
            title: 'Betrag',
            field: 'fundingTargetMin',
            type: 'currency',
            render: row => Formatter.formatCurrency(row.fundingTargetMin),
            editable: 'onUpdate',
        },
        {
            title: 'Anleihentyp',
            field: 'bondType',
            editable: 'onUpdate',
            lookup: bondTypes,
        },
        {
            title: 'Stage',
            field: 'stage',
            editable: (_, row) => row && row.stage !== 'funded',
            lookup: stageTypes,
        },
    ];

    const handleUpdate = updatedData => {
        return FinancingEntityService.update(updatedData.id, updatedData);
    };

    const handleDelete = data => {
        return FinancingEntityService.delete(data.id);
    };

    return (
        <TableContainer component={Paper}>
            <BaseTable
                tableName='financingStructureTable'
                columns={columns}
                data={financingEntities}
                onUpdate={handleUpdate}
                title=''
                objects='Financing Entity'
                onDelete={handleDelete}
                search={false}
                paging={false}
                filtering={false}
                columnsButton={false}
                toolbar={false}
            />
        </TableContainer>
    );
}
