import API from '../api/API';
import FinancingEntity from '../interfaces/models/FinancingEntity';

export default {
    get: (id: number): Promise<FinancingEntity> => {
        return API.financingEntity.get(id);
    },
    create: (data: object): Promise<FinancingEntity> => {
        return API.financingEntity.post({
            data: data,
        });
    },
    update: (id: number, data: FinancingEntity): Promise<FinancingEntity> => {
        return API.financingEntity.patch({
            url: id,
            data: data,
        });
    },
    delete: (id: number): Promise<string> => {
        return API.financingEntity.delete(id);
    },
    createFinancialProduct: (id: number): Promise<string> => {
        return API.financingEntity.get(`/financial-product/${id}`);
    },
}
