import React, {ReactElement, useEffect, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import Helper from '../../helpers/Helper';
import Loader from '../UI/Loader';
import ReportingProps from '../../interfaces/props/ReportingProps';
import ReportingQuarter from '../../interfaces/models/ReportingQuarter';
import {IdMatchParam} from '../../interfaces/matchs/IdMatchParam';
import ReportingService from '../../services/ReportingService';
import ReportingList from './ReportingList';

export default function Reporting(props: ReportingProps): ReactElement {
    const match = useRouteMatch<IdMatchParam>();
    const [reportings, setReportings] = useState<ReportingQuarter[]>([] as ReportingQuarter[]);
    const [isLoading, setIsLoading] = useState(false);
    const {setMenuItems, setBreadcrumbItems} = props;

    useEffect(() => {
        setIsLoading(true);

        setMenuItems!([]);

        if (!match.params.financialProductId && match.params.financialProductId === '') {
            return;
        }

        setBreadcrumbItems!([
            {
                type: 'link',
                link: '/reporting/',
                name: 'Reporting',
            },
            {
                type: 'typography',
                name: '#' + match.params.financialProductId,
            },
        ]);

        Helper.setPageTitle('Reportings - Projekt #' + match.params.financialProductId);

        ReportingService.getAllById(match.params.financialProductId).then(reportings => {
            setReportings(reportings);
            setIsLoading(false);
        }).catch(() => { });
    }, [match.params.financialProductId, setMenuItems, setBreadcrumbItems]);

    if (isLoading) {
        return <Loader open={isLoading}/>
    }

    return (
        <>
            {
                reportings
                    ? (
                        <ReportingList
                            reportingQuarters={reportings}
                            financialProductId={match.params.financialProductId}
                        />
                        )
                    : null
            }
        </>
    );
}
