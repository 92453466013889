const itemName: string = 'darkmode-Status';

export default {
    getDarkModeStatus: (): boolean => {
        return localStorage.hasOwnProperty(itemName) ? (localStorage.getItem(itemName) === 'true') : false;
    },
    setDarkModeStatus: (value: string): void => {
        return localStorage.setItem(itemName, value);
    },
}
