import React, {ChangeEvent, useEffect, useState} from 'react';
import {CardActions, CardHeader} from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import DetailsTable from '../UI/Tables/DetailsTable';
import Card from '@material-ui/core/Card';
import {makeStyles} from '@material-ui/core/styles';
import ButtonEditOkCancel from '../UI/Partials/ButtonEditOkCancel';
import ThemeHelper from '../../helpers/ThemeHelper';
import EditableCardProps from "../../interfaces/props/EditableCardProps";
import ReportingQuarter from "../../interfaces/models/ReportingQuarter";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditableTextCard from "../UI/Cards/EditableTextCard";

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: '100%',
    },
    card: {
        maxWidth: '100%',
        flexGrow: 1,
        marginTop: 16,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start'
    },
    cardHeader: {
        backgroundColor: ThemeHelper.getColor(theme, theme.palette.cardHeader.backgroundColor),
        color: ThemeHelper.getColor(theme, theme.palette.cardHeader.color),
    },
    cardHeaderSecondary: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    rootDialog: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        padding: 24,
    },
    appBar: {
        position: 'relative',
        backgroundColor: ThemeHelper.getColor(theme, theme.palette.cardHeader.backgroundColor),
        color: ThemeHelper.getColor(theme, theme.palette.cardHeader.color),
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    margin: {
        margin: theme.spacing(1),
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
        color: ThemeHelper.getColor(theme, theme.palette.cardHeader.color),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '100%',
    },
}));

export default function ReportingDataCard(props: EditableCardProps) {
    const classes = useStyles();
    const [editMode, setEditMode] = useState(false);
    const [data, setData] = useState<ReportingQuarter>({} as ReportingQuarter);
    const [summariesOpen, setSummariesOpen] = useState(false);
    const [summaries, setSummaries] = useState({});

    useEffect(() => {
        if (props.summaries) {
            setSummaries(props.summaries);
        }
    }, [props.summaries]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.name, event.target.value)
        const currentData = Object.assign(data);
        // @ts-ignore
        currentData[event.target.name] = event.target.value;
        setData(currentData);
    };

    const handleSummariesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const dataToUpdate = Object.assign(data);
        dataToUpdate.summaries = summaries;

        dataToUpdate.summaries[event.target.name] = event.target.value;
        setData(dataToUpdate);
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleSave();
        }
    };

    const handleSave = () => {
        setEditMode(false);

        // Check if things have changed.
        if (Object.keys(data).length === 0) {
            return;
        }

        props.onSave(data);
    };

    return (
        <Card className={classes.card} style={{height: '100%'}}>
            <CardHeader
                title={props.title}
                className={props.headerStyle === 'secondary' ? classes.cardHeaderSecondary : classes.cardHeader}
                action={
                    props.tableData.filter(element => element.editable).length !== 0 && (
                        <ButtonEditOkCancel
                            onEdit={() => setEditMode(true)}
                            onCancel={() => setEditMode(false)}
                            onSave={handleSave}
                            editMode={editMode}
                            headerStyle={props.headerStyle}
                        >
                        </ButtonEditOkCancel>
                    )
                }
            >
            </CardHeader>
            <CardContent className={classes.root}>
                <DetailsTable
                    data={props.tableData}
                    editMode={editMode}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                />
            </CardContent>
            {props.summaries && Object.keys(props.summaries).length > 0
                ? <Button size="small" className={classes.margin} onClick={() => setSummariesOpen(true)}>
                    Zusammenfassungen anzeigen
                </Button>
                : <Button size="small" className={classes.margin} startIcon={<AddCircleIcon/>} onClick={() => setSummariesOpen(true)}>
                    Zusammenfassung hinzufügen
                </Button>
            }
            {props.cardActions && (
                <CardActions>
                    {(props.cardActions)}
                </CardActions>
            )}
            <Dialog
                fullScreen
                open={summariesOpen}
                onClose={() => setSummariesOpen(false)}
                // @ts-ignore
                // TransitionComponent={Transition}
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            edge='start'
                            onClick={() => setSummariesOpen(false)}
                            aria-label='close'
                        >
                            <CloseIcon/>
                        </IconButton>
                        <Typography variant='h6' className={classes.title}>
                            Zusammenfassungen
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div className={classes.rootDialog}>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <EditableTextCard
                                data={summaries}
                                name={'de'}
                                title={'Deutsch'}
                                onChange={handleSummariesChange}
                                onSave={handleSave}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <EditableTextCard
                                data={summaries}
                                name={'en'}
                                title={'Englisch'}
                                onChange={handleSummariesChange}
                                onSave={handleSave}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <EditableTextCard
                                data={summaries}
                                name={'nl'}
                                title={'Niederländisch'}
                                onChange={handleSummariesChange}
                                onSave={handleSave}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <EditableTextCard
                                data={summaries}
                                name={'fr'}
                                title={'Französisch'}
                                onChange={handleSummariesChange}
                                onSave={handleSave}
                            />
                        </Grid>
                    </Grid>
                </div>
            </Dialog>
        </Card>
    );
}
