import React, {useState} from 'react';
import TextField from '@material-ui/core/TextField';
import {CardHeader} from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import {makeStyles} from '@material-ui/core/styles';
import ButtonEditOkCancel from '../Partials/ButtonEditOkCancel';
import Render from '../../../helpers/Render';
import ThemeHelper from '../../../helpers/ThemeHelper';
import EditableTextCardProps from '../../../interfaces/props/EditableTextCardProps';
import DebugHint from '../Partials/DebugHint';

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: '100%',
        flexGrow: 1,
    },
    card: {
        maxWidth: '100%',
        flexGrow: 1,
        marginTop: 16,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    cardHeader: {
        backgroundColor: ThemeHelper.getColor(theme, theme.palette.cardHeader.backgroundColor),
        color: ThemeHelper.getColor(theme, theme.palette.cardHeader.color),
    },
}));

export default function EditableTextCard(props: EditableTextCardProps) {
    const [editMode, setEditMode] = useState(false);
    const classes = useStyles();
    const initialValue = props.data ? props.data[props.name] : '';

    const handleSave = async (): Promise<void> => {
        await props.onSave();
        setEditMode(false);
    }

    const handleCancel = async (): Promise<void> => {
        if (props.data) {
            props.data[props.name] = initialValue;
        }
        setEditMode(false);
    }

    return (
        <>
            <Card className={classes.card}>
                <CardHeader
                    title={props.title}
                    className={classes.cardHeader}
                    action={
                        <>
                            <DebugHint id={props.name}/>
                            <ButtonEditOkCancel
                                onEdit={() => setEditMode(true)}
                                onCancel={handleCancel}
                                onSave={handleSave}
                                editMode={editMode}>
                            </ButtonEditOkCancel>
                        </>
                    }
                />
                <CardContent className={classes.root}>
                    {editMode
                        ? (
                            <TextField
                                id={props.name}
                                multiline
                                rows={10}
                                defaultValue={props.data ? props.data[props.name] : ''}
                                variant='outlined'
                                name={props.name}
                                onChange={props.onChange}
                                style={{width: '100%'}}
                            />
                        )
                        : (
                            <div style={{whiteSpace: 'pre-line'}}>{props.data && props.data[props.name] ? props.data[props.name] : Render.defaultValue()}</div>
                        )
                    }
                </CardContent>
            </Card>
        </>
    );
}
