import React, {useEffect, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import BaseTable from '../../UI/Tables/BaseTable';
import TenantService from '../../../services/TenantService';
import Formatter from '../../../helpers/Formatter';
import BooleanYesNoTypes from '../../../enums/BooleanYesNoTypes';
import TenantsTableProps from '../../../interfaces/props/TenantsTableProps';
import Tenant from '../../../interfaces/models/Tenant';
import TableData from '../../../interfaces/TableData';
import {TableDataEditableTypes} from '../../../interfaces/enums/TableDataEditableTypes';
import {IdMatchParam} from '../../../interfaces/matchs/IdMatchParam';
import {TableDataTypes} from "../../../interfaces/enums/TableDataTypes";
import {TableDataAccountTypes} from "../../../interfaces/enums/TableDataAccountTypes";

export default function TenantsTable(props: TenantsTableProps) {
    const [tableData, setTableData] = useState<Tenant[]>([] as Tenant[]);
    const match = useRouteMatch<IdMatchParam>();

    useEffect(() => {
        setTableData(props.tenants ?? []);
    }, [props.tenants]);

    const formatAccountName = (row: any): string => {
        return row.account ? row.account.name : '';
    };

    const columns: TableData[] = [
        {
            title: 'ID',
            field: 'id',
            editable: TableDataEditableTypes.never,
            filtering: false,
            width: '65px',
        },
        {
            title: 'Account',
            id: 'accountId',
            field: 'account',
            render: (row: any) => formatAccountName(row),
            type: TableDataTypes.accountSelect,
            accountType: TableDataAccountTypes.all,
        },
        {
            title: 'Rank',
            field: 'rank',
        },
        {
            title: 'Mietfläche',
            field: 'rentalSpace',
            type: TableDataTypes.squareMeter,
            render: (row: any) => Formatter.formatSquareMeters(row ? row.rentalSpace : null),
        },
        {
            title: 'Mietende',
            field: 'rentalEndDate',
            render: (row: any) => Formatter.formatDate(row.rentalEndDate),
            type: TableDataTypes.date,
        },
        {
            title: 'Mieteinnahmen/Jahr',
            field: 'rentalIncome',
            type: TableDataTypes.currency,
            render: (row: any) => Formatter.formatCurrency(row ? row.rentalIncome : null),
        },
        {
            title: 'automatische Verlängerung',
            field: 'extensionOption',
            render: (row: any) => Formatter.formatBooleanYesNo(row ? row.extensionOption : 0),
            lookup: BooleanYesNoTypes.getTranslations(),
        },
        {
            title: 'Option',
            field: 'option',
        },
    ];

    const handleAdd = (newData: Tenant) => {
        newData.projectId = props.projectId;
        newData.realEstatePropertyId = parseInt(match.params.id);
        // @ts-ignore
        newData.extensionOption = newData.extensionOption === 'true';
        return TenantService.create(newData);
    }

    const handleUpdate = (updatedData: Tenant) => {
        // @ts-ignore
        updatedData.extensionOption = updatedData.extensionOption === 'true';
        // @ts-ignore
        return TenantService.update(updatedData.id, updatedData);
    };

    const handleDelete = (data: Tenant) => {
        // @ts-ignore
        return TenantService.delete(data.id);
    };

    return (
        <BaseTable
            tableName='tenantsTable'
            columns={columns}
            data={tableData}
            enableRowAdd={true}
            onAdd={handleAdd}
            onUpdate={handleUpdate}
            onDelete={handleDelete}
            title='Mieter'
            objects='Mieter'
            filtering={false}
            search={false}
        />
    );
}
