import React, {useEffect} from 'react';
import Helper from '../../helpers/Helper';
import PageNotFoundProps from '../../interfaces/props/PageNotFoundProps';

export default function PageNotFound(props: PageNotFoundProps) {
    const {setMenuItems, setBreadcrumbItems} = props;

    useEffect(() => {
        setMenuItems!([]);
        setBreadcrumbItems!([]);
        Helper.setPageTitle('Seite nicht gefunden');
    }, [setMenuItems, setBreadcrumbItems]);

    return (
        <div>
            <h1>Seite nicht gefunden</h1>
        </div>
    );
}
