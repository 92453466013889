import React, {useEffect, useState} from 'react';
import {Redirect, useRouteMatch} from 'react-router-dom';
import Loader from '../../UI/Loader';
import Divider from '@material-ui/core/Divider';
import {makeStyles} from '@material-ui/core/styles';
import DeleteEntry from '../../UI/Partials/DeleteEntry';
import Helper from '../../../helpers/Helper';
import LandRegisterService from '../../../services/LandRegisterService';
import LandRegister from '../../../interfaces/models/LandRegister';
import {IdMatchParam} from '../../../interfaces/matchs/IdMatchParam';
import LandRegisterInscriptionCard from './LandRegisterInscriptionCard';
import Grid from '@material-ui/core/Grid';
import LandRegisterInventoryCard from './LandRegisterInventoryCard';
import LandRegisterCurrentOwnershipCard from './LandRegisterCurrentOwnershipCard';
import LandRegisterUsufructAndRestrictionCard from './LandRegisterUsufructAndRestrictionCard';
import LandRegisterMortgageCard from './LandRegisterMortgageCard';
import LandRegisterOtherCard from './LandRegisterOtherCard';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    divider: {
        marginTop: 16,
        marginBottom: 16,
    },
    title: {
        color: theme.palette.grey.A100,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        marginTop: 15,
        fontWeight: 'bold',
    }
}));

export default function LandRegisterDetailPage(props: any) {
    const classes = useStyles();
    const {setMenuItems, setBreadcrumbItems} = props;
    const [data, setData] = useState<LandRegister>({} as LandRegister);
    const [isLoading, setIsLoading] = useState(false);
    const [navigateToRealEstateProperty, setNavigateToRealEstateProperty] = useState(false);
    const match = useRouteMatch<IdMatchParam>();

    useEffect(() => {
        setMenuItems([]);
    }, [setMenuItems]);

    useEffect(() => {
        setIsLoading(true);
        LandRegisterService.get(parseInt(match.params.id, 10)).then(landRegister => {
            setData(landRegister);
            setIsLoading(false);
            if (landRegister.realEstateProperty) {
                setBreadcrumbItems([
                    {
                        type: 'link',
                        link: '/projects/',
                        name: 'Projekte',
                    },
                    {
                        type: 'link',
                        link: '/project/' + landRegister.realEstateProperty.projectId + '/',
                        name: 'Projekt #' + landRegister.realEstateProperty.projectId,
                    },
                    {
                        type: 'link',
                        link: '/real-estate-property/' + landRegister.realEstatePropertyId + '/',
                        name: 'Objekt #' + landRegister.realEstatePropertyId,
                    },
                    {
                        type: 'typography',
                        name: 'Grundbuch - ' + landRegister.title,
                    },
                ]);
            }
        }).catch(() => {
            setIsLoading(false);
        });
    }, [match.params.id, setBreadcrumbItems]);

    const handleSave = (updatedData: LandRegister): void => {
        setData({
            ...data,
            ...updatedData,
        });

        if (data.id) {
            LandRegisterService.updateFromTable(data.id, {
                ...updatedData
            }).then(result => {
                setData(result);
            }).catch(() => {
            });
        }
    };

    const handleDelete = () => {
        LandRegisterService.delete(parseInt(match.params.id, 10)).then(() => {
            setNavigateToRealEstateProperty(true);
        });
    }

    if (isLoading) {
        return <Loader open={isLoading}/>
    }

    if (navigateToRealEstateProperty) {
        return <Redirect to={`/real-estate-property/${data.realEstatePropertyId}`} push={true}/>
    }

    data.title
        ? Helper.setPageTitle('Grundbuch - ' + data.title)
        : Helper.setPageTitle('Grundbuch #' + match.params.id);

    return (
        <>
            <DeleteEntry
                onDelete={handleDelete}
                title={'Grundbuch löschen?'}
                text={'Bist du dir sicher, dass du dieses Grundbuch und alle dazugehörigen Daten löschen möchtest?'}
            />
            <Divider className={classes.divider}/>
            <Grid container spacing={3} alignItems='stretch'>
                <Grid item sm={12}>
                    <Typography variant='h4' className={classes.title}>{data.title}</Typography>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <LandRegisterInscriptionCard
                        landRegister={data}
                        onSave={(landRegister: LandRegister) => handleSave(landRegister)}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <LandRegisterInventoryCard
                        landRegister={data}
                        onSave={(landRegister: LandRegister) => handleSave(landRegister)}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3} alignItems='stretch'>
                <Grid item sm={6} xs={12}>
                    <LandRegisterCurrentOwnershipCard
                        landRegister={data}
                        onSave={(landRegister: LandRegister) => handleSave(landRegister)}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <LandRegisterUsufructAndRestrictionCard
                        landRegister={data}
                        onSave={(landRegister: LandRegister) => handleSave(landRegister)}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3} alignItems='stretch'>
                <Grid item sm={6} xs={12}>
                    <LandRegisterMortgageCard
                        landRegister={data}
                        onSave={(landRegister: LandRegister) => handleSave(landRegister)}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <LandRegisterOtherCard
                        landRegister={data}
                        onSave={(landRegister: LandRegister) => handleSave(landRegister)}
                    />
                </Grid>
            </Grid>
        </>
    );
}
