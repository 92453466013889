import {createMuiTheme} from '@material-ui/core/styles';
import {Theme} from '@material-ui/core/styles/createMuiTheme';
import {PaletteType} from '@material-ui/core';

export default {
    createTheme: (paletteType: PaletteType = 'light'): Theme => {
        return createMuiTheme({
            typography: {
                fontSize: 13,
                fontFamily: '"Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", sans-serif',
            },
            palette: {
                type: paletteType,
                primary: {
                    main: '#86AC48',
                    dark: '#ffffff',
                },
                secondary: {
                    A400: '#5d7832',
                    main: '#3968af',
                },
                info: {main: '#626262'},
                error: {main: '#dc004d', A100: '#dc004d'},
                delete: '#dc004d',
                success: {main: '#7cb342'},
                appBar: {
                    main: '#86AC48',
                    dark: 'rgb(66, 66, 66)',
                },
                cardHeader: {
                    backgroundColor: {
                        main: '#F6F7F9',
                        dark: '#565656',
                    },
                    color: {
                        main: '#626262',
                        dark: '#ffffff',
                    },
                },
                chart: {
                    title: {
                        main: '#333333',
                        dark: '#ffffff',
                    },
                    subtitle: {
                        main: 'rgb(102, 102, 102)',
                        dark: '#ffffff',
                    },
                },
                grey: {
                    A100: '#626262',
                },
                tableOdd: {
                    main: '#F6F7F9',
                    dark: '#303030',
                },
                customBackground: {
                    lightGrey: '#F6F7F9',
                    darkGrey: '#565656',
                    lightGreen: '#C8DAAC',
                    green: '#86AC48',
                },
            },
            overrides: {
                MuiTooltip: {
                    tooltip: {
                        fontSize: 13,
                        color: '#ffffff',
                        maxWidth: 500,
                    },
                },
            },
        });
    },
}
