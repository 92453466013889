import React, {useEffect, useState} from 'react';
import SyncIcon from '@material-ui/icons/Sync';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import SendButtonProps from '../../../interfaces/props/SendButtonProps';

const useStyles = makeStyles(theme => ({
    button: {
        background: theme.palette.success.main,
        color: 'white'
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    // @ts-ignore
    return <Slide direction='up' ref={ref} {...props} />;
});

export default function SendButton(props: SendButtonProps) {
    const classes = useStyles();
    const [sendMode, setSendMode] = useState(true);
    const [modalOpen, setModalOpen] = React.useState(false);

    useEffect(() => {
        setSendMode(props.sendMode);
    }, [props.sendMode]);

    const handleSend = () => {
        setModalOpen(false);
        props.onSend();
    }

    const handleClickDeleteOpen = () => {
        setModalOpen(true);
    };

    const handleDeleteClose = () => {
        setModalOpen(false);
    };

    return (
        <>
            <Button
                variant='contained'
                className={classes.button}
                startIcon={<SyncIcon/>}
                onClick={handleClickDeleteOpen}
                disabled={!sendMode}
            >
                {props.buttonText || 'Senden'}
            </Button>
            <Dialog
                open={modalOpen}
                // @ts-ignore
                TransitionComponent={Transition}
                keepMounted
                onClose={handleDeleteClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>{props.title || 'Daten senden?'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        {props.text || 'Bist du dir sicher, dass du die Daten senden möchtest?'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteClose} color='primary' autoFocus>
                        Abbrechen
                    </Button>
                    <Button
                        variant='contained'
                        onClick={handleSend}
                        className={classes.button}
                        startIcon={<SyncIcon/>}
                    >
                        {props.buttonText || 'Senden'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
