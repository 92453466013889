import React, {ReactElement} from 'react';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineDot from '@material-ui/lab/TimelineDot';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import MuiTimelineItem from '@material-ui/lab/TimelineItem';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import EventTimelineLastItemProps from '../../interfaces/props/EventTimelineLastItemProps';

const useStyles = makeStyles((theme) => ({
    infinity: {
        backgroundColor: theme.palette.grey.A100,
    },
    icon: {
        height: 30,
        width: 30,
        '& svg': {
            height: 30,
            width: 30,
            fontSize: 16,
        },
    },
}));

const TimelineItem = withStyles({
    missingOppositeContent: {
        '&:before': {
            display: 'none'
        }
    }
})(MuiTimelineItem);

export default function EventTimelineLastItem(props: EventTimelineLastItemProps): ReactElement {
    const classes = useStyles();

    return (
        <TimelineItem>
            <TimelineSeparator>
                <TimelineDot className={classes.infinity}>
                    <div className={classes.icon}>
                        {
                            props.icon
                                ? props.icon
                                : (<AllInclusiveIcon/>)
                        }
                    </div>
                </TimelineDot>
            </TimelineSeparator>
        </TimelineItem>
    );
}
