import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ProjectService from "../../../services/ProjectService";
import {useRouteMatch} from "react-router-dom";
import {IdMatchParam} from "../../../interfaces/matchs/IdMatchParam";
import Project from "../../../interfaces/models/Project";
import RealEstatePropertiesTable from "./RealEstatePropertiesTable";
import RealEstateProperty from "../../../interfaces/models/RealEstateProperty";
import RealEstatePropertyOriginationData from "./RealEstateProperyOriginationData";
import ProjectOriginationData from "./ProjectOriginationData";
import FinancialProductCard from "../../FinancingEntities/FinancialProductCard";
import PlannedMeasures from "../PlannedMeasures";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import FutureOriginationData from "./FutureOriginationData";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.common.white,
        },
    },
    backButton: {
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
        color: theme.palette.common.white,
        backgroundColor: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
            color: theme.palette.common.white,
        },
    },
    completed: {
        display: 'inline-block',
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    step: {
        color: 'white',
    },
    active: {
        color: 'white',
    }, //needed so that the &$active tag works
}));

function getSteps() {
    return [
        'Real Estate Properties anlegen',
        'Property Detail-Daten',
        'Projektdaten',
        'Prognostizierte Jahresrechnung',
        'Financial Product erstellen',
    ];
}

function getRealEstatePropertyContent(realEstateProperties: RealEstateProperty[] | undefined) {
    return (
        <>
            <p>Es muss mindestens ein Objekt mit Adressdaten angelegt sein. Sollte es sich um ein Portfolio handeln,
            lege bitte alle Objekte in der Liste an.</p>
            <RealEstatePropertiesTable realEstateProperties={realEstateProperties}/>
        </>
    );
}

function getRealEstatePropertyDetailContent(realEstateProperties: RealEstateProperty[] | undefined) {
    return (
        <>
            <p>Dies sind die Mindestdaten, die für die Projektdetailseite gebraucht werden.</p>
            <RealEstatePropertyOriginationData realEstateProperties={realEstateProperties}/>
        </>
    );
}

function getProjectDetailContent(project: Project | undefined) {
    return (
        <>
            <ProjectOriginationData project={project}/>
        </>
    );
}

function getProjectFutureDetailsContent(project: Project | undefined) {
    return <FutureOriginationData project={project} />
}

function getFinancialProductCreationContent(project: Project | undefined) {
    if (project && project.plannedMeasures) {
        const financingPlannedMeasure = project?.plannedMeasures.filter(plannedMeasure => {
            return plannedMeasure.type === 'financing';
        });
        const exporoCapital = financingPlannedMeasure && financingPlannedMeasure[0] && financingPlannedMeasure[0].financingEntities && financingPlannedMeasure[0].financingEntities.filter(entity => {
            return entity.type === 'exporoCapital';
        });

        if (exporoCapital && exporoCapital.length > 0) {
            return <>
                <p>Es muss zumindest das Exporo-Kapital angelegt sein. Sobald alle Daten ausgefüllt sind, kannst du ein Financial Product erstellen.</p>
                <PlannedMeasures plannedMeasures={project.plannedMeasures} />
                <FinancialProductCard financingEntity={exporoCapital[0]} />
            </>
        }
    }

    return <>
        <PlannedMeasures plannedMeasures={project ? project.plannedMeasures : null} />
    </>
}

function getStepContent(step: number, projectData: Project) {
    switch (step) {
        case 0:
            return getRealEstatePropertyContent(projectData.realEstateProperties);
        case 1:
            return getRealEstatePropertyDetailContent(projectData.realEstateProperties);
        case 2:
            return getProjectDetailContent(projectData);
        case 3:
            return getProjectFutureDetailsContent(projectData);
        case 4:
            return getFinancialProductCreationContent(projectData);
        default:
            return 'Unknown step';
    }
}

export default function FinalizeProject() {
    const classes = useStyles();
    const match = useRouteMatch<IdMatchParam>();

    const [projectData, setProjectData] = useState({} as Project);
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({} as {[key:number]: boolean;});
    const steps = getSteps();

    useEffect(() => {
        ProjectService.get(parseInt(match.params.id, 10)).then(response => {
            setProjectData(response);
        });
    }, [match.params.id]);

    useEffect(() => {
        setCompleted({
            0: !!projectData.realEstateProperties && projectData.realEstateProperties.length > 0,
            1: projectData.secondStepCompleted,
            2: projectData.thirdStepCompleted,
            3: projectData.fourthStepCompleted,
            4: projectData.fifthStepCompleted,
        });
    }, [projectData]);

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).filter((item, index) => {
            return item[index];
        }).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                  // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step: number) => () => {
        setActiveStep(step);
    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };

    return (
        <div className={classes.root}>
            <Stepper nonLinear activeStep={activeStep}>
                {steps.map((label, index) => (
                    <Step key={label} style={{color: 'white'}} classes={{
                        root: classes.step,
                        completed: classes.completed,
                    }}>
                        <StepButton onClick={handleStep(index)} completed={completed[index]}>
                            {label}
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
            <div>
                {allStepsCompleted() ? (
                    <div>
                        <Typography className={classes.instructions}>
                            All steps completed - you&apos;re finished
                        </Typography>
                        <Button onClick={handleReset}>Reset</Button>
                    </div>
                ) : (
                    <>
                        <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            className={activeStep === 0 ? classes.button : classes.backButton}
                            startIcon={<ArrowBackIosIcon/>}
                        >
                            Zurück
                        </Button>
                        <Button
                            disabled={isLastStep()}
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                            className={classes.button}
                            endIcon={<ArrowForwardIosIcon/>}
                        >
                            Weiter
                        </Button>
                        <Typography className={classes.instructions}>{getStepContent(activeStep, projectData)}</Typography>
                    </>
                )}
            </div>
        </div>
    );
}
