export default {
    get: (itemName: string, defaultValue: string = ''): string | null => {
        return localStorage.hasOwnProperty(itemName) ? localStorage.getItem(itemName) : defaultValue;
    },
    set: (itemName: string, value: string): void => {
        localStorage.setItem(itemName, value);
    },
    remove: (itemName: string): void => {
        localStorage.removeItem(itemName);
    },
}
