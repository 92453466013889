import React, {useState} from 'react';
import clsx from 'clsx';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {Link as RouterLink, useLocation, useHistory, Link} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import {useAppContext} from '../../../libs/contextLib';
import UserService from '../../../services/UserService';
import Navigation from '../../../navigation/Navigation';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import SidebarHelper from '../../../helpers/SidebarHelper';
import ThemeHelper from '../../../helpers/ThemeHelper';
import SidebarProps from '../../../interfaces/props/SidebarProps';
import UserHelper from '../../../helpers/UserHelper';

const drawerWidth: number = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        background: ThemeHelper.getColor(theme, theme.palette.appBar),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color: '#ffffff',
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    title: {
        flexGrow: 1,
        color:theme.palette.grey.A100,
    },
    button: {
        color: '#fff',
    },
    spacer: {
        flex: '1 1 auto',
    },
    logo: {
        height: 65,
    },
    logoOutline: {
        maxHeight: '65px',
        margin: '0 auto',
    },
    list: {
        paddingTop: '0px',
    },
    slackLink: {
        position: 'fixed',
        bottom: 0,
        textAlign: 'center',
        width: drawerWidth,
    },
    slackLinkText: {
        fontSize: 11,
        color: '#888888',
    },
    slackLinkIcon: {
        height: 13,
        marginLeft: 50,
        marginBottom: -2,
    },
}));

export default function Sidebar(props: SidebarProps) {
    const classes = useStyles();
    let location = useLocation();
    const { setIsAuthenticated } = useAppContext();

    const theme = useTheme();
    const [drawerOpen, setDrawerOpen] = useState(SidebarHelper.openStatus());

    const history = useHistory();

    const handleDrawerOpen = (): void => {
        setDrawerOpen(true);
        SidebarHelper.setOpenStatus('true');
    };

    const handleDrawerClose = (): void => {
        setDrawerOpen(false);
        SidebarHelper.setOpenStatus('false');
    };

    async function handleLogout(): Promise<void> {
        await UserService.logout();

        setIsAuthenticated(false);
        history.push('/login');
    }

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <AppBar
                position='fixed'
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: drawerOpen,
                })}
                elevation={2}
            >
                <Toolbar>
                    <IconButton
                        color='inherit'
                        aria-label='open drawer'
                        onClick={handleDrawerOpen}
                        edge='start'
                        className={clsx(classes.menuButton, drawerOpen && classes.hide)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <span className={classes.spacer} />
                    {props.menuItems.map(item => {
                        return (
                            <Button
                                key={item.title}
                                style={{
                                    color: 'white',
                                }}
                                to={item.to}
                                component={RouterLink}>
                                {item.title}
                            </Button>
                        );
                    })}
                    <Link to={'/'} className={classes.logo}>
                        <img src={`${process.env.PUBLIC_URL}/images/icon-bestand-quer.svg`} alt={'Exporo Bestand'}  className={classes.logo}/>
                    </Link>
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant='persistent'
                anchor='left'
                open={drawerOpen}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <img src={`${process.env.PUBLIC_URL}/images/icon-bestand-outline.svg`} alt='' className={classes.logoOutline} />
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </div>
                <Divider />
                <List className={classes.list}>
                    {Navigation.map((item, index) => {
                        switch(item.type) {
                            case 'divider':
                                return (
                                    <Divider key={index}/>
                                )
                            case 'link':
                                if (item.permission) {
                                    if (!props.user
                                        ||
                                        !UserHelper.hasPermission(props.user, item.permission)
                                    ) {
                                        return null;
                                    }
                                }

                                return (
                                    <ListItem
                                        button
                                        selected={location.pathname === item.link}
                                        // @ts-ignore
                                        component={RouterLink}
                                        to={item.link}
                                        key={item.link}
                                    >
                                        <ListItemIcon>{item.icon}</ListItemIcon>
                                        <ListItemText>{item.name}</ListItemText>
                                    </ListItem>
                                )
                            case 'click':
                                return (
                                    <ListItem
                                        button
                                        key={item.name}
                                    >
                                        <ListItemIcon>{item.icon}</ListItemIcon>
                                        <ListItemText
                                            onClick={handleLogout}
                                            >
                                            {item.name}
                                        </ListItemText>
                                    </ListItem>
                                )
                            default:
                                return null;
                        }
                    })}
                </List>
                <div className={classes.slackLink}>
                    <List>
                        <ListItem button component='a' href='https://exporo.slack.com/archives/C012ZP2ELKF' target='slack'>
                            <ListItemText classes={{primary:classes.slackLinkText}}><LiveHelpIcon className={classes.slackLinkIcon}/> Slack Channel</ListItemText>
                        </ListItem>
                    </List>
                </div>
            </Drawer>
            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: drawerOpen,
                })}
            >
                <div className={classes.drawerHeader} />
                {props.children}
            </main>
        </div>
    );
}
