import React, {useEffect, useState} from 'react';
import {Redirect, useRouteMatch} from 'react-router-dom';
import FinancingEntityService from '../../services/FinancingEntityService';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FinancialConditionsCard from './FinancialConditionsCard';
import BondCalculationCard from './BondCalculationCard';
import Loader from '../UI/Loader';
import DeleteEntry from '../UI/Partials/DeleteEntry';
import GeneralCard from './GeneralCard';
import {useAppContext} from '../../libs/contextLib';
import Helper from '../../helpers/Helper';
import FinancialProductCard from "./FinancialProductCard";

export default function FinancingEntityDetailPage(props) {
    const {selectOptions} = useAppContext();
    const {setBreadcrumbItems} = props;
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [types, setTypes] = useState({});
    const [navigateToProject, setNavigateToProject] = useState(false);
    const match = useRouteMatch();

    useEffect(() => {
        setLoading(true);

        FinancingEntityService.get(match.params.financingEntityId).then(entity => {
            setData(entity);
            setLoading(false);

            setBreadcrumbItems([
                {
                    type: 'link',
                    link: '/projects/',
                    name: 'Projekte',
                },
                {
                    type: 'link',
                    link: '/project/' + match.params.id + '/',
                    name: 'Projekt #' + match.params.id,
                },
                {
                    type: 'typography',
                    name: 'Financing Entity #' + entity.id,
                },
            ]);
        }).catch(() => {
            setLoading(false);
        });

        Helper.setPageTitle('Finanzierungsstruktur #' + match.params.financingEntityId);

    }, [match.params.financingEntityId, match.params.id, setBreadcrumbItems]);

    useEffect(() => {
        if (Object.keys(selectOptions).length === 0) {
            return;
        }

        setTypes(selectOptions.financingEntity.types);
    }, [selectOptions]);

    const handleDelete = () => {
        FinancingEntityService.delete(match.params.financingEntityId).then(() => {
            setNavigateToProject(true);
        }).catch(() => {

        });
    }

    if (navigateToProject) {
        return <Redirect to={`/project/${match.params.id}`} push={true} />
    }

    return (
        <>
            <DeleteEntry
                onDelete={handleDelete}
                title={'Financing Entity löschen?'}
                text={'Bist du dir sicher, dass du diese Financing Entity und alle dazugehörigen Daten löschen möchtest?'}
            />

            <Typography variant='h4' gutterBottom>
                {types[data.type]}
            </Typography>

            {(data.plannedMeasure && data.plannedMeasure.type === 'preFinancing') && (
                <Typography>
                    Hier werden bald Informationen über die Vorfinanzierung stehen. Falls ihr Details über die Vorfinanzierung haben möchtet, müsstet ihr im Moment noch zu Raphael gehen.
                </Typography>
            )}

            {(data.plannedMeasure && data.plannedMeasure.type === 'financing' && data.type === 'debt') && (
                <Typography>
                    Hier werden bald die Infos aus der hinterlegten Fremdkapital-Opportunity aus dem Salesforce Projekt übernommen.
                </Typography>
            )}

            {(data.plannedMeasure && data.plannedMeasure.type === 'financing' && data.type === 'exporoCapital') && (
                <Grid container spacing={3} alignItems='stretch'>
                    {Object.keys(data).length > 0 && (
                        <>
                            <Grid item xs={12} sm={6}>
                                <FinancialProductCard financingEntity={data}/>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <GeneralCard financingEntity={data}/>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <BondCalculationCard financingEntity={data}/>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FinancialConditionsCard financingEntity={data}/>
                            </Grid>
                        </>
                    )}
                </Grid>
            )}

            <Loader open={loading}/>
        </>
    );
}
