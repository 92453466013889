import React, {useEffect, useState} from 'react';
import Formatter from '../../../helpers/Formatter';
import EditableCard from '../../UI/Cards/EditableCard';
import Box from '@material-ui/core/Box';

export default function ProjectFinancialKeyFacts(props) {
    const {project} = props;
    const [data, setData] = useState({});

    useEffect(() => {
        setData(project);
    }, [project]);

    const hintOptionalRentIncrease = () => {
        return `<strong>Optionale Angabe.</strong><br>
                Wenn kein Wert eingetragen wird, wird die Standard Mietsteigerung 
                von ${Formatter.formatPercentage(data.rentIncreasePercentage)} verwendet.`;
    };

    const tableData = [
        {
            label: 'Vorfinanzierungsrücklage',
            value: data.prefinancingCosts,
            name: 'prefinancingCosts',
            editable: true,
            formatter: Formatter.formatCurrency,
            type: 'integerCurrency',
        },
        {
            label: 'Liquiditätsrücklage',
            value: data.cashReserve,
            name: 'cashReserve',
            editable: true,
            formatter: Formatter.formatCurrency,
            type: 'integerCurrency',
        },
        {
            label: 'Gesamtinvestition',
            value: data.totalProjectCost || 'wird berechnet',
            name: 'totalProjectCost',
            editable: false,
            formatter: Formatter.formatCurrency,
            hint: 'Kaufpreis inklusive NK, Liquidität & Vorfinanzierung',
        },
        {
            label: 'Standard Mietsteigerung pro Jahr (%)',
            value: data.rentIncreasePercentage,
            name: 'rentIncreasePercentage',
            editable: true,
            formatter: Formatter.formatPercentage,
            type: 'number',
            hint: 'Prognostizierte Jahresrechnung (10-Jahres-Durchschnitt) Mietsteigerung',
        },
        {
            label: 'erwarteter Nettoverkaufsfaktor nach Ende der Laufzeit',
            value: data.realestateSalesfactorTenYears,
            name: 'realestateSalesfactorTenYears',
            editable: true,
            formatter: Formatter.formatFactor,
            type: 'number',
        },
    ];

    for (let year = 2; year <= 10; year++) {
        const yearRow = data.runningTime >= year
            ? {
                label: `Mietsteigerung ${year}. Jahr (%)`,
                value: data['rentIncreasePercentageYear' + year],
                name: 'rentIncreasePercentageYear' + year,
                editable: true,
                formatter: Formatter.formatPercentage,
                type: 'number',
                hint: hintOptionalRentIncrease(),
              }
            : {
                label: `Mietsteigerung ${year}. Jahr (%)`,
                value: (<Box fontStyle="italic" fontSize={10}>Nach Ende der Laufzeit</Box>),
                name: 'rentIncreasePercentageYear' + year,
                editable: false,
            };
        tableData.push(yearRow);
    }

    const handleSave = (updatedData) => {
        console.log('UPDATED DATA IN KEYFACTS: ', updatedData);
        setData({
            ...data,
            ...updatedData,
        });

        props.onSave(updatedData);
    };

    return (
        <EditableCard
            tableData={tableData}
            onSave={handleSave}
            title={'Kennzahlen'}
            headerImage={props.imageUrl}
        />
    );
}
