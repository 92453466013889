import React, {useEffect, useState} from 'react';
import BaseTable from '../../UI/Tables/BaseTable';
import Formatter from '../../../helpers/Formatter';
import BooleanYesNoTypes from '../../../enums/BooleanYesNoTypes';
import TopTenantsTableProps from '../../../interfaces/props/TopTenantsTableProps';
import Tenant from '../../../interfaces/models/Tenant';
import TableData from '../../../interfaces/TableData';
import {TableDataEditableTypes} from '../../../interfaces/enums/TableDataEditableTypes';
import {TableDataTypes} from "../../../interfaces/enums/TableDataTypes";
import {TableDataAccountTypes} from "../../../interfaces/enums/TableDataAccountTypes";

export default function TopTenantsTable(props: TopTenantsTableProps) {
    const [tableData, setTableData] = useState<Tenant[]>([] as Tenant[]);

    useEffect(() => {
        setTableData(props.tenants);
    }, [props.tenants]);

    const formatAccountName = (row: any): string => {
        return row.account ? row.account.name : '';
    };

    const columns: TableData[] = [
        {
            title: 'ID',
            field: 'id',
            editable: TableDataEditableTypes.never,
            filtering: false,
            width: '65px',
        },
        {
            title: 'Account',
            id: 'accountId',
            field: 'account',
            render: (row: any) => formatAccountName(row),
            type: TableDataTypes.accountSelect,
            accountType: TableDataAccountTypes.all,
        },
        {
            title: 'Rank',
            field: 'rank',
        },
        {
            title: 'Mietfläche',
            field: 'rentalSpace',
            type: TableDataTypes.squareMeter,
            render: (row: any) => Formatter.formatSquareMeters(row ? row.rentalSpace : null),
        },
        {
            title: 'Mietende',
            field: 'rentalEndDate',
            render: (row: any) => Formatter.formatDate(row.rentalEndDate),
            type: TableDataTypes.date,
        },
        {
            title: 'Mieteinnahmen/Jahr',
            field: 'rentalIncome',
            type: TableDataTypes.currency,
            render: (row: any) => Formatter.formatCurrency(row ? row.rentalIncome : null),
        },
        {
            title: 'automatische Verlängerung',
            field: 'extensionOption',
            render: (row: any) => Formatter.formatBooleanYesNo(row ? row.extensionOption : 0),
            lookup: BooleanYesNoTypes.getTranslations(),
        },
        {
            title: 'Option',
            field: 'option',
        },
    ];

    return (
        <BaseTable
            tableName='topTenantsTable'
            columns={columns}
            data={tableData}
            disableUpdateAction={true}
            disableDeleteAction={true}
            title='Top Mieter'
            objects='Top Mieter'
            filtering={false}
            search={false}
        />
    );
}
