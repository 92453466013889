import React, {ReactElement, useEffect, useState} from 'react';
import Formatter from '../../../helpers/Formatter';
import AccountService from '../../../services/AccountService';
import EditableCard from '../../UI/Cards/EditableCard';
import ProjectCompanyStructureCardProps from '../../../interfaces/props/ProjectCompanyStructureCardProps';
import Account from '../../../interfaces/models/Account';
import Project from '../../../interfaces/models/Project';
import CardData from '../../../interfaces/CardData';
import {RenderTypes} from '../../../interfaces/enums/RenderTypes';

export default function ProjectCompanyStructureCard(props: ProjectCompanyStructureCardProps): ReactElement {
    const {project} = props;
    const [data, setData] = useState<Project>({} as Project);
    const [accounts, setAccounts] = useState<Account[] | []>([]);

    useEffect(() => {
        setData(project);
        getAccounts();
    }, [project]);

    const getAccounts = (): void => {
        AccountService.all().then(response => {
            setAccounts(response);
        }).catch(() => { });
    };

    const tableData: CardData[] = [
        {
            label: 'Asset Management',
            value: data.assetManagementCompany ? data.assetManagementCompany.name : null,
            id: data.assetManagementCompany ? data.assetManagementCompany : null,
            name: 'assetManagementCompanyId',
            type: RenderTypes.accountSelect,
            noneValue: '-- keine Angabe --',
            editable: true,
            formatter: () => Formatter.formatAccountLink(data.assetManagementCompany),
            editFormatter: Formatter.formatAccountSelectOption,
        },
        {
            label: 'Asset Management Beschreibung',
            value: data.assetManagementCompany ? data.assetManagementCompany.description : null,
            name: 'assetManagementCompany.description',
            editable: true,
        },
        {
            label: 'Property Management',
            value: data.propertyManagement ? data.propertyManagement.name : null,
            id: data.propertyManagement ? data.propertyManagement : null,
            name: 'propertyManagementId',
            type: RenderTypes.accountSelect,
            noneValue: '-- keine Angabe --',
            formatter: () => Formatter.formatAccountLink(data.propertyManagement),
            editFormatter: Formatter.formatAccountSelectOption,
            editable: true,
        },
        {
            label: 'Emittentin',
            value: data.emittent ? data.emittent.name : null,
            id: data.emittent ? data.emittent : null,
            name: 'emittentId',
            type: RenderTypes.accountSelect,
            noneValue: '-- keine Angabe --',
            formatter: () => Formatter.formatAccountLink(data.emittent),
            editFormatter: Formatter.formatAccountSelectOption,
            editable: true,
        },
        {
            label: 'Makler',
            value: data.broker ? data.broker.name : null,
            id: data.broker ? data.broker : null,
            name: 'brokerId',
            formatter: () => Formatter.formatAccountLink(data.broker),
            editFormatter: Formatter.formatAccountSelectOption,
            editable: false,
            hint: 'Dieses Feld wird von den TAMs in Salesforce bearbeitet.',
        },
        {
            label: 'Verkäufer',
            value: data.seller ? data.seller.name : null,
            id: data.seller ? data.seller : null,
            name: 'sellerId',
            formatter: () => Formatter.formatAccountLink(data.seller),
            editFormatter: Formatter.formatAccountSelectOption,
            editable: false,
            hint: 'Dieses Feld wird von den TAMs in Salesforce bearbeitet.',
        },
        {
            label: 'Vermittler Besitzgesellschaft',
            value: data.financialServicesInstitute ? data.financialServicesInstitute.name : null,
            id: data.financialServicesInstitute ? data.financialServicesInstitute : null,
            name: 'financialServicesInstituteId',
            type: RenderTypes.accountSelect,
            noneValue: '-- keine Angabe --',
            formatter: () => Formatter.formatAccountLink(data.financialServicesInstitute),
            editFormatter: Formatter.formatAccountSelectOption,
            editable: true,
        },
        {
            label: 'Gutachter',
            value: data.valuator ? data.valuator.name : null,
            id: data.valuator ? data.valuator : null,
            name: 'valuatorId',
            type: RenderTypes.accountSelect,
            noneValue: '-- keine Angabe --',
            formatter: () => Formatter.formatAccountLink(data.valuator),
            editFormatter: Formatter.formatAccountSelectOption,
            editable: true,
        },
        {
            label: 'Auditor',
            value: data.auditor ? data.auditor.name : null,
            id: data.auditor ? data.auditor : null,
            name: 'auditorId',
            type: RenderTypes.accountSelect,
            noneValue: '-- keine Angabe --',
            formatter: () => Formatter.formatAccountLink(data.auditor),
            editFormatter: Formatter.formatAccountSelectOption,
            editable: true,
        },
        {
            label: 'Supply Partner',
            value: data.supplyPartner ? data.supplyPartner.name : null,
            id: data.supplyPartner ? data.supplyPartner : null,
            name: 'supplyPartnerId',
            type: RenderTypes.accountSelect,
            noneValue: '-- keine Angabe --',
            formatter: () => Formatter.formatAccountLink(data.supplyPartner),
            editFormatter: Formatter.formatAccountSelectOption,
            editable: data.source === "supplyPartner",
            hint: 'Dieses Feld ist nur für Supply Partner Projekte editierbar.',
        },
    ];

    const getUpdatedAccounts = (updatedData: Project): Project => {
        const newData: Project = {} as Project;

        if (Object.keys(updatedData).includes('assetManagementCompanyId')) {
            newData.assetManagementCompany = accounts.find(account => account.id === updatedData.assetManagementCompanyId);
        }

        if (Object.keys(updatedData).includes('emittentId')) {
            newData.emittent = accounts.find(account => account.id === updatedData.emittentId);
        }

        if (Object.keys(updatedData).includes('financialServicesInstituteId')) {
            newData.financialServicesInstitute = accounts.find(account => account.id === updatedData.financialServicesInstituteId);
        }

        if (Object.keys(updatedData).includes('propertyManagementId')) {
            newData.propertyManagement = accounts.find(account => account.id === updatedData.propertyManagementId);
        }

        if (Object.keys(updatedData).includes('valuatorId')) {
            newData.valuator = accounts.find(account => account.id === updatedData.valuatorId);
        }

        if (Object.keys(updatedData).includes('auditorId')) {
            newData.auditor = accounts.find(account => account.id === updatedData.auditorId);
        }

        if (Object.keys(updatedData).includes('supplyPartnerId')) {
            newData.supplyPartner = accounts.find(account => account.id === updatedData.supplyPartnerId);
        }

        if (Object.keys(updatedData).includes('assetManagementCompany.description')) {
            if (!newData.assetManagementCompany) {
                newData.assetManagementCompany = data.assetManagementCompany;
            }

            if (newData.assetManagementCompany && updatedData.assetManagementCompany) {
                newData.assetManagementCompany.description = updatedData.assetManagementCompany.description;
            }
        }

        return newData;
    };

    const handleSave = (updatedData: Project): void => {
        setData({
            ...data,
            ...updatedData,
            ...getUpdatedAccounts(updatedData),
        });

        if (Object.keys(updatedData).includes('assetManagementCompany.description')) {
            const accountId = updatedData.assetManagementCompanyId ?? data.assetManagementCompanyId;
            // @ts-ignore
            const assetManagementCompanyDescription = updatedData['assetManagementCompany.description'];
            if (accountId && assetManagementCompanyDescription) {
                AccountService.updateFromTable(accountId, {
                    description: assetManagementCompanyDescription,
                }).then(() => {
                    getAccounts();
                });
            }
        }

        props.onSave(updatedData);
    };

    return (
        <EditableCard
            tableData={tableData}
            onSave={handleSave}
            title={'Firmenstruktur'}
        />
    );
}
