import React, {useEffect, useState} from 'react';
import Formatter from '../../../helpers/Formatter';
import EditableCard from '../../UI/Cards/EditableCard';
import {useAppContext} from '../../../libs/contextLib';

export default function ProjectCard(props) {
    const {selectOptions} = useAppContext();
    const [countries, setCountries] = useState({});
    const [data, setData] = useState({});
    const [stageTypes, setStageTypes] = useState({});
    const [riskClasses, setRiskClasses] = useState({});
    const {project} = props;

    useEffect(() => {
        if (Object.keys(selectOptions).length === 0) {
            return;
        }

        setCountries(selectOptions.realEstateProperty.countries.available);
        setStageTypes(selectOptions.project.stageTypes);
        setRiskClasses(selectOptions.project.riskClasses);
    }, [selectOptions]);

    useEffect(() => {
        setData(project);
    }, [project]);

    const formatCountries = (row) => {
        if (!row) {
            return '';
        }

        return [...new Set(row.map(item => item.country ? countries[item.country] : ''))].join(', ');
    }

    const userSelectOptions = data => {
        return Formatter.formatSelectOptions(data);
    };

    const stageSelectOptions = () => {
        return Formatter.formatSelectOptionsFromObject(stageTypes);
    };

    const tableData = [
        {
            label: 'Projektname',
            value: data.name,
            name: 'name',
            id: data.name,
            editable: true,
        },
        {
            label: 'Stage',
            value: data.stage,
            name: 'stage',
            formatter: stage => Formatter.formatStage(stage, stageTypes),
            type: 'select',
            selectOptions: stageSelectOptions(),
            id: data.stage,
            editable: true,
        },
        {
            label: 'Risikoklasse',
            value: data.riskClass,
            name: 'riskClass',
            formatter: Formatter.formatRiskClass,
            type: 'select',
            selectOptions: Formatter.formatSelectOptionsFromObject(riskClasses),
            id: data.riskClass,
            editable: true,
        },
        {
            label: 'Investment Fokus',
            value: data.investmentFocus,
            name: 'investmentFocus',
            editable: false,
            hint: 'Dieses Feld wird von den TAMs in Salesforce bearbeitet.',
        },
        {
            label: 'Buchungskreis',
            value: data.bkr,
            name: 'bkr',
            editable: true,
        },
        {
            label: 'Asset Manager',
            value: data.assetManager ? data.assetManager.fullName : '',
            id: data.assetManagerId,
            name: 'assetManagerId',
            type: 'select',
            selectOptions: userSelectOptions(props.assetUsers),
            noneValue: '-- keine Angabe --',
            editable: true,
        },
        {
            label: 'Transaction Manager',
            value: data.transactionManager ? data.transactionManager.fullName : '',
            id: data.transactionManagerId,
            name: 'transactionManagerId',
            type: 'select',
            selectOptions: userSelectOptions(props.transactionUsers),
            noneValue: '-- keine Angabe --',
            editable: true,
        },
        {
            label: 'Jahresnettomieteinnahmen 1. Jahr Gesamt',
            value: data.basicTotalRentFirstYear,
            name: 'basicTotalRentFirstYear',
            formatter: Formatter.formatCurrency,
            editable: true,
        },
        {
            label: 'Nettomieteinnahmen 10-Jahres-Schnitt',
            value: data.basicTotalRentTenYears,
            name: 'basicTotalRentTenYears',
            formatter: Formatter.formatCurrency,
            editable: true,
        },
        {
            label: 'Land',
            value: data.realEstateProperties,
            name: 'Countries',
            formatter: formatCountries,
            editable: false,
        },
        {
            label: 'Gesamtkaufpreis',
            value: data.purchasePrice,
            name: 'propertyPurchasePrice',
            formatter: Formatter.formatCurrency,
            type: 'integerCurrency',
            editable: true,
        },
        {
            label: 'Voraussichtliches Kaufdatum',
            value: data.estimatedPurchaseDate,
            name: 'estimatedPurchaseDate',
            formatter: Formatter.formatDate,
            editable: false,
        },
        {
            label: 'Campaign Trigger Coming Soon',
            value: data.campaignTrigger,
            name: 'campaignTrigger',
            editable: true,
        },
        {
            label: 'Laufzeit',
            value: data.runningTime || '',
            name: 'runningTime',
            editable: true,
            type: 'number',
        },
    ];

    const getUpdatedUsers = (updatedData) => {
        const owners = {};

        if (Object.keys(updatedData).includes('transactionManagerId')) {
            owners.transactionManager = props.transactionUsers.find(user => user.id === updatedData.transactionManagerId);
        }

        if (Object.keys(updatedData).includes('assetManagerId')) {
            owners.assetManager = props.assetUsers.find(user => user.id === updatedData.assetManagerId);
        }

        return owners;
    };

    const handleSave = (updatedData) => {
        setData({
            ...data,
            ...updatedData,
            ...getUpdatedUsers(updatedData),
        });

        props.onSave(updatedData);
    };

    return (
        <EditableCard
            tableData={tableData}
            onSave={handleSave}
            title={'Allgemeine Projektinformationen'}
            headerImage={props.imageUrl}
        />
    );
}
