import API from '../api/API';
import Company from '../interfaces/models/Company';

export default {
    attachManagingDirector: (accountId: number, managingDirectorId: number): Promise<Company> => {
        return API.company.post({
            url: `/${accountId}/managingdirector`,
            data: {'managingDirectorId': managingDirectorId},
        });
    },
    detachManagingDirector: (accountId: number, managingDirectorId: number): Promise<Company> => {
        return API.company.delete(`/${accountId}/managingdirector/${managingDirectorId}`);
    },
    query: (term: string): Promise<Company[]> => {
        return API.company.get(`/search/${term}`);
    },
}
