import React, { ReactElement, useEffect, useState } from 'react';
import ReportingListProps from '../../interfaces/props/ReportingListProps';
import ReportingQuarter from '../../interfaces/models/ReportingQuarter';
import ReportingService from '../../services/ReportingService';
import Formatter from '../../helpers/Formatter';
import CardData from '../../interfaces/CardData';
import { RenderTypes } from '../../interfaces/enums/RenderTypes';
import ThumbUpAltOutlined from '@material-ui/icons/ThumbUpAltOutlined';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { useAppContext } from '../../libs/contextLib';
import Typography from '@material-ui/core/Typography';
import ThemeHelper from '../../helpers/ThemeHelper';
import ReportingDataCard from './ReportingDataCard';
import Tooltip from '@material-ui/core/Tooltip';
import ReportingNew from './ReportingNew';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import SyncIcon from '@material-ui/icons/Sync';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: '100%',
        flexGrow: 1,
        paddingLeft: 10,
        paddingRight: 10,
    },
    button: {
        background: theme.palette.secondary.main,
        color: 'white',
        marginBottom: 8,
        marginTop: 8,
        width: '100%',
    },
    card: {
        maxWidth: '100%',
        flexGrow: 1,
        marginTop: 16,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    cardHeader: {
        backgroundColor: ThemeHelper.getColor(theme, theme.palette.cardHeader.backgroundColor),
        color: ThemeHelper.getColor(theme, theme.palette.cardHeader.color),
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    // @ts-ignore
    return <Slide direction='up' ref={ref} {...props} />;
});

export default function ReportingList(props: ReportingListProps): ReactElement {
    const [reportings, setReportings] = useState<ReportingQuarter[]>([] as ReportingQuarter[]);
    const classes = useStyles();
    const { user } = useAppContext();

    const [reportingToUnApprove, setReportingToUnApprove] = useState<ReportingQuarter | null>(null);
    const [modalOpen, setModalOpen] = React.useState(false);

    useEffect(() => {
        setReportings(props.reportingQuarters);
    }, [props.reportingQuarters]);

    const handleSave = (updatedData: ReportingQuarter, oldData: ReportingQuarter) => {
        const mergedObject = {
            ...oldData,
            ...updatedData,
        }

        ReportingService.updateReport(mergedObject).then((response) => {
            const stateData = [...reportings];
            const index = stateData.indexOf(oldData);

            Object.assign(stateData[index], response);
            setReportings([...stateData]);
        }).catch(() => { });
    };

    const handleApprove = (data: ReportingQuarter) => {
        data.approvedBy = user;

        ReportingService.approveReport({
            ...data,
        }).then((response) => {
            window.location.reload();
        }).catch(() => { });
    }

    const handleContentfulPublish = (data: ReportingQuarter) => {
        ReportingService.publishToContentful({
            ...data,
        }).then((response) => {
            window.location.reload();
        }).catch(() => { });
    }

    const handleUnapproveSend = () => {
        let data = reportingToUnApprove;
        if (!data) {
            return;
        }

        data.approvedBy = user;

        ReportingService.unapproveReport({
            ...data,
        }).then((response) => {
            setReportings({
                ...reportings,
                ...data,
            })
        }).catch(() => { });
    }

    const isApprovable = (data: ReportingQuarter) => {
        const requiredFields = [
            'year',
            'quarter',
            'rentalIncome',
            'rentalLoss',
            'operatingCosts',
            'propertyManagementCosts',
            'assetManagementCosts',
            'maintenanceCosts',
            'interestPayments',
            'projectResult',
            'acquittancePayments',
            'maintenanceReserveFunds',
            'payoutEuro',
            'payoutPercentage',
            'investorCapital',
            'financialProductId',
            'plannedPayoutEuro',
            'plannedPayoutPercentage',
            'plannedRentalIncome',
        ];

        if (!data.summaries || (data.summaries && !Object.keys(data.summaries).includes('de'))) {
            return false;
        }

        const existingFields = requiredFields.filter(field => {
            return Object.keys(data).includes(field);
        });

        if (data.approvedBy) {
            return false;
        }

        return requiredFields.length === existingFields.length;
    }

    const isUnapprovable = (data: ReportingQuarter) => {
        if (data.approvedBy && data.approvedBy.email && user && user.roles.includes('assetManager')) {
            return true;
        }

        return false;
    }

    const renderApproveButton = (data: ReportingQuarter): JSX.Element => {
        return <>
            <Grid container spacing={2} justify="center">
                <Grid item xs={12} sm={6}>
                    <Tooltip title={'Kann nur von einem Asset Manager freigegeben werden.'} arrow>
                        <span style={{ width: '100%' }}>
                            <Button
                                disabled={(data.approvedBy && !!data.approvedBy.email) || !isApprovable(data) || (user && !user.roles.includes('assetManager'))}
                                variant='contained'
                                className={classes.button}
                                startIcon={data.approvedBy && !!data.approvedBy.email ? <CheckIcon /> : <ThumbUpAltOutlined />}
                                onClick={() => handleApprove(data)}
                            >
                                {data.approvedBy ? `freigegeben von: ${data.approvedBy.email}` : 'Report freigeben'}
                            </Button>
                        </span>
                    </Tooltip>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Tooltip title={'Kann nur von einem Asset Manager unveröffentlicht werden.'} arrow>
                        <span style={{ width: '100%' }}>
                            <Button
                                disabled={!isUnapprovable(data)}
                                variant='contained'
                                className={classes.button}
                                startIcon={<DeleteIcon />}
                                onClick={() => handleUnapprove(data)}
                                color='secondary'
                            >
                                {'Report unveröffentlichen'}
                            </Button>
                        </span>
                    </Tooltip>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Tooltip title={'Kann nur von einem Asset Manager freigegeben werden.'} arrow>
                        <span style={{ width: '100%' }}>
                            <Button
                                disabled={user && !user.roles.includes('assetManager')}
                                variant='contained'
                                className={classes.button}
                                startIcon={data.approvedBy && !!data.approvedBy.email ? <CheckIcon /> : <ThumbUpAltOutlined />}
                                onClick={() => handleContentfulPublish(data)}
                            >
                                {'Contentful Aktualisieren'}
                            </Button>
                        </span>
                    </Tooltip>
                </Grid>
            </Grid>
        </>;
    }

    const handleUnapprove = (data: ReportingQuarter) => {
        setReportingToUnApprove(data);
        setModalOpen(true);
    };

    const handleDeleteClose = () => {
        setModalOpen(false);
    };

    return reportings && reportings.length > 0 ? (
        <>
            <Grid className={classes.root} container spacing={0} alignItems='stretch'>
                <Grid item sm={12}>
                    <Typography variant='h5' component={'h2'} gutterBottom>
                        Reportings
                    </Typography>
                    <Typography variant='body1' paragraph>
                        Hinweis: Ein Reporting kann erst freigegeben werden, wenn alle Daten befüllt sind.
                    </Typography>
                    <ReportingNew
                        financialProductId={props.financialProductId}
                    />
                </Grid>
            </Grid>
            <Grid className={classes.root} container spacing={1} alignItems='stretch'>
                {
                    reportings.map((data, index) => {
                        const cardData: CardData[] = [
                            {
                                label: 'Planzahlen',
                                value: null,
                                name: '',
                                editable: false,
                                type: RenderTypes.divider,
                            },
                            {
                                label: 'Geplante Auszahlung in €',
                                value: data.plannedPayoutEuro,
                                name: 'plannedPayoutEuro',
                                id: data.plannedPayoutEuro,
                                editable: true,
                                type: RenderTypes.floatCurrency,
                                formatter: Formatter.formatCurrency,
                            },
                            {
                                label: 'Geplante Auszahlung in %',
                                value: data.plannedPayoutPercentage,
                                name: 'plannedPayoutPercentage',
                                id: data.plannedPayoutPercentage,
                                editable: true,
                                type: RenderTypes.floatPercent,
                                formatter: Formatter.formatPercentage,
                            },
                            {
                                label: 'Geplante Mieteinnahmen',
                                value: data.plannedRentalIncome,
                                name: 'plannedRentalIncome',
                                id: data.plannedRentalIncome,
                                editable: true,
                                type: RenderTypes.floatCurrency,
                                formatter: Formatter.formatCurrency,
                            },
                            {
                                label: 'Einnahmen / Ausgaben',
                                value: null,
                                name: '',
                                editable: false,
                                type: RenderTypes.divider,
                            },
                            {
                                label: 'Mieteinnahmen',
                                value: data.rentalIncome,
                                name: 'rentalIncome',
                                id: data.rentalIncome,
                                editable: false,
                                type: RenderTypes.floatCurrency,
                                formatter: Formatter.formatCurrency,
                            },
                            {
                                label: 'Saldovortrag',
                                value: data.saldoVortrag,
                                name: 'saldoVortrag',
                                id: data.saldoVortrag,
                                editable: false,
                                type: RenderTypes.floatCurrency,
                                formatter: Formatter.formatCurrency,
                            },
                            {
                                label: 'Mietausfall',
                                value: data.rentalLoss,
                                name: 'rentalLoss',
                                id: data.rentalLoss,
                                editable: false,
                                type: RenderTypes.floatCurrency,
                                formatter: Formatter.formatCurrency,
                            },
                            {
                                label: 'Betriebskosten',
                                value: data.operatingCosts,
                                name: 'operatingCosts',
                                id: data.operatingCosts,
                                editable: false,
                                type: RenderTypes.floatCurrency,
                                formatter: Formatter.formatCurrency,
                            },
                            {
                                label: 'Hausverwaltung',
                                value: data.propertyManagementCosts,
                                name: 'propertyManagementCosts',
                                id: data.propertyManagementCosts,
                                editable: false,
                                type: RenderTypes.floatCurrency,
                                formatter: Formatter.formatCurrency,
                            },
                            {
                                label: 'Verwaltungskosten',
                                value: data.assetManagementCosts,
                                name: 'assetManagementCosts',
                                id: data.assetManagementCosts,
                                editable: false,
                                type: RenderTypes.floatCurrency,
                                formatter: Formatter.formatCurrency,
                            },
                            {
                                label: 'Instandshaltungskosten',
                                value: data.maintenanceCosts,
                                name: 'maintenanceCosts',
                                id: data.maintenanceCosts,
                                editable: false,
                                type: RenderTypes.floatCurrency,
                                formatter: Formatter.formatCurrency,
                            },
                            {
                                label: 'Zinszahlungen',
                                value: data.interestPayments,
                                name: 'interestPayments',
                                id: data.interestPayments,
                                editable: false,
                                type: RenderTypes.floatCurrency,
                                formatter: Formatter.formatCurrency,
                            },
                            {
                                label: 'Ergebnis der Immobilie(n)',
                                value: data.projectResult,
                                name: 'projectResult',
                                id: data.projectResult,
                                editable: false,
                                formatter: Formatter.formatCurrency,
                                hint: 'Wird aus den obigen Werten berechnet.',
                            },
                            {
                                label: 'Tilgungszahlungen',
                                value: data.acquittancePayments,
                                name: 'acquittancePayments',
                                id: data.acquittancePayments,
                                editable: false,
                                type: RenderTypes.floatCurrency,
                                formatter: Formatter.formatCurrency,
                            },
                            {
                                label: 'Instandshaltungsrücklage',
                                value: data.maintenanceReserveFunds,
                                name: 'maintenanceReserveFunds',
                                id: data.maintenanceReserveFunds,
                                editable: false,
                                type: RenderTypes.floatCurrency,
                                formatter: Formatter.formatCurrency,
                            },
                            {
                                label: 'Instandshaltungsrücklage Gesamt',
                                value: data.maintenanceReserveFundsTotal,
                                name: 'maintenanceReserveFundsTotal',
                                id: data.maintenanceReserveFundsTotal,
                                editable: true,
                                type: RenderTypes.floatCurrency,
                                formatter: Formatter.formatCurrency,
                            },
                            {
                                label: 'Ausschüttung',
                                value: null,
                                name: '',
                                editable: false,
                                type: RenderTypes.divider,
                            },
                            {
                                label: 'Anlegerkapital',
                                value: data.investorCapital,
                                name: 'investorCapital',
                                id: data.investorCapital,
                                editable: false,
                                type: RenderTypes.floatCurrency,
                                formatter: Formatter.formatCurrency,
                            },
                            {
                                label: 'Fundingziel',
                                value: data.fundingTarget,
                                name: 'investorCapital',
                                id: data.investorCapital,
                                editable: true,
                                type: RenderTypes.floatCurrency,
                                formatter: Formatter.formatCurrency,
                            },
                            {
                                label: 'Wieviel Prozent wurden gefunded?',
                                value: data.investorCapitalPercentage,
                                name: 'investorCapital',
                                id: data.investorCapital,
                                editable: true,
                                type: RenderTypes.floatPercent,
                                formatter: Formatter.formatPercentage,
                            },
                            {
                                label: 'Ausschüttung in €',
                                value: data.payoutEuro,
                                name: 'payoutEuro',
                                id: data.payoutEuro,
                                editable: true,
                                type: RenderTypes.floatCurrency,
                                formatter: Formatter.formatCurrency,
                            },
                            {
                                label: 'Ausschüttung in %',
                                value: data.payoutPercentage,
                                name: 'payoutPercentage',
                                id: data.payoutPercentage,
                                editable: false,
                                type: RenderTypes.floatPercent,
                                formatter: Formatter.formatPercentage,
                                hint: 'Ausschüttung in € / Anlegerkapital'
                            },
                        ];

                        return (
                            <>
                                <Grid item sm={4} key={data.financialProductId} wrap='wrap'>
                                    <ReportingDataCard
                                        tableData={cardData}
                                        summaries={data.summaries}
                                        onSave={(updatedData: ReportingQuarter) => handleSave(updatedData, data)}
                                        title={`Q${data.quarter} ${data.year} ${data.approvedBy ? '' : '(unveröffentlicht)'}`}
                                        cardActions={renderApproveButton(data)}
                                        headerStyle={data.approvedBy ? 'primary' : 'secondary'}
                                        style={{ height: '100%' }}
                                    />
                                </Grid>
                            </>
                        );
                    })
                }
            </Grid>
            <Dialog
                open={modalOpen}
                // @ts-ignore
                TransitionComponent={Transition}
                keepMounted
                onClose={handleDeleteClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>Report unveröffentlichen</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        {'Bist du dir sicher, dass du den Report unveröffentlichen möchtest?'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteClose} color='primary' autoFocus>
                        Abbrechen
                    </Button>
                    <Button
                        variant='contained'
                        onClick={handleUnapproveSend}
                        className={classes.button}
                        startIcon={<SyncIcon />}
                    >
                        Unveröffentlichen
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    ) : (<div>Noch keine Reportings eingetragen</div>);
}
