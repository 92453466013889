import React, {useEffect, useState} from 'react';
import Formatter from '../../helpers/Formatter';
import FinancingEntityService from '../../services/FinancingEntityService';
import EditableCard from '../UI/Cards/EditableCard';

export default function FinancialConditionsCard(props) {
    const {financingEntity} = props;
    const [data, setData] = useState({});

    useEffect(() => {
        setData(financingEntity);
    }, [financingEntity]);

    const tableData = [
        {
            label: 'Funding Target',
            value: data.fundingTargetMin,
            name: 'fundingTargetMin',
            formatter: Formatter.formatCurrency,
            type: 'integerCurrency',
            editable: true,
        },
        {
            label: 'Laufzeit Start',
            value: data.startsAt,
            name: 'startsAt',
            formatter: Formatter.formatDate,
            type: 'date',
            editable: true,
        },
        {
            label: 'Laufzeit Ende',
            value: data.endsAt,
            name: 'endsAt',
            formatter: Formatter.formatDate,
            type: 'date',
            editable: true,
        },
        {
            label: 'Laufzeit in Jahren',
            value: data.runningTime,
            name: 'endsAt',
            hint: 'Wird aus Laufzeit Start & Ende berechnet',
            formatter: Formatter.formatYears,
        },
    ];

    const handleSave = (updatedData) => {
        setData({
            ...data,
            ...updatedData,
        });

        FinancingEntityService.update(data.id, updatedData).then(response => {
            setData(response);
        });
    };

    return (
        <EditableCard
            tableData={tableData}
            onSave={handleSave}
            title={'Finanzielle Konditionen'}
        />
    );
}
