import React, {useEffect, useState} from 'react';
import Formatter from '../../helpers/Formatter';
import FinancingEntityService from '../../services/FinancingEntityService';
import EditableCard from '../UI/Cards/EditableCard';
import {useAppContext} from '../../libs/contextLib';

export default function GeneralCard(props) {
    const {selectOptions} = useAppContext();
    const [data, setData] = useState({});
    const [stageTypes, setStageTypes] = useState({});
    const [legalSetupTypes, setLegalSetupTypes] = useState({});
    const [bondTypes, setBondTypes] = useState({});
    const {financingEntity} = props;

    useEffect(() => {
        setData(financingEntity);
    }, [financingEntity]);

    useEffect(() => {
        if (Object.keys(selectOptions).length === 0) {
            return;
        }

        setStageTypes(selectOptions.financingEntity.stageTypes);
        setLegalSetupTypes(selectOptions.financingEntity.legalSetupTypes);
        setBondTypes(selectOptions.financingEntity.bondTypes);
    }, [selectOptions]);

    const stageSelectOptions = () => {
        return Formatter.formatSelectOptionsFromObject(stageTypes);
    };

    const legalSetupOptions = () => {
        return Formatter.formatSelectOptionsFromObject(legalSetupTypes);
    };

    const bondTypeSelectOptions = () => {
        return Formatter.formatSelectOptionsFromObject(bondTypes);
    }

    const tableData = [
        {
            label: 'Legal Setup',
            value: data.legalSetup,
            id: data.legalSetup,
            name: 'legalSetup',
            type: 'select',
            editable: true,
            selectOptions: legalSetupOptions(),
            formatter: legalSetup => Formatter.formatLegalSetup(legalSetup, legalSetupTypes),
        },
        {
            label: 'Darlehensgeber',
            value: data.creditor ? data.creditor.name : null,
            id: data.creditor ? data.creditor : null,
            name: 'creditorId',
            type: 'accountSelect',
            noneValue: '-- keine Angabe --',
            editFormatter: Formatter.formatAccountSelectOption,
            editable: true,
        },
        {
            label: 'Treuhänder',
            value: data.trustee ? data.trustee.name : null,
            id: data.trustee ? data.trustee : null,
            name: 'trusteeId',
            type: 'accountSelect',
            noneValue: '-- keine Angabe --',
            editFormatter: Formatter.formatAccountSelectOption,
            editable: true,
        },
        {
            label: 'Mindestinvestmentsumme',
            value: data.investingAmountMin,
            name: 'investingAmountMin',
            type: 'integerCurrency',
            editable: true,
            formatter: Formatter.formatCurrency,
        },
        {
            label: 'Anleihentyp',
            value: data.bondType,
            id: data.bondType,
            name: 'bondType',
            type: 'select',
            editable: true,
            selectOptions: bondTypeSelectOptions(),
            formatter: bondType => Formatter.formatFinancingEntityBondType(bondType, bondTypes),
        },
        {
            label: 'Stage',
            value: data.stage,
            id: data.stage,
            name: 'stage',
            editable: true,
            type: 'select',
            selectOptions: stageSelectOptions(),
            formatter: stage => Formatter.formatStage(stage, stageTypes),
        },
        {
            label: 'Voraussichtlicher Funding-Start',
            value: data.estimatedFundingStart,
            name: 'estimatedFundingStart',
            formatter: Formatter.formatDate,
            type: 'date',
            editable: true,
        },
        {
            label: 'ISIN',
            value: data.isin,
            name: 'isin',
            editable: true,
        },
        {
            label: 'WKN',
            value: data.wkn,
            name: 'wkn',
            editable: true,
        },
        {
            label: 'Token Nummer',
            value: data.tokenNumber,
            name: 'tokenNumber',
            editable: true,
        },
        {
            label: 'Actual Funding Amount',
            value: data.actualFundingAmount,
            name: 'actualFundingAmount',
            editable: true,
            formatter: Formatter.formatCurrency,
        },
    ];

    const handleSave = (updatedData) => {
        setData({
            ...data,
            ...updatedData,
        });

        FinancingEntityService.update(data.id, updatedData).then(result => {
            setData(result);
        });
    };

    return (
        <EditableCard
            tableData={tableData}
            onSave={handleSave}
            title={'Finanzielle Konditionen'}
        />
    );
}
