import React, {useEffect, useState} from 'react';
import ProjectArchiveTable from './ProjectArchiveTable';
import Loader from '../../UI/Loader';
import Helper from '../../../helpers/Helper';
import ProjectsArchiveProps from '../../../interfaces/props/ProjectsArchiveProps';

export default function Archive(props: ProjectsArchiveProps) {
    const [loading, setLoading] = useState(false);
    const {setMenuItems, setBreadcrumbItems} = props;

    useEffect(() => {
        setMenuItems!([]);
        setBreadcrumbItems!([]);
        Helper.setPageTitle('Abgelehnte Projekte');
    }, [setMenuItems, setBreadcrumbItems]);

    return (
        <>
            <ProjectArchiveTable setLoader={setLoading} />
            <Loader open={loading}/>
        </>
    );
}
