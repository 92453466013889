import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import LoaderProps from '../../../interfaces/props/LoaderProps';

const useStyles = makeStyles(theme => ({
    overlay: {
        zIndex: theme.zIndex.drawer + 1,
        position:'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(88, 88, 88, 0.7)',
        top: 0,
        left: 0
    },
    centered: {
        margin: 0,
        position: 'absolute',
        top: '50%',
        left: '50%',
        color: '#fff',
    },
}));

export default function Loader(props: LoaderProps) {
    const classes = useStyles();

    return props.open ?
        (
            <div className={classes.overlay}>
                <div className={classes.centered}>
                    <CircularProgress color='inherit' />
                </div>
            </div>
        )
        : null;
}
