import API from '../api/API';
import Account from "../interfaces/models/Account";

export default {
    all: (): Promise<Account[]> => {
        return API.account.get();
    },
    create: (data: object): Promise<Account> => {
        return API.account.post({
            data: data,
        });
    },
    get: (id: number): Promise<Account> => {
        return API.account.get(id);
    },
    delete: (id: number): Promise<string> => {
        return API.account.delete(id);
    },
    updateFromTable: async (id: number, data: Account): Promise<Account> => {
        return API.account.patch({
            url: id,
            data: data,
        });
    },
    query: (term: string): Promise<Account[]> => {
        return API.account.get(`/search/${term}`);
    },
}
