import React, {useState, useEffect} from 'react';
import Formatter from '../../../helpers/Formatter';
import ProjectService from '../../../services/ProjectService';
import UserService from '../../../services/UserService';
import BaseTable from '../../UI/Tables/BaseTable';
import {useAppContext} from '../../../libs/contextLib';

export default function ProjectsArchiveTable(props) {
    const {selectOptions} = useAppContext();
    const [data, setData] = useState([]);
    const [ownerUsers, setOwnerUsers] = useState({});
    const [stageTypes, setStageTypes] = useState({});

    const {setLoader} = props;

    useEffect(() => {
        setLoader(true);

        ProjectService.getByStage('declined').then(projects => {
            setData(projects);
            setLoader(false);
        }).catch(() => {
            setLoader(false)
        });
    }, [setLoader]);

    useEffect(() => {
        if (Object.keys(selectOptions).length === 0) {
            return;
        }

        setStageTypes(selectOptions.project.stageTypes);
    }, [selectOptions]);

    useEffect(() => {
        formatOwnerUsers();
    }, []);

    const handleUpdate = updatedData => {
        return ProjectService.updateFromTable(updatedData.id, updatedData);
    };

    const formatOwnerUsers = () => {
        let ownerUsers = {};
        UserService.allShort().then(users => {
            users = users || [];
            users.forEach(user => {
                ownerUsers[user.id] = user.fullName;
            });
        }).catch(() => { });

        setOwnerUsers(ownerUsers);
    };

    const columns = [
        {
            title: 'ID',
            field: 'id',
            render: row => Formatter.formatIdColumn(row, 'project'),
            filtering: false,
            width: '65px',
        },
        {
            title: 'Projektname',
            field: 'name',
            editable: 'onUpdate',
            render: row => Formatter.formatNameColumn(row, 'project'),
        },
        {
            title: 'Account',
            id: 'accountId',
            editable: 'never',
            field: 'account.companyName',
        },
        {
            title: 'Owner',
            id: 'ownerId',
            editable: 'onUpdate',
            render: row => Formatter.formatOwnerName(row, 'owner'),
            field: 'ownerId',
            lookup: ownerUsers,
        },
        {
            title: 'Stage',
            field: 'stage',
            lookup: stageTypes,
            editable: 'onUpdate',
            filtering: false,
        },
        {
            title: 'Kaufpreis',
            id: 'computedPurchasePrice',
            field: 'computedPurchasePrice',
            render: row => Formatter.formatCurrency(row.computedPurchasePrice),
            editable: 'never',
            filtering: false,
        },
        {
            title: 'Buchungskreis',
            field: 'bkr',
            editable: 'onUpdate',
            width: '130px',
        },
        {
            title: 'Funding-Volumen',
            id: 'computedFundingVolume',
            field: 'computedFundingVolume',
            render: row => Formatter.formatCurrency(row.computedFundingVolume),
            editable: 'never',
            filtering: false,
        },
    ];

    return (
        <BaseTable
            tableName='projectsArchiveTable'
            columns={columns}
            data={data}
            onUpdate={handleUpdate}
            title='Abgelehnte Projekte'
            disableDeleteAction={true}
        />
    );
}
