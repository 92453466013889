import API from '../api/API';
import Person from '../interfaces/models/Person';

export default {
    all: (): Promise<Person[]> => {
        return API.person.get();
    },
    query: (term: string): Promise<Person[]> => {
        return API.person.get(`/search/${term}`);
    },
}
