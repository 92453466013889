import React, {useEffect, useState} from 'react';
import Formatter from '../../helpers/Formatter';
import BaseTable from '../UI/Tables/BaseTable';
import ReportingService from "../../services/ReportingService";
import {Link as RouterLink} from "react-router-dom";
import Link from "@material-ui/core/Link";

export default function ReportingTable(props) {
    const [data, setData] = useState([]);
    const {setLoader} = props;

    useEffect(() => {
        setLoader(true);

        ReportingService.getAllAssets().then(assets => {
            setData(assets);
            setLoader(false);
        }).catch(() => {
            setLoader(false);
        });
    }, [setLoader]);

    const columns = [
        {
            title: 'Name',
            field: 'projectName',
            render: (row) => (<Link component={RouterLink} to={`/reporting/${row.financialProductId}`}>{row.projectName}</Link>),
            editable: 'never',
        },
        {
            title: 'Buchungskreis',
            field: 'bkr',
            editable: 'never',
        },
        {
            title: 'Financial Product ID',
            field: 'financialProductId',
            editable: 'never',
        },
        {
            title: 'Funding-Volumen',
            field: 'fundingGoal',
            render: row => Formatter.formatCurrency(row.fundingGoal),
            editable: 'never',
            filtering: false,
        },
    ];

    return (
        <BaseTable
            tableName='assetTable'
            columns={columns}
            data={data}
            title='Assets'
            objects='Assets'
            disableDeleteAction={true}
            disableUpdateAction={true}
        />
    );
}
