import React, {useEffect, useState} from 'react';
import Formatter from '../../helpers/Formatter';
import FinancingEntityService from '../../services/FinancingEntityService';
import EditableCard from '../UI/Cards/EditableCard';
import Box from '@material-ui/core/Box';

export default function BondCalculationCard(props) {
    const [data, setData] = useState({});

    const {financingEntity} = props;

    useEffect(() => {
        setData(financingEntity);
    }, [financingEntity]);

    const salesProvision = () => {
        return data.salesProvision
            ?
                {
                    label: 'Vertriebsprovision (Betrag)',
                    value: data.salesProvision,
                    name: 'salesProvision',
                    formatter: Formatter.formatCurrency,
                    editable: false,
                    hint: 'Wird wie folgt berechnet: Actual Funding Amount * Vertriebsprovision %'
                }
            :
                {
                    label: 'Vertriebsprovision (Betrag)',
                    value: <Box fontStyle='italic' fontSize={10}>wird erst in der Stage Funded berechnet</Box>,
                    editable: false,
                    name: 'salesProvision',
                    hint: 'Wird wie folgt berechnet: Actual Funding Amount * Vertriebsprovision %'
                };
    }

    const tableData = [
        {
            label: 'Erste Zinsausschüttung',
            value: data.firstInterestDistribution,
            name: 'firstInterestDistribution',
            formatter: Formatter.formatDate,
            type: 'date',
            editable: true,
        },
        {
            label: 'Sourcing Intern',
            value: data.internalSourcing,
            name: 'internalSourcing',
            formatter: Formatter.formatCurrency,
            type: 'integerCurrency',
            editable: true,
        },
        {
            label: 'Prospekterstellung',
            value: data.prospectCosts,
            name: 'prospectCosts',
            formatter: Formatter.formatCurrency,
            type: 'integerCurrency',
            editable: true,
        },
        {
            label: 'Emissionsmarketing',
            value: data.emissionMarketingCosts,
            name: 'emissionMarketingCosts',
            formatter: Formatter.formatCurrency,
            type: 'integerCurrency',
            editable: true,
        },
        {
            label: 'Vertriebsprovision (%)',
            value: data.completionCostsPercentage,
            name: 'completionCostsPercentage',
            formatter: Formatter.formatPercentage,
            type: 'number',
            editable: true,
        },
        salesProvision(),
        {
            label: 'Break even Prospekt',
            value: data.breakEvenProspect,
            name: 'breakEvenProspect',
            formatter: Formatter.formatCurrency,
            type: 'integerCurrency',
            editable: true,
        },
        {
            label: 'Externe DueDiligence',
            value: data.externalDdCosts,
            name: 'externalDdCosts',
            formatter: Formatter.formatCurrency,
            type: 'integerCurrency',
            editable: true,
        },
        {
            label: 'Wirtschaftsprüfer / Steuerberater',
            value: data.taxLawyerCosts,
            name: 'taxLawyerCosts',
            editable: true,
            formatter: Formatter.formatCurrency,
            type: 'integerCurrency',
        },
        {
            label: 'Sicherheitentreuhänder',
            value: data.securityTrusteeCosts,
            name: 'securityTrusteeCosts',
            editable: true,
            formatter: Formatter.formatCurrency,
            type: 'integerCurrency',
        },
        {
            label: 'Zahlstelle',
            value: data.payingAgencyCosts,
            name: 'payingAgencyCosts',
            formatter: Formatter.formatCurrency,
            type: 'integerCurrency',
            editable: true,
        },
        {
            label: 'Andere Kosten',
            value: data.otherCosts,
            name: 'otherCosts',
            formatter: Formatter.formatCurrency,
            editable: false,
        },
        {
            label: 'Asset Management Kosten (Mietenbasiert)',
            value: data.assetManagementFeeRentBased,
            name: 'assetManagementFeeRentBased',
            formatter: Formatter.formatPercentage,
            type: 'number',
            editable: true,
        },
    ];

    const handleSave = (updatedData) => {
        setData({
            ...data,
            ...updatedData,
        });

        FinancingEntityService.update(data.id, updatedData);
    };

    return (
        <EditableCard
            tableData={tableData}
            onSave={handleSave}
            title={'Anleihenkalkulationsdaten'}
        />
    );
}
