import React, {useEffect, useState} from 'react';
import UsersTable from './UsersTable';
import Loader from '../UI/Loader';
import Helper from '../../helpers/Helper';
import UserProps from '../../interfaces/props/UserProps';

export default function Accounts(props: UserProps) {
    const {setMenuItems, setBreadcrumbItems} = props;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setMenuItems!([]);
        setBreadcrumbItems!([]);
        Helper.setPageTitle('Benutzerverwaltung');
    }, [setMenuItems, setBreadcrumbItems]);

    return (
        <>
            <UsersTable setLoader={setLoading}/>
            <Loader open={loading}/>
        </>
    );
}
