import React, {useEffect, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import AccountService from '../../../services/AccountService';
import CompanyBaseInformationCard from './CompanyBaseInformationCard';
import PersonBaseInformationCard from './PersonBaseInformationCard';
import Grid from '@material-ui/core/Grid';
import Loader from '../../UI/Loader';
import Typography from '@material-ui/core/Typography';
import EditableTextCard from '../../UI/Cards/EditableTextCard';
import ManagingDirectorTable from './ManagingDirectorTable';
import Helper from '../../../helpers/Helper';

export default function AccountDetailPage(props) {
    const {setBreadcrumbItems} = props;
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const match = useRouteMatch();

    useEffect(() => {
        setIsLoading(true);

        AccountService.get(match.params.id).then(account => {
            setData(account);
            setIsLoading(false);

            setBreadcrumbItems([
                {
                    type: 'link',
                    link: '/accounts/',
                    name: 'Accounts',
                },
                {
                    type: 'typography',
                    name: account.name,
                },
            ]);
        }).catch(() => {
            setIsLoading(false);
        });

        data.name
            ? Helper.setPageTitle('Account - ' + data.name)
            : Helper.setPageTitle('Account - #' + match.params.id);

    }, [match.params.id, setBreadcrumbItems, data.name]);

    if (isLoading) {
        return <Loader open={isLoading}/>
    }

    const handleChange = (event) => {
        const currentData = data;
        currentData[event.target.name] = event.target.value;
        setData(currentData);
    };

    const handleSave = (updatedData, what) => {
        let updateData = {};
        updateData[what] = updatedData[what];

        AccountService.updateFromTable(data.id, {
            ...updateData
        }).catch(() => {

        });
    };

    return (
        <>
            <Typography gutterBottom variant='h5' component='h2'>
                {data.name}
            </Typography>
            <Grid container spacing={3} alignItems='stretch'>
                <Grid item sm={6}>
                    {data.type === 'person'
                        ? <PersonBaseInformationCard account={data}/>
                        : <CompanyBaseInformationCard account={data}/>
                    }
                </Grid>
                <Grid item sm={6}>
                    <EditableTextCard
                        data={data}
                        onChange={handleChange}
                        onSave={() => handleSave(data, 'description')}
                        title={'Beschreibung'}
                        name={'description'}
                    />
                </Grid>
            </Grid>
            {data.type === 'company'
                ? (
                    <Grid container spacing={3} alignItems='stretch'>
                        <Grid item sm={12}>
                            <ManagingDirectorTable managingDirectors={data.managingDirectors}/>
                        </Grid>
                    </Grid>
                )
                : null
            }
        </>
    );
}
