import React, {ChangeEvent, ReactElement, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import {CardHeader} from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import {makeStyles} from '@material-ui/core/styles';
import ThemeHelper from '../../helpers/ThemeHelper';
import ButtonEditOkCancel from '../UI/Partials/ButtonEditOkCancel';
import CustomDatePicker from '../UI/Forms/CustomDatePicker';
import EventNewProps from '../../interfaces/props/EventNewProps';
import Event from '../../interfaces/models/Event';

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: '100%',
        flexGrow: 1,
    },
    card: {
        maxWidth: '100%',
        flexGrow: 1,
        marginTop: 16,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    cardHeader: {
        backgroundColor: ThemeHelper.getColor(theme, theme.palette.cardHeader.backgroundColor),
        color: ThemeHelper.getColor(theme, theme.palette.cardHeader.color),
    },
}));

export default function EventNew(props: EventNewProps): ReactElement {
    const classes = useStyles();
    const [data, setData] = useState<Event>({} as Event);

    const handleSave = async (): Promise<void> => {
        props.onSave(data);
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        let currentData: Event = data;
        // @ts-ignore
        currentData[event.target.name] = event.target.value;
        setData(currentData);
    }

    return (
        <>
            <Card className={classes.card}>
                <CardHeader
                    title='Neues Ereignis eintragen'
                    className={classes.cardHeader}
                    action={
                        <ButtonEditOkCancel
                            onCancel={props.onCancel}
                            onSave={handleSave}
                            editMode={true}
                            onEdit={() => {
                            }}
                        />
                    }
                />
                <CardContent className={classes.root}>
                    <CustomDatePicker
                        id='date'
                        value=''
                        onChange={handleChange}
                        name='date'
                    />
                    <TextField
                        id='message'
                        multiline
                        rows={10}
                        defaultValue=''
                        variant='outlined'
                        name='message'
                        onChange={handleChange}
                        style={{width: '100%'}}
                    />
                </CardContent>
            </Card>
        </>
    );
}
