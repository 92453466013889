import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {SnackbarProvider} from 'notistack';

Sentry.init({
    dsn: 'https://07bf207725e240efb909345aec6a8877@o38895.ingest.sentry.io/5224127',
    environment: process.env.REACT_APP_ENV,
});

/* tslint:disable */
const JsApp: any = App; // todo delete me after refactoring to TS
/* tslint:enable */

ReactDOM.render(
    <React.StrictMode>
        <SnackbarProvider maxSnack={3} preventDuplicate>
            <JsApp />
        </SnackbarProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

serviceWorker.unregister();
