import React, {ReactElement, useEffect, useState} from 'react';
import EditableCard from '../../UI/Cards/EditableCard';
import Project from '../../../interfaces/models/Project';
import CardData from '../../../interfaces/CardData';
import LandRegisterCardProps from '../../../interfaces/props/LandRegisterCardProps';
import LandRegister from '../../../interfaces/models/LandRegister';

export default function LandRegisterUsufructAndRestrictionCard(props: LandRegisterCardProps): ReactElement {
    const {landRegister} = props;
    const [data, setData] = useState<LandRegister>({} as LandRegister);

    useEffect(() => {
        setData(landRegister);
    }, [landRegister]);

    const tableData: CardData[] = [
        {
            label: 'Lasten',
            value: data.usufruct ? data.usufruct : null,
            name: 'usufruct',
            editable: true,
        },
        {
            label: 'Beschränkungen',
            value: data.priorityNoticeOwnership ? data.priorityNoticeOwnership : null,
            name: 'priorityNoticeOwnership',
            editable: true,
        },
    ];

    const handleSave = (updatedData: Project): void => {
        setData({
            ...data,
            ...updatedData
        });

        props.onSave(updatedData);
    };

    return (
        <EditableCard
            tableData={tableData}
            onSave={handleSave}
            title={'Abteilung 2 - Lasten und Beschränkungen'}
        />
    );
}
