import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import LinearProgress from '@material-ui/core/LinearProgress';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import PictureDropzoneArea from './PictureDropzoneArea';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import EditIcon from '@material-ui/icons/Edit';
import ThemeHelper from '../../../helpers/ThemeHelper';
import {useAppContext} from '../../../libs/contextLib';
import UserHelper from '../../../helpers/UserHelper';

const useStyles = makeStyles((theme) => ({
    root: {
        with: '100%',
        marginBottom: 10,
    },
    media: {
        height: 'auto',
    },
    button: {
        color: ThemeHelper.getColor(theme, theme.palette.primary),
        margin: theme.spacing(1),
    },
    buttonRed: {
        color: ThemeHelper.getColor(theme, theme.palette.error),
        margin: theme.spacing(1),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '100%',
        maxHeight: '100%',
        border: 0,
    },
    modalImage: {
        maxWidth: '100%',
        maxHeight: '100%',
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

export default function SingleUpload(props) {
    const classes = useStyles();
    const [data, setData] = useState(props.data);
    const [uploading, setUploading] = useState(false);
    const [previewImage, setPreviewImage] = useState('images/placeholder.jpg');
    const [openZoomModal, setOpenZoomModal] = React.useState(false);
    const [openDropzoneModal, setOpenDropzoneModal] = useState(false);
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const {user} = useAppContext();

    useEffect(() => {
        (uploading || !data[props.column]) ? setPreviewImage('images/placeholder.jpg') : setPreviewImage(data[props.column + 'Thumb']);
    }, [data, uploading, props.column]);

    const navigateToExternalUrl = (url, shouldOpenNewTab = true) =>
        shouldOpenNewTab ? window.open(url, '_blank') : window.location.href = url;

    const handleSave = async (files) => {
        setUploading(true);
        setOpenDropzoneModal(false);
        const formData = new FormData();
        formData.append('images[]', files[0], files[0].name);

        props.service.upload(data.id, props.urlSuffix, formData).then(result => {
            setData(result);
            props.onSave(result);
            setUploading(false);
        }).catch(() => {
            setUploading(false);
        });
    }

    const handleDelete = async () => {
        setUploading(true);
        handleDeleteClose();
        props.service.update(data.id, {
            [props.column]: null
        }).then(result => {
            setData(result);
            props.onSave(result);
            setUploading(false);
        }).catch(() => {
        });
    }

    const handleDownload = () => {
        navigateToExternalUrl(data[props.column + 'Original'], true);
    }

    const handleOpenDropzone = () => {
        setOpenDropzoneModal(true);
    }

    const handleCloseDropzone = () => {
        setOpenDropzoneModal(false);
    }

    const handleOpenZoomModal = () => {
        data[props.column] ? setOpenZoomModal(true) : handleOpenDropzone();
    };

    const handleCloseZoomModal = () => {
        setOpenZoomModal(false);
    };

    const handleClickDeleteOpen = () => {
        setDeleteOpen(true);
    };

    const handleDeleteClose = () => {
        setDeleteOpen(false);
    };

    const dropzone = () => {
        return UserHelper.hasPermission(user, 'edit')
            ? (<PictureDropzoneArea onSave={files => handleSave(files)} onClose={handleCloseDropzone}
                                    cancelButton={data[props.column] !== null}/>)
            : null
    };

    const actions = () => {
        return UserHelper.hasPermission(user, 'edit')
            ? (<CardActions>
                    <Button
                        onClick={handleOpenDropzone}
                        size='small'
                        startIcon={<EditIcon/>}
                        className={classes.button}
                    >
                        Ändern
                    </Button>
                    <Button
                        onClick={handleDownload}
                        size='small'
                        startIcon={<GetAppIcon/>}
                        className={classes.button}
                    >
                        Download
                    </Button>
                    <Button onClick={handleClickDeleteOpen}
                            size='small'
                            startIcon={<DeleteIcon/>}
                            className={classes.buttonRed}>
                        Löschen
                    </Button>
                </CardActions>
            )
            : null
    };

    return (
        <Card className={classes.root}>
            <CardActionArea onClick={handleOpenZoomModal}>
                <CardMedia
                    component='img'
                    className={classes.media}
                    image={previewImage}
                    title={data[props.column]}
                />
                {uploading ? <LinearProgress/> : null}
                <CardContent>
                    <Typography gutterBottom color='textSecondary'>
                        {props.title}
                    </Typography>
                </CardContent>
            </CardActionArea>

            {openDropzoneModal || !data[props.column]
                ? dropzone()
                : actions()
            }

            <Modal
                className={classes.modal}
                open={openZoomModal}
                onClose={handleCloseZoomModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openZoomModal}>
                    <img onClick={handleCloseZoomModal} className={classes.modalImage}
                         src={data[props.column + 'Fullscreen']} alt='Zoomed'/>
                </Fade>
            </Modal>

            <Dialog
                open={deleteOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleDeleteClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>{'Bild löschen?'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        Bist du dir sicher, dass du dieses Bild löschen möchtest?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleDeleteClose}
                        color='primary'
                        autoFocus
                        startIcon={<CloseIcon/>}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        onClick={handleDelete}
                        color='primary'
                        startIcon={<DeleteIcon/>}
                    >
                        Löschen
                    </Button>
                </DialogActions>
            </Dialog>
        </Card>
    );
}
