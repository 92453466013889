import React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Hint from '../Partials/Hint';
import Render from '../../../helpers/Render';
import {makeStyles} from '@material-ui/core/styles';
import ThemeHelper from '../../../helpers/ThemeHelper';
import DebugHint from '../Partials/DebugHint';

const useStyles = makeStyles(theme => ({
    primary: {
        color: ThemeHelper.getColor(theme, theme.palette.primary),
    },
}));

export default function EditableSmallTable(props) {
    const classes = useStyles();

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableBody>
                    {props.rows.map(row => {
                        return (
                            <TableRow key={row.name}>
                                {props.editMode
                                    ? (
                                        <>
                                            <TableCell>{row.label}
                                                <Hint
                                                    message={row.hint}
                                                    className={classes.primary}
                                                />
                                            </TableCell>
                                            <TableCell align='right'>
                                                {props.editMode && row.editable
                                                    ? Render.renderEditComponentByType(props, row)
                                                    : row.formatter ? row.formatter(row.value) : row.value}
                                            </TableCell>
                                        </>
                                    )
                                    : (
                                        <>
                                            <TableCell>{row.label}
                                                <Hint
                                                    message={row.hint}
                                                    className={classes.primary}
                                                />
                                                <DebugHint id={row.name}/>
                                            </TableCell>
                                            <TableCell
                                                align='right'>{row.formatter ? row.formatter(row.value) || Render.defaultValue() : row.value || Render.defaultValue() }</TableCell>
                                        </>
                                    )
                                }
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
