import React, {useEffect, useState} from 'react';
import Formatter from '../../../helpers/Formatter';
import EditableCard from '../../UI/Cards/EditableCard';
import {useAppContext} from '../../../libs/contextLib';
import MetaDataCardProps from '../../../interfaces/props/MetaDataCardProps';
import RealEstateProperty from '../../../interfaces/models/RealEstateProperty';

export default function MetaDataCard(props: MetaDataCardProps) {
    const {selectOptions} = useAppContext();
    const [data, setData] = useState<RealEstateProperty>({} as RealEstateProperty);
    const [assetClasses, setAssetClasses] = useState({});
    const [buildingStructures, setBuildingStructures] = useState({});
    const {realEstateProperty} = props;

    useEffect(() => {
        if (!selectOptions) {
            return;
        }

        if (Object.keys(selectOptions).length === 0) {
            return;
        }

        setAssetClasses(selectOptions.realEstateProperty.assetClasses);
        setBuildingStructures(selectOptions.realEstateProperty.buildingStructures);
    }, [selectOptions]);

    useEffect(() => {
        setData(realEstateProperty ?? {} as RealEstateProperty);
    }, [realEstateProperty]);

    const tableData = [
        {
            label: 'Kaufpreis',
            value: data.purchasePrice || '',
            name: 'purchasePrice',
            formatter: Formatter.formatCurrency,
            editable: true,
            type: 'integerCurrency',
        },
        {
            label: 'Kaufdatum',
            value: data.purchaseDate || '',
            name: 'purchaseDate',
            formatter: Formatter.formatDate,
            type: 'date',
            editable: true,
        },
        {
            label: 'Nutzenlastenwechsel',
            value: data.firstNlwDate || '',
            name: 'firstNlwDate',
            formatter: Formatter.formatDate,
            type: 'date',
            editable: true,
        },
        {
            label: 'Verkehrswertgutachen',
            value: data.marketValueAppraisal || '',
            name: 'marketValueAppraisal',
            formatter: Formatter.formatCurrency,
            type: 'integerCurrency',
            editable: true,
        },
        {
            label: 'Stichtag Verkehrswertgutachen',
            value: data.marketValueAppraisalDate || '',
            name: 'marketValueAppraisalDate',
            formatter: Formatter.formatDate,
            type: 'date',
            editable: true,
        },
        {
            label: 'Ist-Nettokaltmiete (NLW)',
            value: data.rentPrice || '',
            name: 'rentPrice',
            formatter: Formatter.formatCurrency,
            type: 'integerCurrency',
            editable: true,
        },
        {
            label: 'Nettokaufpreisfaktor',
            value: data.purchaseFactor || '',
            name: 'purchaseFactor',
            editable: true,
            type: 'number',
            formatter: Formatter.formatXtimes
        },
        {
            label: 'Baujahr',
            value: data.constructionYear || '',
            name: 'constructionYear',
            type: 'number',
            editable: true,
        },
        {
            label: 'letzte Modernisierung',
            value: data.lastModernizationYear || '',
            name: 'lastModernizationYear',
            type: 'number',
            editable: true,
        },
        {
            label: 'Asset-Klasse',
            value: data.assetClass || '',
            name: 'assetClass',
            id: data.assetClass,
            type: 'select',
            selectOptions: Formatter.formatSelectOptionsFromObject(assetClasses),
            // @ts-ignore
            formatter: () => assetClasses[data.assetClass],
            editable: true,
        },
        {
            label: 'Gebäudestruktur',
            value: data.buildingStructure || '',
            name: 'buildingStructure',
            id: data.buildingStructure,
            type: 'select',
            selectOptions: Formatter.formatSelectOptionsFromObject(buildingStructures),
            // @ts-ignore
            formatter: () => buildingStructures[data.buildingStructure],
            editable: true
        },
        {
            label: 'WE-Nummer',
            value: data.weNumber || '',
            name: 'weNumber',
            editable: true
        },
    ]

    return (
        <EditableCard
            tableData={tableData}
            onSave={props.onSave}
            title={'Objektdaten'}
        />
    );
}
