import React, {ReactElement, useEffect, useState} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Timeline from '@material-ui/lab/Timeline';
import EventTimelineItem from './EventTimelineItem';
import EventTimelineLastItem from './EventTimelineLastItem';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import EventTimelineProps from '../../interfaces/props/EventTimelineProps';
import Event from '../../interfaces/models/Event';

const useStyles = makeStyles(() => ({
    timeline: {
        width: '100%',
        marginBottom: 0,
        paddingBottom: 0,
    },
}));

export default function EventTimeline(props: EventTimelineProps): ReactElement {
    const classes = useStyles();
    const [events, setEvents] = useState<Event[]>([] as Event[]);
    const [showInfinity, setShowInfinity] = useState<boolean | undefined>(false);
    const [shortText, setShortText] = useState<boolean | undefined>(false);

    useEffect(() => {
        setEvents(props.events);
        setShowInfinity(props.showInfinity);
        setShortText(props.shortText);
    }, [props.events, props.showInfinity, props.shortText]);

    return events && events.length > 0 ? (
        <Timeline className={classes.timeline}>
            {
                events.sort((a, b) => (a.date < b.date) ? 1 : -1).map(event => {
                    return (<EventTimelineItem event={event} key={event.id} shortText={shortText}/>);
                })
            }
            {
                showInfinity
                    ? events.length > 0 ? <EventTimelineLastItem/> : null
                    : events.length > 0 ? <EventTimelineLastItem icon={(<CheckCircleOutlineIcon/>)}/> : null
            }
        </Timeline>
    ) : (<div>Noch keine Ereignisse eingetragen</div>);
}
