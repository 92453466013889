import React, {useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import UserCard from './UserCard';
import SettingsCard from './SettingsCard';
import Helper from '../../helpers/Helper';
import ProfileProps from '../../interfaces/props/ProfileProps';

export default function Accounts(props: ProfileProps) {
    const {setMenuItems, setBreadcrumbItems} = props;

    useEffect(() => {
        setMenuItems!([]);
        setBreadcrumbItems!([]);
        Helper.setPageTitle('Profil');
    }, [setMenuItems, setBreadcrumbItems]);

    return (
        <>
            <Grid container spacing={3} alignItems='stretch'>
                <Grid item xs={12} sm={6}>
                    <UserCard />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <SettingsCard />
                </Grid>
            </Grid>
        </>
    );
}
