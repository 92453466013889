import React, {useEffect, useState} from 'react';
import EditableCard from '../UI/Cards/EditableCard';
import UserService from '../../services/UserService';
import Formatter from '../../helpers/Formatter';
import User from '../../interfaces/models/User';

export default function UserCard() {
    const [user, setUser] = useState<User>({} as User);

    useEffect(() => {
        UserService.getProfile().then(user => {
            setUser(user);
        }).catch(() => { });
    }, []);

    const tableData = [
        {
            label: 'E-Mail',
            value: user.email,
            name: 'email',
            editable: false,
        },
        {
            label: 'Vorname',
            value: user.firstName,
            name: 'firstName',
            editable: false,
        },
        {
            label: 'Nachname',
            value: user.lastName,
            name: 'lastName',
            editable: false,
        },
        {
            label: 'Rollen',
            value: user.roles,
            name: 'roles',
            editable: false,
            formatter: (row: any) => Formatter.formatRolesColumn(row, 'roles'),
        },
        {
            label: 'Berechtigungen',
            value: user.permissions,
            name: 'permissions',
            editable: false,
            formatter: (row: any) => Formatter.formatRolesColumn(row, 'permissions'),
        },
    ];

    return (
        <EditableCard
            tableData={tableData}
            title={'Benutzer'}
        />
    );
}
