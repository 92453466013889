import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import EditableTextCard from '../../UI/Cards/EditableTextCard';

export default function ProjectTextarea(props) {
    const [data, setData] = useState({});
    const {project} = props;

    useEffect(() => {
        setData(project);
    }, [project]);

    const handleChange = (event) => {
        const currentData = data;
        currentData[event.target.name] = event.target.value;
        setData(currentData);
    };

    const handleSave = () => {
        let updatedData = {};
        updatedData[props.name] = data[props.name];

        props.onSave(updatedData);
    };

    return (
        <>
            <Grid container spacing={3} alignItems='stretch'>
                <Grid item sm={12}>
                    <EditableTextCard
                        data={data}
                        onChange={handleChange}
                        onSave={handleSave}
                        title={props.title}
                        name={props.name}
                    />
                </Grid>
            </Grid>
        </>
    );
}
