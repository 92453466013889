import React, {useEffect, useState} from 'react';
import EditableCard from '../../UI/Cards/EditableCard';
import Formatter from '../../../helpers/Formatter';
import {useAppContext} from '../../../libs/contextLib';
import AddressDataCardProps from '../../../interfaces/props/AddressDataCardProps';
import RealEstateProperty from '../../../interfaces/models/RealEstateProperty';

export default function AddressDataCard(props: AddressDataCardProps) {
    const {selectOptions} = useAppContext();
    const [data, setData] = useState<RealEstateProperty>({} as RealEstateProperty);
    const [countries, setCountries] = useState({});
    const {realEstateProperty} = props;

    useEffect(() => {
        setData(realEstateProperty ?? {} as RealEstateProperty);
    }, [realEstateProperty]);

    useEffect(() => {
        if (!selectOptions) {
            return;
        }

        if (Object.keys(selectOptions).length === 0) {
            return;
        }

        setCountries(selectOptions.realEstateProperty.countries.available);
    }, [selectOptions]);

    const tableData = [
        {
            label: 'Bezeichnung',
            value: data.name || '',
            id: data.name,
            name: 'name',
            editable: true,
        },
        {
            label: 'Volle Adresse',
            value: data.fullAddress || '',
            id: data.fullAddress,
            name: 'fullAddress',
            hint: 'Wird aus Straße, Hausnummer, Postleitzahl und Stadt zusammengesetzt'
        },
        {
            label: 'Straße',
            value: data.street || '',
            id: data.street,
            name: 'street',
            editable: true,
        },
        {
            label: 'Hausnummer',
            value: data.streetNo || '',
            id: data.streetNo,
            name: 'streetNo',
            editable: true,
        },
        {
            label: 'PLZ',
            value: data.postalCode || '',
            id: data.postalCode,
            name: 'postalCode',
            editable: true,
        },
        {
            label: 'Stadt',
            value: data.city || '',
            id: data.city,
            name: 'city',
            editable: true,
        },
        {
            label: 'Bundesland / Provinz',
            value: data.state || '',
            id: data.state,
            name: 'state',
            editable: true,
        },
        {
            label: 'Land',
            value: data.country || '',
            id: data.country,
            name: 'country',
            type: 'select',
            selectOptions: Formatter.formatSelectOptionsFromObject(countries),
            // @ts-ignore
            formatter: () => countries[data.country],
            editable: true,
        },
    ];

    return (
        <EditableCard
            onSave={props.onSave}
            tableData={tableData}
            title={'Adressdaten'}
        />
    );
}
