import 'date-fns';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';

export default function CustomDatePicker(props) {
    const defaultValue = props.value && moment(props.value).isValid() ? new Date(props.value) : new Date();
    const [selectedDate, setSelectedDate] = React.useState(defaultValue);

    const handleDateChange = (date) => {
        setSelectedDate(date);
        props.onChange({
            target: {
                value: moment(date).format('YYYY-MM-DD'),
                name: props.name,
            },
        });
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                disableToolbar
                format='dd.MM.yyyy'
                margin='normal'
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                size={props.size}
            />
        </MuiPickersUtilsProvider>
    );
}
