import API from '../api/API';
import Role from '../interfaces/models/Role';

export default {
    all: (): Promise<Role[]> => {
        return API.role.get();
    },
    create: (data: object): Promise<Role> => {
        return API.role.post({
            data: data,
        });
    },
    get: (id: number): Promise<Role> => {
        return API.role.get(id);
    },
    delete: (id: number): Promise<string> => {
        return API.role.delete(id);
    },
    updateFromTable: async (id: number, data: Role): Promise<Role> => {
        return API.role.patch({
            url: id,
            data: data,
        });
    },
}
