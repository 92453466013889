import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useAppContext} from '../../../libs/contextLib';
import UserHelper from '../../../helpers/UserHelper';
import DebugHintProps from '../../../interfaces/props/DebugHintProps';
import BugReportIcon from '@material-ui/icons/BugReport';
import SingleValueStorageHelper from '../../../helpers/SingleValueStorageHelper';
import {IconButton, Popover, Typography} from '@material-ui/core';
import Formatter from '../../../helpers/Formatter';

const useStyles = makeStyles(theme => ({
    button: {
        padding: 0,
        height: 13,
    },
    icon: {
        height: 15,
        color: 'orange',
    },
    typography: {
        padding: theme.spacing(1),
    },
}));

export default function DebugHint(props: DebugHintProps) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const {user} = useAppContext();

    const content = (props: DebugHintProps): JSX.Element => {
        return props.id
            ? props.id.match(' ')
                ? (<Typography className={classes.typography} dangerouslySetInnerHTML={{__html: props.id}}/>)
                : (
                    <Typography className={classes.typography}>
                        <p>
                            <b>ID:</b>
                            <br/>
                            {props.id}
                        </p>

                        {
                            props.id !== Formatter.camelToSnake(props.id)
                                ? (
                                    <p>
                                        <b>Snake Case:</b>
                                        <br/>
                                        {Formatter.camelToSnake(props.id)}
                                    </p>
                                )
                                : null
                        }
                    </Typography>
                )
            : (<Typography className={classes.typography}>No ID found</Typography>);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (UserHelper.hasPermission(user, 'debug')
        &&
        SingleValueStorageHelper.get('debugMode') === 'true'
            ? (
                <>
                    <IconButton className={classes.button} onClick={handleClick}>
                        <BugReportIcon className={classes.icon}/>
                    </IconButton>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        {content(props)}
                    </Popover>
                </>
            )
            : null
    );
}
