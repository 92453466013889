import React, {ChangeEvent, ReactElement, useEffect, useState} from 'react';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Avatar from '@material-ui/core/Avatar';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import Paper from '@material-ui/core/Paper';
import Formatter from '../../helpers/Formatter';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import MuiTimelineItem from '@material-ui/lab/TimelineItem';
import ButtonEditOkCancel from '../UI/Partials/ButtonEditOkCancel';
import TextField from '@material-ui/core/TextField';
import EventService from '../../services/EventService';
import DeleteEntry from '../UI/Partials/DeleteEntry';
import EventTimelineItemProps from '../../interfaces/props/EventTimelineItemProps';
import Event from '../../interfaces/models/Event';
import DebugHint from '../UI/Partials/DebugHint';

const useStyles = makeStyles(theme => ({
    timelineContent: {
        marginBottom: 25,
    },
    paper: {
        padding: '6px 16px',
    },
    date: {
        fontSize: theme.typography.fontSize,
        color: theme.palette.grey.A100,
        textAlign: 'right',
    },
    message: {
        fontSize: theme.typography.fontSize,
        marginTop: 10,
    },
    dot: {
        backgroundColor: theme.palette.customBackground.lightGreen,
    },
    avatar: {
        backgroundColor: theme.palette.customBackground.lightGreen,
        height: 30,
        width: 30,
        fontSize: 16,
    },
    editIconRow: {
        textAlign: 'right',
    },
}));

const TimelineItem = withStyles({
    missingOppositeContent: {
        '&:before': {
            display: 'none'
        }
    }
})(MuiTimelineItem);

export default function EventTimelineItem(props: EventTimelineItemProps): ReactElement | null {
    const classes = useStyles();
    const [event, setEvent] = useState<Event>({} as Event);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [updatedData, setUpdatedData] = useState<Event>({} as Event);
    const [shortText, setShortText] = useState<boolean | undefined>(false);

    useEffect(() => {
        setEvent(props.event)
        setShortText(props.shortText)
    }, [props.event, props.shortText]);


    const handleChange = (eventSrc: ChangeEvent<HTMLInputElement>): void => {
        const currentEvent = event;
        // @ts-ignore
        currentEvent[eventSrc.target.name] = eventSrc.target.value;
        setEvent(currentEvent);

        const currentUpdatedData = updatedData;
        // @ts-ignore
        currentUpdatedData[eventSrc.target.name] = eventSrc.target.value;
        setUpdatedData(currentUpdatedData);
    };

    const handleSave = (): void => {
        setEditMode(false);

        if (Object.keys(updatedData).length === 0) {
            return;
        }

        if (!event) {
            return;
        }

        EventService.updateFromTable(event.id, {
            ...updatedData
        }).then(result => {
            setEvent(result);
        }).catch(() => {
        });
    };


    const handleDelete = (): void => {
        if (!event) {
            return;
        }

        EventService.delete(event.id).then(() => {
            setEvent({} as Event);
        }).catch(() => { });
    };

    return event.id ? (
        <TimelineItem key={event.id}>
            <TimelineSeparator>
                <TimelineDot className={classes.dot}>
                    <Avatar className={classes.avatar}>{event.user.firstName[0]}{event.user.lastName[0]}</Avatar>
                </TimelineDot>
                <TimelineConnector/>
            </TimelineSeparator>
            <TimelineContent className={classes.timelineContent}>
                <Paper elevation={2} className={classes.paper}>
                    <div className={classes.date}>
                        {event.user.firstName} {event.user.lastName} | {Formatter.formatDate(event.date)}
                    </div>
                    <div className={classes.message}>
                        {editMode
                            ? (
                                <>
                                    <DeleteEntry
                                        onDelete={handleDelete}
                                        title='Ereignis löschen?'
                                        text='Bist du dir sicher, dass du dieses Ereignis löschen möchtest?'
                                    />
                                    <TextField
                                        id='message'
                                        multiline
                                        rows={10}
                                        defaultValue={event.message || ''}
                                        variant='outlined'
                                        name='message'
                                        onChange={handleChange}
                                        style={{width: '100%', marginTop: 10}}
                                    />
                                </>
                            )
                            : (
                                shortText
                                    ? <div>{event.shortMessage}</div>
                                    : <div style={{whiteSpace: 'pre-line'}}>{event.message}</div>
                            )
                        }

                    </div>
                    <div className={classes.editIconRow}>
                        <DebugHint id={'<b>Event</b> #' + event.id}/>
                        <ButtonEditOkCancel
                            onEdit={() => setEditMode(true)}
                            onCancel={() => setEditMode(false)}
                            onSave={handleSave}
                            editMode={editMode}>
                        </ButtonEditOkCancel>
                    </div>
                </Paper>
            </TimelineContent>
        </TimelineItem>
    ) : null;
}
