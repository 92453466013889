import React, {ReactElement, useEffect, useState} from 'react';
import EditableCard from '../../UI/Cards/EditableCard';
import Project from '../../../interfaces/models/Project';
import CardData from '../../../interfaces/CardData';
import LandRegisterCardProps from '../../../interfaces/props/LandRegisterCardProps';
import LandRegister from '../../../interfaces/models/LandRegister';
import {useAppContext} from '../../../libs/contextLib';
import Formatter from '../../../helpers/Formatter';
import {RenderTypes} from '../../../interfaces/enums/RenderTypes';

export default function LandRegisterOtherCard(props: LandRegisterCardProps): ReactElement {
    const {landRegister} = props;
    const [data, setData] = useState<LandRegister>({} as LandRegister);
    const [statusTypes, setStatusTypes] = useState({});
    const {selectOptions} = useAppContext();

    useEffect(() => {
        if (Object.keys(selectOptions).length === 0) {
            return;
        }

        setStatusTypes(selectOptions.landRegister.status);
    }, [selectOptions]);

    useEffect(() => {
        setData(landRegister);
    }, [landRegister]);

    const statusSelectOptions = () => {
        return Formatter.formatSelectOptionsFromObject(statusTypes);
    };

    const tableData: CardData[] = [
        {
            label: 'Status Grundbuch',
            value: data.status ? data.status : null,
            name: 'status',
            editable: true,
            selectOptions: statusSelectOptions(),
            formatter: (status: string) => Formatter.formatStage(status, statusTypes),
            type: RenderTypes.select,
        },
    ];

    const handleSave = (updatedData: Project): void => {
        setData({
            ...data,
            ...updatedData
        });

        props.onSave(updatedData);
    };

    return (
        <EditableCard
            tableData={tableData}
            onSave={handleSave}
            title={'Sonstiges'}
        />
    );
}
