import React, {useState} from 'react';
import {CardActions, CardHeader} from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import DetailsTable from '../Tables/DetailsTable';
import Card from '@material-ui/core/Card';
import {makeStyles} from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';
import ButtonEditOkCancel from '../Partials/ButtonEditOkCancel';
import ThemeHelper from '../../../helpers/ThemeHelper';

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: '100%',
    },
    card: {
        maxWidth: '100%',
        flexGrow: 1,
        marginTop: 16,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start'
    },
    cardHeader: {
        backgroundColor: ThemeHelper.getColor(theme, theme.palette.cardHeader.backgroundColor),
        color: ThemeHelper.getColor(theme, theme.palette.cardHeader.color),
    },
    cardHeaderSecondary: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    }
}));

export default function EditableCard(props) {
    const classes = useStyles();
    const [editMode, setEditMode] = useState(false);
    const [data, setData] = useState({});

    const fieldsWithNegativeValues = [
        'rentalLoss',
        'operatingCosts',
        'propertyManagementCosts',
        'assetManagementCosts',
        'maintenanceCosts',
        'interestPayments',
        'acquittancePayments',
        'maintenanceReserveFunds',
    ];

    const handleChange = (event) => {
        const currentData = data;
        if (fieldsWithNegativeValues.includes(event.target.name) && parseFloat(event.target.value) > 0) {
            event.target.value = (0 - parseFloat(event.target.value));
        }
        currentData[event.target.name] = event.target.value;
        setData(currentData);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSave();
        }
    };

    const handleSave = () => {
        setEditMode(false);

        // Check if things have changed.
        if (Object.keys(data).length === 0) {
            return;
        }

        if (props.id) {
            const updatedData = {
                ...data,
                id: props.id,
            }

            props.onSave(updatedData)
        } else {
            props.onSave(data);
        }
    };

    return (
        <Card className={classes.card} style={{height: '100%'}}>
            <CardHeader
                title={props.title}
                className={props.headerStyle === 'secondary' ? classes.cardHeaderSecondary : classes.cardHeader}
                action={
                    props.tableData.filter(element => element.editable).length !== 0 && (
                        <ButtonEditOkCancel
                            onEdit={() => setEditMode(true)}
                            onCancel={() => setEditMode(false)}
                            onSave={handleSave}
                            editMode={editMode}
                            headerStyle={props.headerStyle}
                        >
                        </ButtonEditOkCancel>
                    )
                }
            >
                {props.headerImage && (
                    <CardMedia
                        component='img'
                        alt='Projekt Bild'
                        height='140'
                        image={props.headerImage}
                    />
                )}
            </CardHeader>
            <CardContent className={classes.root}>
                <DetailsTable
                    data={props.tableData}
                    editMode={editMode}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                />
            </CardContent>
            {props.cardActions && (
                <CardActions>
                    {(props.cardActions)}
                </CardActions>
            )}
        </Card>
    );
}
