import React, {useCallback, useEffect, useRef, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Formatter from '../../../helpers/Formatter';
import EditableSmallTable from '../../UI/Tables/EditableSmallTable';
import EditableTextCard from '../../UI/Cards/EditableTextCard';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import ButtonEditOkCancel from '../../UI/Partials/ButtonEditOkCancel';
import Helper from '../../../helpers/Helper';
import {useTheme} from '@material-ui/core/styles';
import ThemeHelper from '../../../helpers/ThemeHelper';
import PropertyDetailsProps from '../../../interfaces/props/PropertyDetailsProps';
import RealEstateProperty from '../../../interfaces/models/RealEstateProperty';

export default function PropertyDetails(props: PropertyDetailsProps) {
    const theme = useTheme();
    const [data, setData] = useState<RealEstateProperty>({} as RealEstateProperty);
    const [updatedData, setUpdatedData] = useState<RealEstateProperty>({} as RealEstateProperty);
    const [editMode, setEditMode] = useState(false);
    const {realEstateProperty} = props || {};

    const chartRef = useRef()

    const pieColors = () => {
        let colors = [];
        // @ts-ignore
        let base = Highcharts.getOptions().colors[0];
        let i;

        for (i = 0; i < 10; i += 1) {
            colors.push(Highcharts.color(base).brighten((i - 3) / 7).get());
        }

        return colors;
    }

    const [chartOptions, setChartOptions] = useState({
        chart: {
            type: 'pie',
            backgroundColor: 'transparent',
        },
        title: {
            style: {
                // @ts-ignore
                color: ThemeHelper.getColor(theme, theme.palette.chart.title),
            },
            text: 'Wohnflächenverteilung'
        },
        subtitle: {
            style: {
                // @ts-ignore
                color: ThemeHelper.getColor(theme, theme.palette.chart.subtitle),
            },
            text: 'Nutzfläche insgesamt: ' + Helper.fallback(realEstateProperty.totalUsableArea) + 'm&sup2;',
            useHTML: true,
        },
        series: [{
            data:             [
                { name: 'Wohnen', y: Helper.fallback(realEstateProperty.typeOfUseResidentialPercentage) },
                { name: 'Büro', y: Helper.fallback(realEstateProperty.typeOfUseOfficePercentage) },
                { name: 'Gewerbefläche Gastronomie', y: Helper.fallback(realEstateProperty.typeOfUseGastronomyPercentage) },
                { name: 'Gewerbefläche Einzelhandel', y: Helper.fallback(realEstateProperty.typeOfUseRetailPercentage) },
                { name: 'Lager, Archiv, Stellplätze', y: Helper.fallback(realEstateProperty.typeOfUseStockPercentage) },
                { name: 'Leerstand', y: Helper.fallback(realEstateProperty.typeOfUseEmptyPercentage) },
                { name: 'Sonstiges', y: Helper.fallback(realEstateProperty.typeOfUseGeneralPercentage) }
            ]
        }],
        tooltip: {
            pointFormat: '{series.name}: <b>{point.y}%</b>'
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                colors: pieColors(),
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.y} %'
                }
            }
        },
    });

    const updateSeries = useCallback(() => {
        setChartOptions({
            series: [
                { data:[
                        { name: 'Wohnen', y: Helper.fallback(realEstateProperty.typeOfUseResidentialPercentage) },
                        { name: 'Büro', y: Helper.fallback(realEstateProperty.typeOfUseOfficePercentage) },
                        { name: 'Gewerbefläche Gastronomie', y: Helper.fallback(realEstateProperty.typeOfUseGastronomyPercentage) },
                        { name: 'Gewerbefläche Einzelhandel', y: Helper.fallback(realEstateProperty.typeOfUseRetailPercentage) },
                        { name: 'Lager, Archiv, Stellplätze', y: Helper.fallback(realEstateProperty.typeOfUseStockPercentage) },
                        { name: 'Leerstand', y: Helper.fallback(realEstateProperty.typeOfUseEmptyPercentage) },
                        { name: 'Sonstiges', y: Helper.fallback(realEstateProperty.typeOfUseGeneralPercentage) }
                    ]
                }
            ],
            // @ts-ignore
            subtitle: {
                text: 'Nutzfläche insgesamt: ' + Helper.fallback(realEstateProperty.totalUsableArea) + 'm&sup2;'
            }
        });

        // @ts-ignore
        chartRef.current.chart.redraw();
    }, [realEstateProperty])

    useEffect(() => {
        setData(realEstateProperty ?? {});
        updateSeries();
    }, [realEstateProperty, updateSeries]);

    const handleChange = (event: any) => {
        const currentData = data;
        // @ts-ignore
        currentData[event.target.name] = event.target.value;
        setData(currentData);

        const currentUpdatedData = updatedData;
        // @ts-ignore
        currentUpdatedData[event.target.name] = event.target.value;
        setUpdatedData(currentUpdatedData);
    };

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            handleSave();
        }

        if (event.key === 'Escape') {
            setEditMode(false);
        }
    }

    const handleSave = () => {
        setEditMode(false);
        props.onSave!(updatedData);
    }

    const propertyAreaRows = [
        {
            label: 'Vermietbare Fläche laut Kaufvertrag (in m²)',
            value: data.grossFloorArea || '',
            name: 'grossFloorArea',
            editable: true,
            formatter: Formatter.formatSquareMeters
        },
        {
            label: 'Umbauter Raum (in m²)',
            value: data.buildingVolume || '',
            name: 'buildingVolume',
            editable: true,
            formatter: Formatter.formatSquareMeters
        },
        {
            label: 'Grundstücksgröße (in m²)',
            value: data.propertyArea || '',
            name: 'propertyArea',
            editable: true,
            formatter: Formatter.formatSquareMeters
        },
    ];

    const propertyUnitsRows = [
        {
            label: 'Anzahl Wohnungen',
            value: data.livingUnits || '',
            name: 'livingUnits',
            editable: true,
            type: 'number',
        },
        {
            label: 'Gewerbeeinheiten',
            value: data.commercialUnits || '',
            name: 'commercialUnits',
            editable: true,
            type: 'number',
        },
        {
            label: 'Stellplätze draußen',
            value: data.parkingLotsOutside || '',
            name: 'parkingLotsOutside',
            editable: true,
            type: 'number',
        },
        {
            label: 'Stellplätze Garage',
            value: data.parkingLotsInside || '',
            name: 'parkingLotsInside',
            editable: true,
            type: 'number',
        },
    ];

    const houseAreaRows = [
        {
            label: 'Wohnfläche (in m²)',
            value: data.livingArea || '',
            name: 'livingArea',
            editable: true,
            formatter: Formatter.formatSquareMeters
        },
        {
            label: 'Bürofläche (in m²)',
            value: data.officeArea || '',
            name: 'officeArea',
            editable: true,
            formatter: Formatter.formatSquareMeters
        },
        {
            label: 'Gewerbefläche Gastronomie (in m²)',
            value: data.gastronomyArea || '',
            name: 'gastronomyArea',
            editable: true,
            formatter: Formatter.formatSquareMeters
        },
        {
            label: 'Gewerbefläche Einzelhandel (in m²)',
            value: data.retailArea || '',
            name: 'retailArea',
            editable: true,
            formatter: Formatter.formatSquareMeters
        },
        {
            label: 'Lager, Archiv, Stellplätze (in m²)',
            value: data.stockArea || '',
            name: 'stockArea',
            editable: true,
            formatter: Formatter.formatSquareMeters
        },
        {
            label: 'Leerstand (in m²)',
            value: data.emptyArea || '',
            name: 'emptyArea',
            editable: true,
            formatter: Formatter.formatSquareMeters
        },
        {
            label: 'Sonstiges (in m²)',
            value: data.otherArea || '',
            name: 'otherArea',
            editable: true,
            formatter: Formatter.formatSquareMeters
        },
    ];

    return (
        <>
        <Grid
            justify={'flex-start'}
            container
            spacing={2}
            style={{margin: '15px 0 20px 0'}}
        ><Grid item>
            <Typography variant={'h4'} component={'h2'}>
                Grundstücksdaten
            </Typography>
        </Grid>
            <Grid item style={{display: 'flex'}}>
                <div style={{display: 'flex'}}>
                    <ButtonEditOkCancel
                        onEdit={() => setEditMode(true)}
                        onCancel={() => setEditMode(false)}
                        onSave={handleSave}
                        editMode={editMode}>
                    </ButtonEditOkCancel>
                </div>
            </Grid>
        </Grid>
        <Grid container spacing={3} alignItems='stretch'>
            <Grid item sm={4}>
                <EditableSmallTable
                    rows={propertyAreaRows}
                    editMode={editMode}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                />
                <br/>
                <EditableSmallTable
                    rows={propertyUnitsRows}
                    onChange={handleChange}
                    editMode={editMode}
                    onKeyDown={handleKeyDown}
                />
            </Grid>
            <Grid item sm={4}>
                <EditableSmallTable
                    rows={houseAreaRows}
                    editMode={editMode}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                />
            </Grid>
            <Grid item sm={4}>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOptions}
                    // @ts-ignore
                    ref={chartRef}
                    allowChartUpdate={true}
                />
            </Grid>
            <Grid item sm={6}>
                <EditableTextCard
                    data={data}
                    onChange={handleChange}
                    onSave={() => props.onSave!(updatedData)}
                    title={'Informationskasten Sanierungen/Renovierungen/Modernisierungen'}
                    name={'lastModernizationInfo'}
                />
            </Grid>
            <Grid item sm={6}>
                <EditableTextCard
                    data={data}
                    onChange={handleChange}
                    onSave={() => props.onSave!(updatedData)}
                    title={'Ausstattung'}
                    name={'facilities'}
                />
            </Grid>
        </Grid>
        </>
    );
}
