import React, {ReactElement} from 'react';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import DeleteEntryProps from '../../../interfaces/props/DeleteEntryProps';
import {useAppContext} from '../../../libs/contextLib';
import UserHelper from '../../../helpers/UserHelper';

const useStyles = makeStyles(theme => ({
    button: {
        // @ts-ignore
        background: theme.palette.delete,
        color: 'white'
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    // @ts-ignore
    return <Slide direction='up' ref={ref} {...props} />;
});

export default function DeleteEntry(props: DeleteEntryProps): ReactElement | null {
    const classes = useStyles();
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const {user} = useAppContext();

    const handleDelete = (): void => {
        setDeleteOpen(false);
        props.onDelete();
    }

    const handleClickDeleteOpen = (): void => {
        setDeleteOpen(true);
    };

    const handleDeleteClose = (): void => {
        setDeleteOpen(false);
    };

    return UserHelper.hasPermission(user, 'edit')
        ? (
            <>
                <Grid
                    container
                    direction='row'
                    justify='flex-end'
                    alignItems='flex-start'
                >
                    <Button
                        variant='contained'
                        className={classes.button}
                        startIcon={<DeleteIcon/>}
                        onClick={handleClickDeleteOpen}
                    >
                        Löschen
                    </Button>
                </Grid>

                <Dialog
                    open={deleteOpen}
                    // @ts-ignore
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleDeleteClose}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                >
                    <DialogTitle id='alert-dialog-title'>{props.title || 'Eintrag löschen?'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id='alert-dialog-description'>
                            {props.text || 'Bist du dir sicher, dass du diesen Eintrag löschen möchtest?'}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDeleteClose} color='primary' autoFocus>
                            Abbrechen
                        </Button>
                        <Button
                            variant='contained'
                            onClick={handleDelete}
                            className={classes.button}
                            startIcon={<DeleteIcon/>}
                        >
                            Löschen
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        ) : null;
}
