import API from '../api/API';
import RealEstateProperty from '../interfaces/models/RealEstateProperty';

export default {
    get: (id: number): Promise<RealEstateProperty> => {
        return API.realEstateProperty.get(id);
    },
    create: (data: object): Promise<RealEstateProperty> => {
        return API.realEstateProperty.post({
            data: data,
        });
    },
    update: (id: number | undefined, data: RealEstateProperty): Promise<any> => {
        return API.realEstateProperty.patch({
            url: id,
            data: data,
        });
    },
    delete: (id: number): Promise<string> => {
        return API.realEstateProperty.delete(id);
    },
    upload: (id: number, urlSuffix: string, data: object): Promise<string> => {
        return API.realEstateProperty.post({
            url:  `/${id}/${urlSuffix}`,
            data: data,
            fileUpload: true
        });
    },
}
