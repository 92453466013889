import API from '../api/API';
import User from '../interfaces/models/User';

export default {
    all: (): Promise<User[]> => {
        return API.user.get();
    },
    allShort: (): Promise<string> => {
        return API.user.get('/all');
    },
    create: (data: object): Promise<User> => {
        return API.user.post({
            data: data,
        });
    },
    get: (id: number): Promise<User> => {
        return API.user.get(id);
    },
    getProfile: (): Promise<User> => {
        return API.user.get('/profile');
    },
    delete: (id: number): Promise<string> => {
        return API.user.delete(id);
    },
    updateFromTable: async (id: number, data: User): Promise<User> => {
        return API.user.patch({
            url: id,
            data: data,
        });
    },
    auth: (token: string): void => {
        localStorage.setItem('access-token', token);
    },
    currentSession: (): string | null => {
        return localStorage.getItem('access-token');
    },
    logout: (): void => {
        localStorage.removeItem('access-token');
    },
}
