import React, {useEffect, useState} from 'react';
import Formatter from '../../../helpers/Formatter';
import EditableCard from '../../UI/Cards/EditableCard';
import AccountService from '../../../services/AccountService';
import {useAppContext} from '../../../libs/contextLib';

export default function CompanyBaseInformationCard(props) {
    const {selectOptions} = useAppContext();
    const [data, setData] = useState([]);
    const [countries, setCountries] = useState({});
    const {account} = props;

    useEffect(() => {
        setData(account);
    }, [account]);

    useEffect(() => {
        if (Object.keys(selectOptions).length === 0) {
            return;
        }

        setCountries(selectOptions.realEstateProperty.countries.available);
    }, [selectOptions]);

    const handleSave = (updatedData) => {
        setData({
            ...data,
            ...updatedData
        });

        return AccountService.updateFromTable(data.id, {
            ...updatedData,
        }).catch(() => { });
    };

    const tableData = [
        {
            label: 'Name',
            value: data.companyName,
            id: data.companyName,
            name: 'companyName',
            editable: true,
        },
        {
            label: 'Straße',
            value: data.street,
            id: data.street,
            name: 'street',
            editable: true,
        },
        {
            label: 'Hausnummer',
            value: data.streetNo,
            id: data.streetNo,
            name: 'streetNo',
            editable: true,
        },
        {
            label: 'PLZ',
            value: data.postalCode,
            id: data.postalCode,
            name: 'postalCode',
            editable: true,
        },
        {
            label: 'Stadt',
            value: data.city,
            id: data.city,
            name: 'city',
            editable: true,
        },
        {
            label: 'Land',
            value: data.country || '',
            id: data.country,
            name: 'country',
            type: 'select',
            selectOptions: Formatter.formatSelectOptionsFromObject(countries),
            formatter: () => countries[data.country],
            editable: true,
        },
        {
            label: 'URL',
            value: data.url,
            name: 'url',
            editable: true,
            formatter: Formatter.formatUrl,
        },
        {
            label: 'erstellt am',
            value: data.createdAt,
            id: data.createdAt,
            name: 'createdAt',
            editable: false,
            formatter: Formatter.formatDateTime
        },
        {
            label: 'zuletzt geändert am',
            value: data.updatedAt,
            id: data.updatedAt,
            name: 'updatedAt',
            editable: false,
            formatter: Formatter.formatDateTime,
        },
    ];

    return (
        <EditableCard
            tableData={tableData}
            onSave={handleSave}
            title={'Allgemeine Accountinformationen'}
        />
    );
}
