import moment from 'moment';
import Link from '@material-ui/core/Link';
import React from 'react';
import LinkIcon from '@material-ui/icons/Link';
import Helper from './Helper';
import CustomChip from '../components/UI/Chip/CustomChip';
import {Link as RouterLink} from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Render from "./Render";

const LOCALE_GERMANY = Helper.getLocale();
const STYLE_CURRENCY = 'currency';
const CURRENCY_EURO = 'EUR';
const DATE_FORMAT_GERMAN = 'DD.MM.YYYY';
const TIME_FORMAT_GERMAN = 'HH:mm';

const Formatter = {
    formatAccountLink: (data) => {
        if (!data) {
            return;
        }

        return (
            <Link component={RouterLink} to={`/account/${data.id}`}>{data.name}</Link>
        );
    },
    formatFactor: number => {
        return number ? `${number.toLocaleString(LOCALE_GERMANY)}x` : '';
    },
    formatCurrency: (number) => {
        number = parseFloat(number);
        if (!isNaN(number)) {
            return number.toLocaleString(LOCALE_GERMANY, {
                style: STYLE_CURRENCY,
                currency: CURRENCY_EURO,
            }).replace(',00', '');
        }

        return Render.defaultValue();
    },
    formatSquareMeters: value => {
        return value || value === 0 ? value.toLocaleString(LOCALE_GERMANY) + ' \u33A1' : null;
    },
    formatPercentage: number => {
        return number || number === 0 ? `${number.toLocaleString(LOCALE_GERMANY, {
            maximumFractionDigits: 2,
        })} %` : null;
    },
    formatDateTime: input => {
        const date = moment(input).isValid() ? moment(input, 'YYYY-MM-DD').format(DATE_FORMAT_GERMAN) : '';
        const time = moment(input).isValid() ? moment(input, 'YYYY-MM-DD HH:mm:ii').format(TIME_FORMAT_GERMAN) : '';

        return `${date}, um ${time} Uhr`;
    },
    formatDate: date => {
        return moment(date).isValid() ? moment(date, 'YYYY-MM-DD').format(DATE_FORMAT_GERMAN) : '';
    },
    formatOwnerName: (row, field) => {
        return row[field] ? row[field].fullName : '';
    },
    formatId: row => {
        if (!row) {
            return;
        }

        return row.id ? row.id.toString().padStart(5, '0') : null;
    },
    formatIdColumn: (row, link) => {
        if (!row) {
            return;
        }

        return (
            <Link component={RouterLink} to={`/${link}/${row.id}`}>{Formatter.formatId(row)}</Link>
        );
    },
    formatNameColumn: (row, link) => {
        if (!row) {
            return;
        }

        return (
            <Link component={RouterLink} to={`/${link}/${row.id}`}>{row.name}</Link>
        );
    },
    formatSelectOptions: data => {
        if (!data) {
            return [];
        }

        let options = [];
        data.forEach(item => {
            options.push({value: item.id, label: item.fullName});
        });

        return options;
    },
    formatSelectOptionsFromObject: object => {
        let options = [];
        Object.keys(object).forEach(key => {
            options.push({value: key, label: object[key]});
        });

        return options;
    },
    formatAccountSelectOption: account => {
        if (!account) {
            return null;
        }

        return {
            value: account.id,
            label: account.name || '',
        };
    },
    formatLegalSetup: (legalSetup, legalSetups) => {
        return legalSetups[legalSetup];
    },
    formatFinancingEntityBondType: (bondType, bondTypes) => {
        return bondTypes[bondType];
    },
    formatStage: (stage, stages) => {
        return stages[stage];
    },
    formatCamelCaseToRoute: str => {
        return str.replace(/([A-Z])/g, function($1){return '-'+$1.toLowerCase();});
    },
    formatXtimes: number => {
        return number ? number + ' fach' : '';
    },
    formatBooleanYesNo: value => {
        return value ? 'Ja' : 'Nein'
    },
    formatUrl: value => {
        return value
            ?  Helper.validLink(value) ? <Link href={value} target='_blank'><LinkIcon style={{marginBottom:-6, marginRight:5}}/>{value}</Link> : value
            : ''
    },
    formatRolesColumn: (row, field) => {
        if (!row) {
            return '';
        }

        let data = row;

        if (field) {
            if (field in row) {
                data = row[field];
            }
        }

        return data.map(item => {
            return Formatter.formatRoleColumn(typeof item === 'string' ? item : item.name, field);
        });
    },
    formatRoleColumn: (value, field) => {
        return (
            <CustomChip
                value={value}
                color={field === 'roles' ? 'secondary' : 'primary'}
                key={value}
            />
        );
    },
    formatYears: number => {
        return number ? number.toLocaleString(LOCALE_GERMANY) + ` ${(number === 1 ? 'Jahr' : 'Jahre')}` : '';
    },
    formatRiskClass: (riskClass) => {
        const backgroundColor = {
          'AAA': '#b6c1cf',
          'AA': '#274a7f',
          'A': '#1e73be',
          'B': '#82bc24',
          'C': '#c1da00',
          'D': '#fae100',
          'E': '#fba609',
        };
        const style = {
            width: 30,
            height:30,
            fontSize:14,
            backgroundColor: backgroundColor[riskClass],
            float: 'right',
        }
        return riskClass ? (<Avatar style={style}>{riskClass}</Avatar>) : '';
    },
    camelToSnake: (id) => {
        return id.replace(/[\w]([A-Z])/g, function (m) {
            return m[0] + '_' + m[1];
        }).toLowerCase();
    },
    formatQuarter: number => {
        return number ? `Q${number}` : '';
    },
};

export default Formatter;
