import React, {useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddModal from '../Dialogs/Account/AddModal';
import AccountService from '../../../services/AccountService';
import useDebouncedEffect from 'use-debounced-effect';
import {useAppContext} from '../../../libs/contextLib';
import PersonService from '../../../services/PersonService';
import CompanyService from '../../../services/CompanyService';

const filter = createFilterOptions();

export default function AccountSelect(props) {
    const {selectOptions} = useAppContext();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState([]);
    const [value, setValue] = useState(props.formatter(props.defaultValue));
    const [modalOpen, setModalOpen] = useState(false);
    const [countries, setCountries] = useState({});
    const [prefilledAccount, setPrefilledAccount] = useState({});
    const [service, setService] = useState(null);

    useEffect(() => {
        if (props.accountType === 'person') {
            setService(PersonService)
        } else if (props.accountType === 'company') {
            setService(CompanyService)
        } else {
            setService(AccountService)
        }
    }, [props.accountType]);


    useDebouncedEffect(() => {
        if (!inputValue) {
            return;
        }

        setLoading(true);

        (async () => {
            const response = await service.query(inputValue);

            let data = [];
            response.forEach(account => {
                data.push(props.formatter(account));
            });

            data.sort((a, b) => (a.label > b.label) ? 1 : -1);

            if (props.noneValue) {
                data.unshift({value: '', label: props.noneValue});
            }

            let optionsGrouped = data.map((option) => {
                const firstLetter = option.label && Array.isArray(option.label) ? option.label[0].toUpperCase() : '0';
                return {
                    firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
                    ...option,
                };
            });

            optionsGrouped = optionsGrouped.sort(function (a, b) {
                return a.firstLetter.localeCompare(b.firstLetter);
            });

            setOptions(optionsGrouped);
            setLoading(false);
        })();

    }, 500, [inputValue]);

    const handleInputChange = (event, value) => {
        setInputValue(value);
    };

    const setPrefilledAccountHelper = (value) => {
        if (props.accountType === 'person') {
            setPrefilledAccount({
                type: 'person',
                firstName: value,
            });
        } else {
            setPrefilledAccount({
                type: 'company',
                companyName: value,
            });
        }
    };

    const handleChange = (event, item) => {
        if (!item) {
            setValue(null);
            props.onChange({
                name: props.name,
                value: null,
                target: {
                    name: props.name,
                    value: null,
                }
            });
        } else if (typeof item === 'string') {
            setModalOpen(true);
            setPrefilledAccountHelper(item);
        } else if (item && item.inputValue) {
            setModalOpen(true);
            setPrefilledAccountHelper(item.inputValue);
        } else {
            setValue(item);
            props.onChange({
                name: props.name,
                value: item.value,
                target: {
                    name: props.name,
                    value: item.value,
                }
            });
        }
    };

    useEffect(() => {
        if (Object.keys(selectOptions).length === 0) {
            return;
        }

        setCountries(selectOptions.realEstateProperty.countries.available);
    }, [selectOptions]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    const handleModalSave = (account) => {
        setModalOpen(false);

        AccountService.create(account).then((response) => {
            setValue(props.formatter(response));

            props.onChange({
                name: props.name,
                value: response.id,
                target: {
                    name: props.name,
                    value: response.id,
                }
            });
        }).catch(() => {
        });
    }

    const handleModalClose = () => {
        setModalOpen(false);
    }

    return (
        <>
            <Autocomplete
                id={props.name}
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                value={value}
                onInputChange={handleInputChange}
                onChange={handleChange}
                groupBy={(option) => option.firstLetter}
                getOptionSelected={(option, value) => option.value === value.value}
                getOptionLabel={(option) => option.label}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    if (filtered.length === 0 && params.inputValue !== '') {
                        filtered.push({
                            inputValue: params.inputValue,
                            label: `"${params.inputValue}" erstellen`,
                        });
                    }
                    return filtered;
                }}
                options={options}
                loading={loading}
                freeSolo
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant='outlined'
                        autoComplete='off'
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color='inherit' size={20}/> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
            <AddModal
                open={modalOpen}
                countries={countries}
                onClose={handleModalClose}
                onSave={handleModalSave}
                account={prefilledAccount}
            />
        </>
    );
}
