import API from '../api/API';
import PlannedMeasure from '../interfaces/models/PlannedMeasure';

export default {
    create: (data: object): Promise<PlannedMeasure> => {
        return API.plannedMeasure.post({
            data: data,
        });
    },
    update: (id: number, data: PlannedMeasure): Promise<PlannedMeasure> => {
        return API.plannedMeasure.patch({
            url: id,
            data: data,
        });
    },
    delete: (id: number): Promise<string> => {
        return API.plannedMeasure.delete(id);
    },
}
