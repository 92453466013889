import React, {useEffect, useState} from 'react';
import Formatter from '../../helpers/Formatter';
import BaseTable from '../UI/Tables/BaseTable';
import UserService from '../../services/UserService';
import RoleService from '../../services/RoleService';
import PermissionService from '../../services/PermissionService';
import Search from '../../helpers/Search';

export default function UsersTable(props) {
    const [data, setData] = useState([]);
    const [roles, setRoles] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const {setLoader} = props;

    useEffect(() => {
        setLoader(true);

        UserService.all().then(response => {
            response = response || [];
            setData(response);
            setLoader(false);
        }).catch(() => {
            setLoader(false);
        });

        RoleService.all().then(response => {
            response = response || [];
            setRoles(response);
        }).catch(() => { });

        PermissionService.all().then(response => {
            response = response || [];
            setPermissions(response);
        }).catch(() => { });
    }, [setLoader]);

    const columns = [
        {
            title: 'ID',
            id: 'id',
            editable: 'never',
            render: row => Formatter.formatId(row),
            width: '20px',
        },
        {
            title: 'Vorname',
            field: 'firstName',
        },
        {
            title: 'Nachname',
            field: 'lastName',
        },
        {
            title: 'E-Mail',
            field: 'email',
        },
        {
            title: 'Rollen',
            field: 'roles',
            render: row => Formatter.formatRolesColumn(row, 'roles'),
            customFilterAndSearch: (term, rowData) => Search.searchRolesColumn(term, rowData['roles']),
            type: 'multiSelect',
            options: roles,
        },
        {
            title: 'Explizite Berechtigungen',
            field: 'permissions',
            render: row => Formatter.formatRolesColumn(row, 'permissions'),
            customFilterAndSearch: (term, rowData) => Search.searchRolesColumn(term, rowData['permissions']),
            type: 'multiSelect',
            options: permissions,
        },
        {
            title: 'Alle Berechtigungen',
            field: 'allPermissions',
            render: row => Formatter.formatRolesColumn(row, 'allPermissions'),
            editable: false,
        },
    ];

    const handleDelete = model => {
        return UserService.delete(model.id);
    };

    const handleSave = model => {
        return UserService.create(model);
    }

    const handleUpdate = model => {
        return UserService.updateFromTable(model.id, model);
    };

    return (
        <>
            <BaseTable
                tableName='usersTable'
                columns={columns}
                data={data}
                onAdd={handleSave}
                onDelete={handleDelete}
                onUpdate={handleUpdate}
                onUpdateDisableDiff={true}
                enableRowAdd={true}
                title='Benutzer'
                objects='Benutzer'
            />
        </>
    );
}
