import * as React from 'react';
import {useEffect, useState} from 'react';
import Sidebar from './components/UI/Sidebar';
import {HashRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import {ThemeProvider} from '@material-ui/core/styles';
import routes from './routes/routes';
import redirects from './routes/redirects';
import Login from './components/Login';
import {AppContext} from './libs/contextLib';
import EventSystem from './EventSystem';
import {useSnackbar} from 'notistack';
import UserService from './services/UserService';
import Breadcrumb from './components/UI/Breadcrumb';
import DarkModeHelper from './helpers/DarkModeHelper';
import CommonService from './services/CommonService';
import Theme from './Theme';

let hasRequestedProfile = false;
let hasLoadedOptions = false;

const App = () => {
    const [user, setUser] = useState(null);
    const [menuItems, setMenuItems] = useState([]);
    const [breadcrumbItems, setBreadcrumbItems] = useState([]);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isAuthenticating, setIsAuthenticating] = useState(true);
    const [darkState, setDarkState] = useState(DarkModeHelper.getDarkModeStatus());
    const [selectOptions, setSelectOptions] = useState({});
    const {enqueueSnackbar} = useSnackbar();
    const paletteType = darkState ? 'dark' : 'light';

    const theme = Theme.createTheme(paletteType);

    useEffect(() => {
        if (hasLoadedOptions || !isAuthenticated) {
            return;
        }

        hasLoadedOptions = true;

        CommonService.option().then(result => {
            setSelectOptions(result);
        }).catch(() => { });
    }, [isAuthenticated]);

    const handleThemeChange = () => {
        const newState = !darkState;
        setDarkState(newState);
        DarkModeHelper.setDarkModeStatus(newState ? 'true' : 'false');
    };

    const showNotification = (data) => {
        enqueueSnackbar(data[0], data[1]);
    }

    function loggedIn() {
        if (hasRequestedProfile) {
            return;
        }

        hasRequestedProfile = true;

        UserService.getProfile().then(user => {
            setUser(user);
        }).catch(() => { });
    }

    EventSystem.subscribe('notification', showNotification);
    EventSystem.subscribe('loggedIn', loggedIn);

    async function onLoad() {
        const userIsAuthenticated = await UserService.currentSession();

        if (userIsAuthenticated) {
            setIsAuthenticated(true);
        }

        setIsAuthenticating(false);
    }

    useEffect(() => {
        onLoad();
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            loggedIn();
        }
    }, [isAuthenticated]);

    return (
        !isAuthenticating &&
        <AppContext.Provider value={{ setIsAuthenticated, handleThemeChange, selectOptions, user}}>
            <Router>
                <ThemeProvider theme={theme}>
                    <div className='App'>
                        {
                            isAuthenticated
                            ? (<Sidebar
                                    menuItems={menuItems}
                                    user={user}
                                    >
                                    <Breadcrumb
                                        items={breadcrumbItems}
                                    />
                                    <Switch>
                                        {redirects.map(redirect => {
                                            return (
                                                <Route path={redirect.path} exact={redirect.exact} key={redirect.path}>
                                                    <Redirect to={redirect.to} />
                                                </Route>
                                            )
                                        })}
                                        <Route exact path='/login'>
                                            {
                                                isAuthenticated
                                                    ? (<Redirect to="/projects/" />)
                                                    : (<Login />)
                                            }
                                        </Route>
                                        {routes.map(route => {
                                            return (
                                                <Route path={route.path} exact={route.exact} key={route}>
                                                    <route.component
                                                        setMenuItems={setMenuItems}
                                                        setBreadcrumbItems={setBreadcrumbItems}
                                                        />
                                                </Route>
                                            )
                                        })}
                                    </Switch>
                                </Sidebar>)
                            : (<Login/>)
                        }
                    </div>
                </ThemeProvider>
            </Router>
        </AppContext.Provider>
    );
};

export default App;
