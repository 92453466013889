import Account from '../interfaces/models/Account';
import Project from '../interfaces/models/Project';
import FilterRow from '../interfaces/FilterRow';

export default {
    searchAccountCountry: (term: string, row: Account): RegExpMatchArray | null => {
        if (!row.country) {
            return null;
        }

        if (!Array.isArray(term)) {
            return row.country.toLowerCase().match(term.toLowerCase());
        }

        return row.country.match(term.join('|'));
    },
    searchRolesColumn: (term: string, row: Array<FilterRow>): boolean => {
        if (!row) {
            return false;
        }

        if (row.length === 0) {
            return false;
        }

        const pattern: RegExp = new RegExp(term.toLowerCase());

        return row.filter((item: FilterRow) => item.name.toLowerCase().match(pattern)).length > 0;
    },
    searchProjectCountry: (term: string, row: Project): RegExpMatchArray | null => {
        if (!row.countries) {
            return null;
        }

        if (!Array.isArray(term)) {
            return row.countries.toLowerCase().match(term.toLowerCase());
        }

        return row.countries.match(term.join('|'));
    },
}
