import React, {useEffect, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import Formatter from '../../../helpers/Formatter';
import RealEstatePropertyService from '../../../services/RealEstatePropertyService';
import BaseTable from '../../UI/Tables/BaseTable';

export default function RealEstatePropertiesTable(props) {
    const [tableData, setTableData] = useState([]);

    const match = useRouteMatch();

    const {realEstateProperties} = props;

    useEffect(() => {
        if (!realEstateProperties) {
            return;
        }

        setTableData(realEstateProperties);
    }, [realEstateProperties]);

    const columns = [
        {
            title: 'ID',
            field: 'id',
            render: row => Formatter.formatIdColumn(row, 'real-estate-property'),
            editable: 'never',
            filtering: false,
            width: '65px',
        },
        {
            title: 'Name',
            field: 'name',
        },
        {
            title: 'Straße',
            id: 'street',
            field: 'street',
        },
        {
            title: 'Hausnr.',
            id: 'streetNo',
            field: 'streetNo',
        },
        {
            title: 'PLZ',
            id: 'postalCode',
            field: 'postalCode',
        },
        {
            title: 'Stadt',
            id: 'city',
            field: 'city',
        },
        {
            title: 'Kaufpreis',
            id: 'purchasePrice',
            field: 'purchasePrice',
            type: 'currency',
            render: row => Formatter.formatCurrency(row.purchasePrice),
            filtering: false,
        },
    ];

    const handleUpdate = updatedData => {
        return RealEstatePropertyService.update(updatedData.id, updatedData);
    };

    const handleAdd = newData => {
        newData.projectId = match.params.id;
        return RealEstatePropertyService.create(newData);
    }

    const handleDelete = data => {
        return RealEstatePropertyService.delete(data.id);
    };

    return (
        <BaseTable
            tableName='realEstatePropertiesTable'
            columns={columns}
            data={tableData}
            enableRowAdd={true}
            onAdd={handleAdd}
            onUpdate={handleUpdate}
            onDelete={handleDelete}
            title='Objekte'
            objects='Objekte'
            filtering={false}
            search={false}
        />
    );
}
