import {useContext, createContext, Context} from 'react';
import BaseContext from '../interfaces/BaseContext';

const defaultValue: BaseContext = {} as BaseContext;

export const AppContext: Context<BaseContext> = createContext(defaultValue);

export function useAppContext(): BaseContext {
    return useContext(AppContext);
}
