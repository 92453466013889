import React, {useCallback, useEffect, useRef, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Formatter from '../../../helpers/Formatter';
import EditableSmallTable from '../../UI/Tables/EditableSmallTable';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import ButtonEditOkCancel from '../../UI/Partials/ButtonEditOkCancel';
import {useTheme} from '@material-ui/core/styles';
import ThemeHelper from '../../../helpers/ThemeHelper';

export default function ProjectCosts(props) {
    const theme = useTheme();
    const [data, setData] = useState({});
    const [updatedData, setUpdatedData] = useState({});
    const [editMode, setEditMode] = useState(false);
    const {project} = props;

    const chartRef = useRef()

    const pieColors = () => {
        let colors = [];
        let base = Highcharts.getOptions().colors[0];
        let i;

        for (i = 0; i < 10; i += 1) {
            colors.push(Highcharts.color(base).brighten((i - 3) / 7).get());
        }

        return colors;
    }

    const [chartOptions, setChartOptions] = useState({
        chart: {
            type: 'pie',
            backgroundColor: 'transparent',
        },
        title: {
            style: {
                color: ThemeHelper.getColor(theme, theme.palette.chart.title),
            },
            text: 'Nebenkostenverteilung'
        },
        subtitle: {
            style: {
                color: ThemeHelper.getColor(theme, theme.palette.chart.subtitle),
            },
            text: 'Nebenkosten insgesamt: ' + project.purchaseIncidentals + ' €',
            useHTML: true,
        },
        series: [{
            data: [
                { name: 'Grunderwerbsteuer', y: project.propertyTransferTaxPercentage },
                { name: 'Notar', y: project.notaryCostsPercentage },
                { name: 'Makler', y: project.brokerageFeePercentage },
                { name: 'Strukturierungskosten', y: project.structuringCostsPercentage },
            ]
        }],
        tooltip: {
            pointFormat: '{series.name}: <b>{point.y}%</b>'
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                colors: pieColors(),
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.y} %'
                }
            }
        },
    });

    const updateSeries = useCallback(() => {
        setChartOptions({
            series: [
                {
                    data:[
                        { name: 'Grunderwerbsteuer', y: project.propertyTransferTaxPercentage },
                        { name: 'Notar', y: project.notaryCostsPercentage },
                        { name: 'Makler', y: project.brokerageFeePercentage },
                        { name: 'Strukturierungskosten', y: project.structuringCostsPercentage },
                    ]
                }
            ],
            subtitle: {
                text: 'Nebenkosten insgesamt: ' + project.purchaseIncidentals + ' €',
            },
        });
        chartRef.current.chart.redraw();
    }, [project])

    useEffect(() => {
        setData(project);
        updateSeries();
    }, [project, updateSeries]);

    const handleChange = (event) => {
        const currentData = data;
        currentData[event.target.name] = event.target.value;
        setData(currentData);

        const currentUpdatedData = updatedData;
        currentUpdatedData[event.target.name] = event.target.value;
        setUpdatedData(currentUpdatedData);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSave();
        }

        if (event.key === 'Escape') {
            setEditMode(false);
        }
    }

    const handleSave = () => {
        setEditMode(false);
        props.onSave(updatedData);
    }

    const costsRows = [
        {
            label: 'Grunderwerbsteuer',
            value: data.propertyTransferTax || '',
            name: 'propertyTransferTax',
            editable: true,
            type: 'integerCurrency',
            formatter: Formatter.formatCurrency,
        },
        {
            label: 'Notar',
            value: data.notaryCosts || '',
            name: 'notaryCosts',
            editable: true,
            type: 'integerCurrency',
            formatter: Formatter.formatCurrency,
        },
        {
            label: 'Makler',
            value: data.brokerageFee || '',
            name: 'brokerageFee',
            editable: true,
            type: 'integerCurrency',
            formatter: Formatter.formatCurrency,
        },
        {
            label: 'Strukturierungskosten',
            value: data.structuringCosts || '',
            name: 'structuringCosts',
            editable: true,
            type: 'integerCurrency',
            formatter: Formatter.formatCurrency,
        },
    ];

    const costsPercentageRows = [
        {
            label: 'Grunderwerbsteuer',
            value: data.propertyTransferTaxPercentage || 'wird berechnet',
            name: 'propertyTransferTax',
            editable: false,
            type: 'number',
            formatter: Formatter.formatPercentage,
            hint: 'Prozentualer Anteil der Grunderwerbsteuerkosten aus der Summe von: Grunderwerbsteuer + Notar + Makler + Strukturierungskosten',
        },
        {
            label: 'Notar',
            value: data.notaryCostsPercentage || 'wird berechnet',
            name: 'notaryCosts',
            editable: false,
            type: 'number',
            formatter: Formatter.formatPercentage,
            hint: 'Prozentualer Anteil der Notarkosten aus der Summe von: Grunderwerbsteuer + Notar + Makler + Strukturierungskosten',
        },
        {
            label: 'Makler',
            value: data.brokerageFeePercentage || 'wird berechnet',
            name: 'brokerageFee',
            editable: false,
            type: 'number',
            formatter: Formatter.formatPercentage,
            hint: 'Prozentualer Anteil der Maklerkosten aus der Summe von: Grunderwerbsteuer + Notar + Makler + Strukturierungskosten',
        },
        {
            label: 'Strukturierungskosten',
            value: data.structuringCostsPercentage || 'wird berechnet',
            name: 'structuringCosts',
            editable: false,
            type: 'number',
            formatter: Formatter.formatPercentage,
            hint: 'Prozentualer Anteil der Strukturierungskosten aus der Summe von: Grunderwerbsteuer + Notar + Makler + Strukturierungskosten',
        },
    ];

    return (
        <>
        <Grid
            justify={'flex-start'}
            container
            spacing={2}
            style={{margin: '15px 0 20px 0'}}
        ><Grid item>
            <Typography variant={'h4'} component={'h2'}>
                Kosten
            </Typography>
        </Grid>
            <Grid item style={{display: 'flex'}}>
                <div style={{display: 'flex'}}>
                    <ButtonEditOkCancel
                        onEdit={() => setEditMode(true)}
                        onCancel={() => setEditMode(false)}
                        onSave={handleSave}
                        editMode={editMode}>
                    </ButtonEditOkCancel>
                </div>
            </Grid>
        </Grid>
        <Grid container spacing={3} alignItems='stretch'>
            <Grid item sm={4}>
                <EditableSmallTable
                    rows={costsRows}
                    editMode={editMode}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                />
            </Grid>
            <Grid item sm={4}>
                <EditableSmallTable
                    rows={costsPercentageRows}
                    editMode={editMode}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                />
            </Grid>
            <Grid item sm={4}>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOptions}
                    ref={chartRef}
                    allowChartUpdate={true}
                />
            </Grid>
        </Grid>
        </>
    );
}
