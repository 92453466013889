import React, {useEffect, useState} from 'react';
import RolesTable from './RolesTable';
import Loader from '../UI/Loader';
import Helper from '../../helpers/Helper';
import RoleProps from '../../interfaces/props/RoleProps';

export default function Role(props: RoleProps) {
    const {setMenuItems, setBreadcrumbItems} = props;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setMenuItems!([]);
        setBreadcrumbItems!([]);
        Helper.setPageTitle('Rollen');
    }, [setMenuItems, setBreadcrumbItems]);

    return (
        <>
            <RolesTable setLoader={setLoading}/>
            <Loader open={loading}/>
        </>
    );
}
