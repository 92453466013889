import Redirect from '../interfaces/Redirect';

const redirects: Array<Redirect> = [
    {
        path: '/',
        to: '/projects',
        exact: true,
    },
];

export default redirects;
