import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import EditableSmallTable from '../../UI/Tables/EditableSmallTable';
import Card from '@material-ui/core/Card';
import {CardHeader} from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import {makeStyles} from '@material-ui/core/styles';
import Formatter from '../../../helpers/Formatter';
import ThemeHelper from '../../../helpers/ThemeHelper';

const useStyles = makeStyles(theme => ({
    card: {
        maxWidth: '100%',
        flexGrow: 1,
        marginTop: 16,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    cardHeader: {
        backgroundColor: ThemeHelper.getColor(theme, theme.palette.cardHeader.backgroundColor),
        color: ThemeHelper.getColor(theme, theme.palette.cardHeader.color),
    },
}));

export default function ValueIncreaseDetails(props) {
    const classes = useStyles();
    const [data, setData] = useState({});

    useEffect(() => {
        setData(props.project);
    }, [props.project]);

    const hintMessage = (year) => {
        const previousYearValue = data['expectedRentYear' + (year - 1)];
        const percentRentIncrease = data['rentIncreasePercentageYear' + year] || data.rentIncreasePercentage;
        return year <= data.runningTime
            ? `<strong>Das Ergebnis aus:</strong><br>
                Vorjahreswert (${Formatter.formatCurrency(previousYearValue)}) 
                + 
                Mietsteigerung (${Formatter.formatPercentage(percentRentIncrease)})`
            : `Liegt nach Ende der Projekt-Laufzeit (${data.runningTime} Jahre)`;
    };

    const rows1 = [
        {
            label: 'erw. Mieteinnahmen im letzten Jahr der Laufzeit',
            value: data.basicRentLastYear || 'wird berechnet',
            name: 'basicRentLastYear',
            editable: false,
            formatter: Formatter.formatCurrency,
            hint: '<strong>Das Ergebnis aus:</strong><br>(Maximalwert der Mieteinnahmen der Jahre 1 bis 10)'
        },
        {
            label: 'Laufzeit',
            value: data.runningTime || '',
            name: 'runningTime',
            editable: false,
            type: 'number',
            hint: 'Identisch zu: Laufzeit (siehe oben: Allgemeine Projektinformationen)'
        },
        {
            label: 'erw. Mieteinnahmen Jahr 1 ',
            value: data.expectedRentYear1 || '',
            name: 'expectedRentYear1',
            editable: false,
            type: 'integerCurrency',
            formatter: Formatter.formatCurrency,
            hint: 'Identisch zu: Jahresnettomieteinnahmen 1. Jahr Gesamt (siehe oben: Allgemeine Projektinformationen)'
        },
        {
            label: 'erw. Mieteinnahmen Jahr 2',
            value: data.expectedRentYear2 || '',
            name: 'expectedRentYear2',
            editable: false,
            type: 'integerCurrency',
            formatter: Formatter.formatCurrency,
            hint: hintMessage(2),
        },
        {
            label: 'erw. Mieteinnahmen Jahr 3',
            value: data.expectedRentYear3 || '',
            name: 'expectedRentYear3',
            editable: false,
            type: 'integerCurrency',
            formatter: Formatter.formatCurrency,
            hint: hintMessage(3),
        },
        {
            label: 'erw. Mieteinnahmen Jahr 4',
            value: data.expectedRentYear4 || '',
            name: 'expectedRentYear4',
            editable: false,
            type: 'integerCurrency',
            formatter: Formatter.formatCurrency,
            hint: hintMessage(4),
        },
    ];

    const rows2 = [
        {
            label: 'erw. Mieteinnahmen Jahr 5',
            value: data.expectedRentYear5 || '',
            name: 'expectedRentYear5',
            editable: false,
            type: 'integerCurrency',
            formatter: Formatter.formatCurrency,
            hint: hintMessage(5),
        },
        {
            label: 'erw. Mieteinnahmen Jahr 6',
            value: data.expectedRentYear6 || '',
            name: 'expectedRentYear6',
            editable: false,
            type: 'integerCurrency',
            formatter: Formatter.formatCurrency,
            hint: hintMessage(6),
        },
        {
            label: 'erw. Mieteinnahmen Jahr 7',
            value: data.expectedRentYear7 || '',
            name: 'expectedRentYear7',
            editable: false,
            type: 'integerCurrency',
            formatter: Formatter.formatCurrency,
            hint: hintMessage(7),
        },
        {
            label: 'erw. Mieteinnahmen Jahr 8',
            value: data.expectedRentYear8 || '',
            name: 'expectedRentYear8',
            editable: false,
            type: 'integerCurrency',
            formatter: Formatter.formatCurrency,
            hint: hintMessage(8),
        },
        {
            label: 'erw. Mieteinnahmen Jahr 9',
            value: data.expectedRentYear9 || '',
            name: 'expectedRentYear9',
            editable: false,
            type: 'integerCurrency',
            formatter: Formatter.formatCurrency,
            hint: hintMessage(9),
        },
        {
            label: 'erw. Mieteinnahmen Jahr 10',
            value: data.expectedRentYear10 || '',
            name: 'expectedRentYear10',
            editable: false,
            type: 'integerCurrency',
            formatter: Formatter.formatCurrency,
            hint: hintMessage(10),
        },
    ];

    const rows3 = [
        {
            label: 'Erwarteter Verkaufspreis nach Laufzeit',
            value: data.expectedTotalSalesPriceTenYears || 'wird berechnet',
            name: 'expectedTotalSalesPriceTenYears',
            editable: false,
            formatter: Formatter.formatCurrency,
            hint: '<strong>Das Ergebnis aus:</strong><br>erw. Mieteinnahmen im letzten Jahr der Laufzeit * erwarteter Nettoverkaufsfaktor nach Ende der Laufzeit'
        },
        {
            label: 'Restschuld Bankdarlehen',
            value: data.remainingDebtAfterLoanRepayments || 'wird berechnet',
            name: 'remainingDebtAfterLoanRepayments',
            editable: false,
            formatter: Formatter.formatCurrency,
            hint: '<strong>Das Ergebnis aus:</strong><br>DebtCapital - Tilgungszahlungen (€)'
        },
        {
            label: 'Verkaufspreis - Restschuld Bankdarlehen',
            value: data.interimResultPropertyValueAfterTenYears || 'wird berechnet',
            name: 'interimResultPropertyValueAfterTenYears',
            editable: false,
            formatter: Formatter.formatCurrency,
            hint: '<strong>Das Ergebnis aus:</strong><br>Erwarteter Verkaufspreis nach Laufzeit - Restschuld Bankdarlehen'
        },
        {
            label: 'Wertentwicklung',
            value: data.propertyValueAfterTenYears || 'wird berechnet',
            name: 'propertyValueAfterTenYears',
            editable: false,
            formatter: Formatter.formatCurrency,
            hint: 'Die Differenz aus dem "Verkaufspreis - Restschuld Bankdarlehen" minus "Summe des Exporo Kapitals".'
        },
        {
            label: 'Anteil Wertentwicklung Anleger 80 %',
            value: data.addedValueAfterTenYearsInvestor || 'wird berechnet',
            name: 'addedValueAfterTenYearsInvestor',
            editable: false,
            formatter: Formatter.formatCurrency,
            hint: '<strong>Das Ergebnis aus:</strong><br>Wertentwicklung * 0.8'
        },
        {
            label: 'Anteil Wertentwicklung Exporo 20%',
            value: data.addedValueAfterTenYearsExporo || 'wird berechnet',
            name: 'addedValueAfterTenYearsExporo',
            editable: false,
            formatter: Formatter.formatCurrency,
            hint: '<strong>Das Ergebnis aus:</strong><br>Wertentwicklung * 0.2'
        },
    ];

    return (
        <Card className={classes.card} height={'100%'}>
            <CardHeader
                title={'Wertentwicklung der Immobilie (Berechnete Werte)'}
                className={classes.cardHeader}
            >
            </CardHeader>
            <CardContent className={classes.root}>
                <Grid container spacing={3} alignItems='stretch'>
                    <Grid item sm={4}>
                        <EditableSmallTable
                            rows={rows1}
                            editMode={false}
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <EditableSmallTable
                            rows={rows2}
                            editMode={false}
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <EditableSmallTable
                            rows={rows3}
                            editMode={false}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
