import React, {useEffect, useState} from 'react';
import ProjectsTable from './ProjectsTable';
import Loader from '../UI/Loader';
import Helper from '../../helpers/Helper';
import ProjectsProps from '../../interfaces/props/ProjectsProps';

export default function Projects(props: ProjectsProps) {
    const [loading, setLoading] = useState(false);
    const {setMenuItems, setBreadcrumbItems} = props;

    useEffect(() => {
        setMenuItems!([]);
        setBreadcrumbItems!([]);
        Helper.setPageTitle('Projektübersicht');
    }, [setMenuItems, setBreadcrumbItems]);

    return (
        <>
            <ProjectsTable setLoader={setLoading}/>
            <Loader open={loading}/>
        </>
    );
}
