import React, {useEffect, useState} from 'react';
import Formatter from '../../../helpers/Formatter';
import EditableCard from '../../UI/Cards/EditableCard';

export default function ProjectYieldCard(props) {
    const {project} = props;
    const [data, setData] = useState({});

    useEffect(() => {
        setData(project);
    }, [project]);

    const tableData = [
        {
            label: 'Erwartete Ausschüttung p. a.',
            value: data.expectedDividendTenYearAverage,
            id: data.expectedDividendTenYearAverage,
            name: 'expectedDividendTenYearAverage',
            editable: true,
            formatter: Formatter.formatPercentage,
            type: 'number',
        },
        {
            label: 'Erwartete Wertsteigerung p.a.',
            value: data.expectedValueIncreaseTenYearAverage,
            id: data.expectedValueIncreaseTenYearAverage,
            name: 'expectedValueIncreaseTenYearAverage',
            editable: true,
            formatter: Formatter.formatPercentage,
            type: 'number',
        },
        {
            label: 'Erwartete Gesamtrendite p. a.',
            value: data.expectedTotalDividendPerYear,
            id: data.expectedTotalDividendPerYear,
            name: 'expectedTotalDividendPerYear',
            editable: true,
            formatter: Formatter.formatPercentage,
            type: 'number',
        },
        {
            label: 'Erwartete Ausschüttungsrendite (1. volles Jahr)',
            value: data.expectedDividendFirstYear,
            id: data.expectedDividendFirstYear,
            name: 'expectedDividendFirstYear',
            editable: true,
            formatter: Formatter.formatPercentage,
            type: 'number',
        },
        {
            label: 'Erwartete Ausschüttungsrendite (2. volles Jahr)',
            value: data.expectedDividendSecondYear,
            id: data.expectedDividendSecondYear,
            name: 'expectedDividendSecondYear',
            editable: true,
            formatter: Formatter.formatPercentage,
            type: 'number',
        },
        {
            label: 'Erwartete Ausschüttungsrendite (3. volles Jahr)',
            value: data.expectedDividendThirdYear,
            id: data.expectedDividendThirdYear,
            name: 'expectedDividendThirdYear',
            editable: true,
            formatter: Formatter.formatPercentage,
            type: 'number',
        },
        {
            label: 'Erwartete Ausschüttungsrendite (4. volles Jahr)',
            value: data.expectedDividendFourthYear,
            id: data.expectedDividendFourthYear,
            name: 'expectedDividendFourthYear',
            editable: true,
            formatter: Formatter.formatPercentage,
            type: 'number',
        },
        {
            label: 'Erwartete Ausschüttungsrendite (5. volles Jahr)',
            value: data.expectedDividendFifthYear,
            id: data.expectedDividendFifthYear,
            name: 'expectedDividendFifthYear',
            editable: true,
            formatter: Formatter.formatPercentage,
            type: 'number',
        },
        {
            label: 'Erwartete Ausschüttungsrendite (6. volles Jahr)',
            value: data.expectedDividendSixthYear,
            id: data.expectedDividendSixthYear,
            name: 'expectedDividendSixthYear',
            editable: true,
            formatter: Formatter.formatPercentage,
            type: 'number',
        },
        {
            label: 'Erwartete Ausschüttungsrendite (7. volles Jahr)',
            value: data.expectedDividendSeventhYear,
            id: data.expectedDividendSeventhYear,
            name: 'expectedDividendSeventhYear',
            editable: true,
            formatter: Formatter.formatPercentage,
            type: 'number',
        },
        {
            label: 'Erwartete Ausschüttungsrendite (8. volles Jahr)',
            value: data.expectedDividendEighthYear,
            id: data.expectedDividendEighthYear,
            name: 'expectedDividendEighthYear',
            editable: true,
            formatter: Formatter.formatPercentage,
            type: 'number',
        },
        {
            label: 'Erwartete Ausschüttungsrendite (9. volles Jahr)',
            value: data.expectedDividendNinthYear,
            id: data.expectedDividendNinthYear,
            name: 'expectedDividendNinthYear',
            editable: true,
            formatter: Formatter.formatPercentage,
            type: 'number',
        },
    ];

    const handleSave = (updatedData) => {
        setData({
            ...data,
            ...updatedData,
        });

        props.onSave(updatedData);
    };

    return (
        <EditableCard
            tableData={tableData}
            onSave={handleSave}
            title={'Rendite'}
            headerImage={props.imageUrl}
        />
    );
}
