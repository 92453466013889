import React, {useEffect, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import BaseTable from '../../UI/Tables/BaseTable';
import LandRegisterTableProps from '../../../interfaces/props/LandRegisterTableProps';
import LandRegister from '../../../interfaces/models/LandRegister';
import TableData from '../../../interfaces/TableData';
import {TableDataEditableTypes} from '../../../interfaces/enums/TableDataEditableTypes';
import LandRegisterService from '../../../services/LandRegisterService';
import Formatter from '../../../helpers/Formatter';
import {IdMatchParam} from '../../../interfaces/matchs/IdMatchParam';

export default function LandRegisterTable(props: LandRegisterTableProps) {
    const [tableData, setTableData] = useState<LandRegister[]>([] as LandRegister[]);
    const match = useRouteMatch<IdMatchParam>();

    useEffect(() => {
        setTableData(props.landRegisters ?? []);
    }, [props.landRegisters]);

    const columns: TableData[] = [
        {
            title: 'ID',
            field: 'id',
            editable: TableDataEditableTypes.never,
            filtering: false,
            width: '65px',
            render: (row: any) => Formatter.formatIdColumn(row, 'land-register'),
        },
        {
            title: 'Grundbuch',
            field: 'title',
        },
        {
            title: 'Amtsgericht',
            field: 'districtCourt',
        },
        {
            title: 'Blatt',
            field: 'page',
        },
        {
            title: 'Flurstück',
            field: 'parcel',
        },
    ];

    const handleUpdate = (updatedData: LandRegister): Promise<LandRegister> | undefined => {
        if (updatedData.id) {
            return LandRegisterService.updateFromTable(updatedData.id, updatedData);
        }
    };

    const handleAdd = (newData: LandRegister): Promise<LandRegister> => {
        newData.realEstatePropertyId = parseInt(match.params.id, 10);
        return LandRegisterService.create(newData);
    }

    const handleDelete = (data: LandRegister): Promise<string> | undefined => {
        if (data.id) {
            return LandRegisterService.delete(data.id);
        }
    };

    return (
        <BaseTable
            tableName='landRegisterTable'
            columns={columns}
            data={tableData}
            enableRowAdd={true}
            onAdd={handleAdd}
            onUpdate={handleUpdate}
            onDelete={handleDelete}
            title='Grundbucheinträge'
            objects='Grundbuch'
            filtering={false}
            search={false}
        />
    );
}
