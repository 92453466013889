import React, {ChangeEvent, useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import EditableSmallTable from '../../UI/Tables/EditableSmallTable';
import Formatter from '../../../helpers/Formatter';
import ButtonEditOkCancel from '../../UI/Partials/ButtonEditOkCancel';
import Card from '@material-ui/core/Card';
import {CardHeader} from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import {makeStyles} from '@material-ui/core/styles';
import ThemeHelper from '../../../helpers/ThemeHelper';
import Typography from '@material-ui/core/Typography';
import FutureDetailProps from '../../../interfaces/props/FutureDetailProps';
import CardData from '../../../interfaces/CardData';
import {RenderTypes} from '../../../interfaces/enums/RenderTypes';
import Project from '../../../interfaces/models/Project';

const useStyles = makeStyles(theme => ({
    card: {
        maxWidth: '100%',
        height: '100%',
        flexGrow: 1,
        marginTop: 16,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    cardHeader: {
        backgroundColor: ThemeHelper.getColor(theme, theme.palette.cardHeader.backgroundColor),
        color: ThemeHelper.getColor(theme, theme.palette.cardHeader.color),
    },
    h6: {
        backgroundColor: ThemeHelper.getColor(theme, theme.palette.cardHeader.backgroundColor),
        color: ThemeHelper.getColor(theme, theme.palette.cardHeader.color),
        padding: 10,
        marginBottom: 10,
        borderRadius: 8,
    },
}));

export default function FutureDetails(props: FutureDetailProps) {
    const classes = useStyles();
    const [data, setData] = useState<Project>({} as Project);
    const [updatedData, setUpdatedData] = useState<Project>({} as Project);
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        setData(props.project);
    }, [props.project]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const currentData: Project = data;
        // @ts-ignore
        currentData[event.target.name] = event.target.value;
        setData(currentData);

        const currentUpdatedData: Project = updatedData;
        // @ts-ignore
        currentUpdatedData[event.target.name] = event.target.value;
        setUpdatedData(currentUpdatedData);
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleSave();
        }

        if (event.key === 'Escape') {
            setEditMode(false);
        }
    }

    const handleSave = () => {
        setEditMode(false);
        props.onSave(updatedData);
    }


    const rows1: CardData[] = [
        {
            label: 'Verwaltung der Zweckgesellschaft (€)',
            value: data.managementCostsFirstYear || 'wird berechnet',
            name: 'managementCostsFirstYear',
            editable: false,
            formatter: Formatter.formatCurrency,
            hint: '<strong>Summe der Kosten im aus:</strong><br>Steuerberater + Sicherheitentreuhänder + Zahlstelle + Anlegerbetreuung'
        },
        {
            label: '- Davon für Steuerberater (€)',
            value: data.taxAdvisorCostsFirstYear,
            name: 'taxAdvisorCostsFirstYear',
            editable: true,
            type: RenderTypes.integerCurrency,
            formatter: Formatter.formatCurrency,
        },
        {
            label: '- Davon für Sicherheitentreuhänder (€)',
            value: data.trusteeCostsFirstYear,
            name: 'trusteeCostsFirstYear',
            editable: true,
            type: RenderTypes.integerCurrency,
            formatter: Formatter.formatCurrency,
        },
        {
            label: '- Davon für Zahlstelle (€)',
            value: data.payAgencyCostsFirstYear,
            name: 'payAgencyCostsFirstYear',
            editable: true,
            type: RenderTypes.integerCurrency,
            formatter: Formatter.formatCurrency,
        },
        {
            label: '- Davon für Anlegerbetreuung (€)',
            value: data.investorSupportCostsFirstYear,
            name: 'investorSupportCostsFirstYear',
            editable: true,
            type: RenderTypes.integerCurrency,
            formatter: Formatter.formatCurrency,
        },
        {
            label: 'Strategische Immobilienverwaltung (€)',
            value: data.assetManagementCostsFirstYear,
            name: 'assetManagementCostsFirstYear',
            editable: true,
            type: RenderTypes.integerCurrency,
            formatter: Formatter.formatCurrency,
        },
        {
            label: 'Hausverwaltung (€)',
            value: data.propertyManagementCostsFirstYear,
            name: 'propertyManagementCostsFirstYear',
            editable: true,
            type: RenderTypes.integerCurrency,
            formatter: Formatter.formatCurrency,
        },
        {
            label: 'Mietausfallwagnis (€)',
            value: data.rentalLossRiskFirstYear,
            name: 'rentalLossRiskFirstYear',
            editable: true,
            type: RenderTypes.integerCurrency,
            formatter: Formatter.formatCurrency,
        },
        {
            label: 'Zinszahlung (€)',
            value: data.interestPaymentsFirstYear,
            name: 'interestPaymentsFirstYear',
            editable: true,
            type: RenderTypes.integerCurrency,
            formatter: Formatter.formatCurrency,
        },
        {
            label: 'Instandhaltungskosten (lt. Plan) (€)',
            value: data.maintenanceCostsFirstYear,
            name: 'maintenanceCostsFirstYear',
            editable: true,
            type: RenderTypes.integerCurrency,
            formatter: Formatter.formatCurrency,
        },
        {
            label: 'Ergebnis der Immobilie (€)',
            value: data.roiTotalFirstYear,
            name: 'roiTotalFirstYear',
            editable: true,
            type: RenderTypes.integerCurrency,
            formatter: Formatter.formatCurrency,
        },
        {
            label: 'Kapitalrendite (%)',
            value: data.roiPercentageFirstYear,
            name: 'roiPercentageFirstYear',
            editable: false,
            formatter: Formatter.formatPercentage,
            hint: '<strong>Das Ergebnis aus:</strong><br>Ergebnis der Immobilie (€) / Anlegervolumen',
        },
        {
            label: 'Tilgungszahlungen (€)',
            value: data.loanRepaymentsFirstYear,
            name: 'loanRepaymentsFirstYear',
            editable: true,
            type: RenderTypes.integerCurrency,
            formatter: Formatter.formatCurrency,
        },
        {
            label: 'Tilgungszahlungen (%)',
            value: data.loanRepaymentsPercentageFirstYear,
            name: 'loanRepaymentsPercentageFirstYear',
            editable: true,
            type: RenderTypes.floatPercent,
            formatter: Formatter.formatPercentage,
        },
        {
            label: 'Ausschüttung (€) 1 Jahr',
            value: data.projectDividendPayoutFirstYear,
            name: 'projectDividendPayoutFirstYear',
            editable: true,
            type: RenderTypes.integerCurrency,
            formatter: Formatter.formatCurrency,
        },
    ];

    const rows2: CardData[] = [
        {
            label: 'Verwaltung der Zweckgesellschaft (€)',
            value: data.managementCosts || 'wird berechnet',
            name: 'managementCosts',
            editable: false,
            formatter: Formatter.formatCurrency,
            hint: '<strong>Summe der Kosten aus:</strong><br>Steuerberater + Sicherheitentreuhänder + Zahlstelle + Anlegerbetreuung'
        },
        {
            label: '- Davon für Steuerberater (€)',
            value: data.taxAdvisorCosts,
            name: 'taxAdvisorCosts',
            editable: true,
            type: RenderTypes.integerCurrency,
            formatter: Formatter.formatCurrency,
        },
        {
            label: '- Davon für Sicherheitentreuhänder (€)',
            value: data.trusteeCosts,
            name: 'trusteeCosts',
            editable: true,
            type: RenderTypes.integerCurrency,
            formatter: Formatter.formatCurrency,
        },
        {
            label: '- Davon für Zahlstelle (€)',
            value: data.payAgencyCosts,
            name: 'payAgencyCosts',
            editable: true,
            type: RenderTypes.integerCurrency,
            formatter: Formatter.formatCurrency,
        },
        {
            label: '- Davon für Anlegerbetreuung (€)',
            value: data.investorSupportCosts,
            name: 'investorSupportCosts',
            editable: true,
            type: RenderTypes.integerCurrency,
            formatter: Formatter.formatCurrency,
        },
        {
            label: 'Strategische Immobilienverwaltung (€)',
            value: data.assetManagementCosts,
            name: 'assetManagementCosts',
            editable: true,
            type: RenderTypes.integerCurrency,
            formatter: Formatter.formatCurrency,
        },
        {
            label: 'Hausverwaltung (€)',
            value: data.propertyManagementCosts,
            name: 'propertyManagementCosts',
            editable: true,
            type: RenderTypes.integerCurrency,
            formatter: Formatter.formatCurrency,
        },
        {
            label: 'Mietausfallwagnis (€)',
            value: data.rentalLossRisk,
            name: 'rentalLossRisk',
            editable: true,
            type: RenderTypes.integerCurrency,
            formatter: Formatter.formatCurrency,
        },
        {
            label: 'Zinszahlung (€)',
            value: data.interestPayments,
            name: 'interestPayments',
            editable: true,
            type: RenderTypes.integerCurrency,
            formatter: Formatter.formatCurrency,
        },
        {
            label: 'Instandhaltungskosten (lt. Plan) (€)',
            value: data.maintenanceCosts,
            name: 'maintenanceCosts',
            editable: true,
            type: RenderTypes.integerCurrency,
            formatter: Formatter.formatCurrency,
        },
        {
            label: 'Ergebnis der Immobilie (€)',
            value: data.roiTotal,
            name: 'roiTotal',
            editable: true,
            type: RenderTypes.integerCurrency,
            formatter: Formatter.formatCurrency,
        },
        {
            label: 'Kapitalrendite (%)',
            value: data.roiPercentage,
            name: 'roiPercentage',
            editable: false,
            formatter: Formatter.formatPercentage,
            hint: '<strong>Das Ergebnis aus:</strong><br>Ergebnis der Immobilie (€) / Anlegervolumen',
        },
        {
            label: 'Tilgungszahlungen (€)',
            value: data.loanRepayments,
            name: 'loanRepayments',
            editable: true,
            type: RenderTypes.integerCurrency,
            formatter: Formatter.formatCurrency,
        },
        {
            label: 'Tilgungszahlungen (%)',
            value: data.loanRepaymentsPercentage,
            name: 'loanRepaymentsPercentage',
            editable: true,
            type: RenderTypes.floatPercent,
            formatter: Formatter.formatPercentage,
        },
        {
            label: 'Ausschüttung (€)',
            value: data.projectDividendPayout || '',
            name: 'projectDividendPayout',
            editable: true,
            type: RenderTypes.integerCurrency,
            formatter: Formatter.formatCurrency,
        },
    ];

    const rows3: CardData[] = [
        {
            label: 'Erw. Wert der Immobilie in 10 Jahren',
            value: data.realestateValueTenYears || '',
            name: 'realestateValueTenYears',
            editable: true,
            type: RenderTypes.integerCurrency,
            formatter: Formatter.formatCurrency,
        },
        {
            label: 'Ausschüttungsrendite 1. Quartal',
            value: data.expectedDividendFirstQuarter || '',
            name: 'expectedDividendFirstQuarter',
            editable: true,
            formatter: Formatter.formatPercentage,
        },
        {
            label: 'Ausschüttungsrendite 2. Quartal',
            value: data.expectedDividendSecondQuarter || '',
            name: 'expectedDividendSecondQuarter',
            editable: true,
            formatter: Formatter.formatPercentage,
        },
        {
            label: 'Ausschüttungsrendite 3. Quartal',
            value: data.expectedDividendThirdQuarter || '',
            name: 'expectedDividendThirdQuarter',
            editable: true,
            formatter: Formatter.formatPercentage,
        },
        {
            label: 'Ausschüttungsrendite 4. Quartal',
            value: data.expectedDividendFourthQuarter || '',
            name: 'expectedDividendFourthQuarter',
            editable: true,
            formatter: Formatter.formatPercentage,
        },
    ];

    return (
        <Card className={classes.card}>
            <CardHeader
                title={'Prognostizierte Jahresrechnung'}
                className={classes.cardHeader}
                action={
                    <ButtonEditOkCancel
                        onEdit={() => setEditMode(true)}
                        onCancel={() => setEditMode(false)}
                        onSave={handleSave}
                        editMode={editMode}>
                    </ButtonEditOkCancel>
                }
            >
            </CardHeader>
            <CardContent>
                <Grid container spacing={3} alignItems='stretch'>
                    <Grid item sm={4}>
                        <Typography variant='h6' className={classes.h6}>1. Jahr</Typography>
                        <EditableSmallTable
                            rows={rows1}
                            editMode={editMode}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <Typography variant='h6' className={classes.h6}>10-Jahres-Durchschnitt</Typography>
                        <EditableSmallTable
                            rows={rows2}
                            editMode={editMode}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <Typography variant='h6' className={classes.h6}>Verschiedenes</Typography>
                        <EditableSmallTable
                            rows={rows3}
                            onChange={handleChange}
                            editMode={editMode}
                            onKeyDown={handleKeyDown}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
