import Formatter from './Formatter';
import CustomSelect from '../components/UI/Forms/CustomSelect';
import CustomDatePicker from '../components/UI/Forms/CustomDatePicker';
import {TextField} from '@material-ui/core';
import React from 'react';
import NumberFormat from 'react-number-format';
import Box from '@material-ui/core/Box';
import AccountSelect from '../components/UI/Forms/AccountSelect';
import {RenderTypes} from "../interfaces/enums/RenderTypes";

const Render = {
    defaultValue: () => {
        return (<Box fontStyle="italic" fontSize={10}>Kein Wert angegeben</Box>);
    },
    numberFormatFloat: (props, prefix, isSuffix = false, decimalScale = 2) => {
        const {inputRef, onChange, ...other} = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator='.'
                isNumericString
                allowNegative
                decimalSeparator=','
                allowedDecimalSeparators=','
                decimalScale={decimalScale}
                prefix={!isSuffix ? prefix : ''}
                suffix={isSuffix ? prefix : ''}
            />
        );
    },
    negativeNumberFormatFloat: (props, prefix, suffix = '', decimalScale = 2) => {
        const {inputRef, onChange, ...other} = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator='.'
                isNumericString
                allowNegative
                decimalSeparator=','
                allowedDecimalSeparators=','
                decimalScale={decimalScale}
                prefix={prefix}
                suffix={suffix}
            />
        );
    },
    numberFormatInteger: (props, prefix, isSuffix = false) => {
        const {inputRef, onChange, ...other} = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator='.'
                decimalSeparator={false}
                isNumericString
                decimalScale='0'
                prefix={!isSuffix ? prefix : ''}
                suffix={isSuffix ? prefix : ''}
                isAllowed={(values) => {
                    const {value} = values;
                    return !value.includes(',');
                }}
            />
        );
    },
    renderEditComponentByType: (props, row) => {
        let inputType = row.type;

        if (row.type === RenderTypes.negativeFloatCurrency) {
            inputType = RenderTypes.negativeFloatCurrency;
        } else if (row.type !== 'integerCurrency' && row.formatter === Formatter.formatCurrency) {
            inputType = 'floatCurrency';
        } else if (row.formatter === Formatter.formatSquareMeters) {
            inputType = 'squareMeters';
        } else if (row.formatter === Formatter.formatPercentage) {
            inputType = 'floatPercent';
        }

        switch (inputType) {
            case 'accountSelect':
                return (
                    <AccountSelect
                        defaultValue={row.id ? row.id : ''}
                        onChange={event => props.onChange(event)}
                        onKeyDown={event => props.onKeyDown(event)}
                        name={row.name}
                        formatter={row.editFormatter}
                        noneValue={row.noneValue ? row.noneValue : null}
                        accountType={row.accountType ? row.accountType : 'company'}
                    />)
            case 'select':
                return (
                    <CustomSelect
                        options={row.selectOptions}
                        defaultValue={row.id ? row.id : ''}
                        onChange={event => props.onChange(event)}
                        onKeyDown={event => props.onKeyDown(event)}
                        name={row.name}
                        size={'small'}
                    />)
            case 'date':
                return (
                    <CustomDatePicker
                        value={row.value}
                        onChange={event => props.onChange(event)}
                        name={row.name}
                        size={'small'}
                    />
                )
            case 'floatCurrency':
                return (
                    <TextField
                        id={row.label}
                        onChange={event => props.onChange(event)}
                        defaultValue={row.value ? row.value : ''}
                        name={row.name}
                        size={'small'}
                        style={{width: '100%'}}
                        onKeyDown={props.onKeyDown}
                        InputProps={{
                            inputComponent: props => Render.numberFormatFloat(props, '€ '),
                        }}
                    />
                )
            case 'negativeFloatCurrency':
                return (
                    <TextField
                        id={row.label}
                        onChange={event => props.onChange(event)}
                        defaultValue={row.value ? row.value : ''}
                        name={row.name}
                        size={'small'}
                        style={{width: '100%'}}
                        onKeyDown={props.onKeyDown}
                        InputProps={{
                            inputComponent: props => Render.negativeNumberFormatFloat(props, '-', ' €'),
                        }}
                    />
                )
            case 'integerCurrency':
                return (
                    <TextField
                        id={row.label}
                        onChange={event => props.onChange(event)}
                        defaultValue={row.value ? row.value : ''}
                        name={row.name}
                        size={'small'}
                        style={{width: '100%'}}
                        onKeyDown={props.onKeyDown}
                        InputProps={{
                            inputComponent: props => Render.numberFormatInteger(props, '€ '),
                        }}
                    />
                )
            case 'floatPercent':
                return (
                    <TextField
                        id={row.label}
                        onChange={event => props.onChange(event)}
                        defaultValue={row.value ? row.value : ''}
                        name={row.name}
                        size={'small'}
                        style={{width: '100%'}}
                        onKeyDown={props.onKeyDown}
                        InputProps={{
                            inputComponent: props => Render.numberFormatFloat(props, '% ', false, 4),
                        }}
                    />
                )
            case 'squareMeters':
                return (
                    <TextField
                        id={row.label}
                        onChange={event => props.onChange(event)}
                        defaultValue={row.value ? row.value : ''}
                        name={row.name}
                        size={'small'}
                        style={{width: '100%'}}
                        onKeyDown={props.onKeyDown}
                        InputProps={{
                            inputComponent: props => Render.numberFormatInteger(props, ' m²', true),
                        }}
                    />
                )
            default:
                return (<TextField
                    id={row.label}
                    type={row.type}
                    onChange={event => props.onChange(event)}
                    defaultValue={row.value ? row.value : ''}
                    name={row.name}
                    size={'small'}
                    style={{width: '100%'}}
                    onKeyDown={event => props.onKeyDown(event)}
                />)
        }
    }
}

export default Render;
