import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReportingService from '../../services/ReportingService';

export default function FormDialog(props: any) {
    const [open, setOpen] = React.useState(false);
    const [file, setFile] = React.useState('');
    const [message, setMessage] = React.useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleUpload = () => {
        let formData = new FormData();
        formData.append("file", file);
        
        ReportingService.uploadExcel(formData, props.financialProductId).then(() => {
            setMessage('Upload erfolgreich. Die Excel wird verarbeitet und die Seite wird gleich neu geladen.');
            setTimeout(() => {
                window.location.reload();
            }, 5000)
        }).catch(() => {
            setMessage('Upload fehlgeschlagen');
        });
        
    }

    const handleFileSelect = (event: any) => {
        console.log(event.target.files[0]);
        setFile(event.target.files[0]);
    }

    return (
        <div>
            <Button variant="outlined" onClick={handleClickOpen}>
                Neues Reporting erstellen
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Upload Excel</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Lade hier die Excel für das neue Quartal hoch.
                    </DialogContentText>
                    <label htmlFor="contained-button-file">
                        <input 
                            accept="xlsx/*" 
                            id="contained-button-file" 
                            type="file"
                            onChange={handleFileSelect}
                            name={'file-upload'}
                        />
                    </label>
                    <div dangerouslySetInnerHTML={{__html: message}}></div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Abbrechen</Button>
                    <Button onClick={handleUpload}>Speichern</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
